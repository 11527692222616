/*!
*   _____       _             __                 _____                               ______ _       _   _
*  |_   _|     | |           / _|               |_   _|                             |  ____| |     | | (_)
*    | |  _ __ | |_ ___ _ __| |_ __ _  ___ ___    | |  ___ ___  _ __  ___   ______  | |__  | | __ _| |_ _  ___ ___  _ __
*    | | | '_ \| __/ _ \ '__|  _/ _` |/ __/ _ \   | | / __/ _ \| '_ \/ __| |______| |  __| | |/ _` | __| |/ __/ _ \| '_ \
*   _| |_| | | | ||  __/ |  | || (_| | (_|  __/  _| || (_| (_) | | | \__ \          | |    | | (_| | |_| | (_| (_) | | | |
*  |_____|_| |_|\__\___|_|  |_| \__,_|\___\___| |_____\___\___/|_| |_|___/          |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*                         UIcons 2.3.0 - https://www.flaticon.com/uicons/interface-icons
*/
:root {
  --fg-high: #fff;
  --fg-med: #ffb816;
  --fg-low: #e1ded7;
  --fg-inv: #4a5d84;
  --background: #b4ab9a;
  --bg-high: #fff;
  --bg-med: #ffb816;
  --bg-low: #a79e8d;
  --bg-inv: #443519;
  --page-width: 600px;
  --radius-large: 8px;
  --radius-small: 5px;
  --sidebar-gap: 10px;
  --sidebar-item: 40px;
  --sidebar-icon: 26px;
}

@font-face {
  font-family: Thasadith;
  src: url("Thasadith-Regular.581b51d9.ttf") format("truetype");
}

@font-face {
  font-family: Rubik;
  src: url("Rubik-VariableFont_wght.e80514c0.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-VariableFont_wght.e400b0f6.ttf") format("truetype"), url("Nunito-Italic-VariableFont_wght.3b9a138e.ttf") format("truetype");
}

html {
  background: url("background.69ef7656.jpg") center / cover no-repeat fixed;
  background-color: var(--background);
}

::selection {
  color: var(--fg-high);
  background-color: var(--bg-med);
}

h1 {
  font-family: Rubik, sans-serif;
  font-size: 3rem;
  font-weight: normal;
}

h2, h3, h4, h5, h6 {
  margin: 1.5rem 0;
  font-family: Thasadith, serif;
  font-size: 2rem;
  font-weight: normal;
}

a:not(.nav-link) {
  color: var(--fg-med);
  padding: 0 3px;
  text-decoration: none;
}

a:not(.nav-link):hover {
  color: var(--background);
  background-color: var(--fg-high);
}

a:not(.nav-link):active {
  color: var(--fg-med);
}

body {
  color: var(--fg-high);
  margin: 0;
  font-family: Nunito, sans-serif;
  font-size: 1rem;
}

@font-face {
  font-family: uicons-solid-rounded;
  src: url("uicons-solid-rounded.5d2b2b44.woff2") format("woff2"), url("uicons-solid-rounded.c233eeb0.woff") format("woff"), url("uicons-solid-rounded.d4f42bb2.eot#iefix") format("embedded-opentype");
  font-display: swap;
}

i[class^="fi-sr-"]:before, i[class*=" fi-sr-"]:before, span[class^="fi-sr-"]:before, span[class*="fi-sr-"]:before {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  font-family: uicons-solid-rounded !important;
  font-weight: normal !important;
}

.fi-sr-zoom-out:before {
  content: "";
}

.fi-sr-zoom-in:before {
  content: "";
}

.fi-sr-z:before {
  content: "";
}

.fi-sr-yin-yang:before {
  content: "";
}

.fi-sr-yen:before {
  content: "";
}

.fi-sr-y:before {
  content: "";
}

.fi-sr-x:before {
  content: "";
}

.fi-sr-x-ray:before {
  content: "";
}

.fi-sr-wrench-simple:before {
  content: "";
}

.fi-sr-wrench-alt:before {
  content: "";
}

.fi-sr-wreath:before {
  content: "";
}

.fi-sr-worm:before {
  content: "";
}

.fi-sr-world:before {
  content: "";
}

.fi-sr-workshop:before {
  content: "";
}

.fi-sr-workflow:before {
  content: "";
}

.fi-sr-workflow-setting:before {
  content: "";
}

.fi-sr-workflow-setting-alt:before {
  content: "";
}

.fi-sr-workflow-alt:before {
  content: "";
}

.fi-sr-won-sign:before {
  content: "";
}

.fi-sr-woman-scientist:before {
  content: "";
}

.fi-sr-woman-head:before {
  content: "";
}

.fi-sr-wishlist-star:before {
  content: "";
}

.fi-sr-wishlist-heart:before {
  content: "";
}

.fi-sr-wine-glass-empty:before {
  content: "";
}

.fi-sr-wine-glass-crack:before {
  content: "";
}

.fi-sr-wine-bottle:before {
  content: "";
}

.fi-sr-windsock:before {
  content: "";
}

.fi-sr-window-restore:before {
  content: "";
}

.fi-sr-window-minimize:before {
  content: "";
}

.fi-sr-window-maximize:before {
  content: "";
}

.fi-sr-window-frame:before {
  content: "";
}

.fi-sr-window-frame-open:before {
  content: "";
}

.fi-sr-window-alt:before {
  content: "";
}

.fi-sr-wind:before {
  content: "";
}

.fi-sr-wind-warning:before {
  content: "";
}

.fi-sr-wind-turbine:before {
  content: "";
}

.fi-sr-wifi:before {
  content: "";
}

.fi-sr-wifi-slash:before {
  content: "";
}

.fi-sr-wifi-exclamation:before {
  content: "";
}

.fi-sr-wifi-alt:before {
  content: "";
}

.fi-sr-wifi-2:before {
  content: "";
}

.fi-sr-wifi-1:before {
  content: "";
}

.fi-sr-whistle:before {
  content: "";
}

.fi-sr-wheelchair:before {
  content: "";
}

.fi-sr-wheelchair-move:before {
  content: "";
}

.fi-sr-wheat:before {
  content: "";
}

.fi-sr-wheat-slash:before {
  content: "";
}

.fi-sr-wheat-awn:before {
  content: "";
}

.fi-sr-wheat-awn-slash:before {
  content: "";
}

.fi-sr-wheat-awn-circle-exclamation:before {
  content: "";
}

.fi-sr-whale:before {
  content: "";
}

.fi-sr-wednesday:before {
  content: "";
}

.fi-sr-webcam:before {
  content: "";
}

.fi-sr-webcam-slash:before {
  content: "";
}

.fi-sr-waveform:before {
  content: "";
}

.fi-sr-waveform-path:before {
  content: "";
}

.fi-sr-wave-triangle:before {
  content: "";
}

.fi-sr-wave-square:before {
  content: "";
}

.fi-sr-wave-sine:before {
  content: "";
}

.fi-sr-watermelon:before {
  content: "";
}

.fi-sr-water:before {
  content: "";
}

.fi-sr-water-rise:before {
  content: "";
}

.fi-sr-water-lower:before {
  content: "";
}

.fi-sr-water-ladder:before {
  content: "";
}

.fi-sr-water-bottle:before {
  content: "";
}

.fi-sr-watch:before {
  content: "";
}

.fi-sr-watch-smart:before {
  content: "";
}

.fi-sr-watch-fitness:before {
  content: "";
}

.fi-sr-watch-calculator:before {
  content: "";
}

.fi-sr-waste:before {
  content: "";
}

.fi-sr-waste-pollution:before {
  content: "";
}

.fi-sr-washer:before {
  content: "";
}

.fi-sr-warehouse-alt:before {
  content: "";
}

.fi-sr-wallet:before {
  content: "";
}

.fi-sr-wallet-arrow:before {
  content: "";
}

.fi-sr-walking:before {
  content: "";
}

.fi-sr-walkie-talkie:before {
  content: "";
}

.fi-sr-walker:before {
  content: "";
}

.fi-sr-wagon-covered:before {
  content: "";
}

.fi-sr-waffle:before {
  content: "";
}

.fi-sr-w:before {
  content: "";
}

.fi-sr-vr-cardboard:before {
  content: "";
}

.fi-sr-vote-yea:before {
  content: "";
}

.fi-sr-vote-nay:before {
  content: "";
}

.fi-sr-volume:before {
  content: "";
}

.fi-sr-volume-slash:before {
  content: "";
}

.fi-sr-volume-off:before {
  content: "";
}

.fi-sr-volume-mute:before {
  content: "";
}

.fi-sr-volume-down:before {
  content: "";
}

.fi-sr-volleyball:before {
  content: "";
}

.fi-sr-volcano:before {
  content: "";
}

.fi-sr-voicemail:before {
  content: "";
}

.fi-sr-visit:before {
  content: "";
}

.fi-sr-viruses:before {
  content: "";
}

.fi-sr-virus:before {
  content: "";
}

.fi-sr-virus-slash:before {
  content: "";
}

.fi-sr-violin:before {
  content: "";
}

.fi-sr-vihara:before {
  content: "";
}

.fi-sr-videoconference:before {
  content: "";
}

.fi-sr-video-slash:before {
  content: "";
}

.fi-sr-video-plus:before {
  content: "";
}

.fi-sr-video-duration:before {
  content: "";
}

.fi-sr-video-camera:before {
  content: "";
}

.fi-sr-video-camera-alt:before {
  content: "";
}

.fi-sr-video-arrow-up-right:before {
  content: "";
}

.fi-sr-video-arrow-down-left:before {
  content: "";
}

.fi-sr-vest:before {
  content: "";
}

.fi-sr-vest-patches:before {
  content: "";
}

.fi-sr-venus:before {
  content: "";
}

.fi-sr-venus-mars:before {
  content: "";
}

.fi-sr-venus-double:before {
  content: "";
}

.fi-sr-vector:before {
  content: "";
}

.fi-sr-vector-polygon:before {
  content: "";
}

.fi-sr-vector-circle:before {
  content: "";
}

.fi-sr-vector-alt:before {
  content: "";
}

.fi-sr-vault:before {
  content: "";
}

.fi-sr-value-absolute:before {
  content: "";
}

.fi-sr-vacuum:before {
  content: "";
}

.fi-sr-vacuum-robot:before {
  content: "";
}

.fi-sr-v:before {
  content: "";
}

.fi-sr-ux:before {
  content: "";
}

.fi-sr-ux-browser:before {
  content: "";
}

.fi-sr-utility-pole:before {
  content: "";
}

.fi-sr-utility-pole-double:before {
  content: "";
}

.fi-sr-utensils:before {
  content: "";
}

.fi-sr-utensils-slash:before {
  content: "";
}

.fi-sr-users:before {
  content: "";
}

.fi-sr-users-slash:before {
  content: "";
}

.fi-sr-users-medical:before {
  content: "";
}

.fi-sr-users-gear:before {
  content: "";
}

.fi-sr-users-class:before {
  content: "";
}

.fi-sr-users-alt:before {
  content: "";
}

.fi-sr-user:before {
  content: "";
}

.fi-sr-user-xmark:before {
  content: "";
}

.fi-sr-user-visor:before {
  content: "";
}

.fi-sr-user-unlock:before {
  content: "";
}

.fi-sr-user-time:before {
  content: "";
}

.fi-sr-user-tag:before {
  content: "";
}

.fi-sr-user-slash:before {
  content: "";
}

.fi-sr-user-shield:before {
  content: "";
}

.fi-sr-user-salary:before {
  content: "";
}

.fi-sr-user-robot:before {
  content: "";
}

.fi-sr-user-robot-xmarks:before {
  content: "";
}

.fi-sr-user-police:before {
  content: "";
}

.fi-sr-user-pilot:before {
  content: "";
}

.fi-sr-user-pilot-tie:before {
  content: "";
}

.fi-sr-user-pen:before {
  content: "";
}

.fi-sr-user-nurse:before {
  content: "";
}

.fi-sr-user-ninja:before {
  content: "";
}

.fi-sr-user-music:before {
  content: "";
}

.fi-sr-user-minus:before {
  content: "";
}

.fi-sr-user-md:before {
  content: "";
}

.fi-sr-user-md-chat:before {
  content: "";
}

.fi-sr-user-lock:before {
  content: "";
}

.fi-sr-user-key:before {
  content: "";
}

.fi-sr-user-interface-ui:before {
  content: "";
}

.fi-sr-user-injured:before {
  content: "";
}

.fi-sr-user-helmet-safety:before {
  content: "";
}

.fi-sr-user-headset:before {
  content: "";
}

.fi-sr-user-graduate:before {
  content: "";
}

.fi-sr-user-gear:before {
  content: "";
}

.fi-sr-user-crown:before {
  content: "";
}

.fi-sr-user-cowboy:before {
  content: "";
}

.fi-sr-user-chef:before {
  content: "";
}

.fi-sr-user-check:before {
  content: "";
}

.fi-sr-user-astronaut:before {
  content: "";
}

.fi-sr-user-alien:before {
  content: "";
}

.fi-sr-user-add:before {
  content: "";
}

.fi-sr-usd-square:before {
  content: "";
}

.fi-sr-usd-circle:before {
  content: "";
}

.fi-sr-usb-pendrive:before {
  content: "";
}

.fi-sr-url:before {
  content: "";
}

.fi-sr-upload:before {
  content: "";
}

.fi-sr-up:before {
  content: "";
}

.fi-sr-up-right:before {
  content: "";
}

.fi-sr-up-right-from-square:before {
  content: "";
}

.fi-sr-up-left:before {
  content: "";
}

.fi-sr-unlock:before {
  content: "";
}

.fi-sr-universal-access:before {
  content: "";
}

.fi-sr-uniform-martial-arts:before {
  content: "";
}

.fi-sr-unicorn:before {
  content: "";
}

.fi-sr-undo:before {
  content: "";
}

.fi-sr-undo-alt:before {
  content: "";
}

.fi-sr-underline:before {
  content: "";
}

.fi-sr-umbrella:before {
  content: "";
}

.fi-sr-umbrella-beach:before {
  content: "";
}

.fi-sr-ui-ux:before {
  content: "";
}

.fi-sr-ufo:before {
  content: "";
}

.fi-sr-ufo-beam:before {
  content: "";
}

.fi-sr-u:before {
  content: "";
}

.fi-sr-typewriter:before {
  content: "";
}

.fi-sr-tv-retro:before {
  content: "";
}

.fi-sr-tv-music:before {
  content: "";
}

.fi-sr-turtle:before {
  content: "";
}

.fi-sr-turntable:before {
  content: "";
}

.fi-sr-turkey:before {
  content: "";
}

.fi-sr-tugrik-sign:before {
  content: "";
}

.fi-sr-tuesday:before {
  content: "";
}

.fi-sr-tubes:before {
  content: "";
}

.fi-sr-tty:before {
  content: "";
}

.fi-sr-tty-answer:before {
  content: "";
}

.fi-sr-tshirt:before {
  content: "";
}

.fi-sr-trust:before {
  content: "";
}

.fi-sr-trust-alt:before {
  content: "";
}

.fi-sr-trumpet:before {
  content: "";
}

.fi-sr-truck-tow:before {
  content: "";
}

.fi-sr-truck-side:before {
  content: "";
}

.fi-sr-truck-ramp:before {
  content: "";
}

.fi-sr-truck-plow:before {
  content: "";
}

.fi-sr-truck-pickup:before {
  content: "";
}

.fi-sr-truck-moving:before {
  content: "";
}

.fi-sr-truck-monster:before {
  content: "";
}

.fi-sr-truck-medical:before {
  content: "";
}

.fi-sr-truck-loading:before {
  content: "";
}

.fi-sr-truck-front:before {
  content: "";
}

.fi-sr-truck-flatbed:before {
  content: "";
}

.fi-sr-truck-couch:before {
  content: "";
}

.fi-sr-truck-container:before {
  content: "";
}

.fi-sr-truck-container-empty:before {
  content: "";
}

.fi-sr-truck-check:before {
  content: "";
}

.fi-sr-truck-box:before {
  content: "";
}

.fi-sr-truck-bolt:before {
  content: "";
}

.fi-sr-truck-arrow-right:before {
  content: "";
}

.fi-sr-truck-arrow-left:before {
  content: "";
}

.fi-sr-trophy:before {
  content: "";
}

.fi-sr-trophy-star:before {
  content: "";
}

.fi-sr-trillium:before {
  content: "";
}

.fi-sr-triangle:before {
  content: "";
}

.fi-sr-triangle-warning:before {
  content: "";
}

.fi-sr-triangle-person-digging:before {
  content: "";
}

.fi-sr-triangle-music:before {
  content: "";
}

.fi-sr-trees:before {
  content: "";
}

.fi-sr-trees-alt:before {
  content: "";
}

.fi-sr-tree:before {
  content: "";
}

.fi-sr-tree-deciduous:before {
  content: "";
}

.fi-sr-tree-christmas:before {
  content: "";
}

.fi-sr-tree-alt:before {
  content: "";
}

.fi-sr-treatment:before {
  content: "";
}

.fi-sr-treasure-chest:before {
  content: "";
}

.fi-sr-trash:before {
  content: "";
}

.fi-sr-trash-xmark:before {
  content: "";
}

.fi-sr-trash-undo:before {
  content: "";
}

.fi-sr-trash-undo-alt:before {
  content: "";
}

.fi-sr-trash-slash:before {
  content: "";
}

.fi-sr-trash-restore:before {
  content: "";
}

.fi-sr-trash-restore-alt:before {
  content: "";
}

.fi-sr-trash-plus:before {
  content: "";
}

.fi-sr-trash-list:before {
  content: "";
}

.fi-sr-trash-clock:before {
  content: "";
}

.fi-sr-trash-check:before {
  content: "";
}

.fi-sr-trash-can-slash:before {
  content: "";
}

.fi-sr-trash-can-plus:before {
  content: "";
}

.fi-sr-trash-can-list:before {
  content: "";
}

.fi-sr-trash-can-clock:before {
  content: "";
}

.fi-sr-trash-can-check:before {
  content: "";
}

.fi-sr-transporter:before {
  content: "";
}

.fi-sr-transporter-empty:before {
  content: "";
}

.fi-sr-transporter-7:before {
  content: "";
}

.fi-sr-transporter-6:before {
  content: "";
}

.fi-sr-transporter-5:before {
  content: "";
}

.fi-sr-transporter-4:before {
  content: "";
}

.fi-sr-transporter-3:before {
  content: "";
}

.fi-sr-transporter-2:before {
  content: "";
}

.fi-sr-transporter-1:before {
  content: "";
}

.fi-sr-transgender:before {
  content: "";
}

.fi-sr-transformer-bolt:before {
  content: "";
}

.fi-sr-transformation-shapes:before {
  content: "";
}

.fi-sr-transformation-design:before {
  content: "";
}

.fi-sr-transformation-circle:before {
  content: "";
}

.fi-sr-transformation-block:before {
  content: "";
}

.fi-sr-transform:before {
  content: "";
}

.fi-sr-tram:before {
  content: "";
}

.fi-sr-train:before {
  content: "";
}

.fi-sr-train-tram:before {
  content: "";
}

.fi-sr-train-subway-tunnel:before {
  content: "";
}

.fi-sr-train-station:before {
  content: "";
}

.fi-sr-train-station-building:before {
  content: "";
}

.fi-sr-train-side:before {
  content: "";
}

.fi-sr-train-journey:before {
  content: "";
}

.fi-sr-trailer:before {
  content: "";
}

.fi-sr-traffic-light:before {
  content: "";
}

.fi-sr-traffic-light-stop:before {
  content: "";
}

.fi-sr-traffic-light-slow:before {
  content: "";
}

.fi-sr-traffic-light-go:before {
  content: "";
}

.fi-sr-traffic-cone:before {
  content: "";
}

.fi-sr-trademark:before {
  content: "";
}

.fi-sr-tractor:before {
  content: "";
}

.fi-sr-track:before {
  content: "";
}

.fi-sr-tower-control:before {
  content: "";
}

.fi-sr-tour-virtual:before {
  content: "";
}

.fi-sr-tour-guide-people:before {
  content: "";
}

.fi-sr-total:before {
  content: "";
}

.fi-sr-tornado:before {
  content: "";
}

.fi-sr-torii-gate:before {
  content: "";
}

.fi-sr-toothbrush:before {
  content: "";
}

.fi-sr-tooth:before {
  content: "";
}

.fi-sr-tools:before {
  content: "";
}

.fi-sr-tool-marquee:before {
  content: "";
}

.fi-sr-tool-crop:before {
  content: "";
}

.fi-sr-tool-box:before {
  content: "";
}

.fi-sr-tombstone:before {
  content: "";
}

.fi-sr-tombstone-alt:before {
  content: "";
}

.fi-sr-tomato:before {
  content: "";
}

.fi-sr-token:before {
  content: "";
}

.fi-sr-toilet:before {
  content: "";
}

.fi-sr-toilet-paper-under:before {
  content: "";
}

.fi-sr-toilet-paper-under-slash:before {
  content: "";
}

.fi-sr-toilet-paper-slash:before {
  content: "";
}

.fi-sr-toilet-paper-blank:before {
  content: "";
}

.fi-sr-toilet-paper-blank-under:before {
  content: "";
}

.fi-sr-toggle-on:before {
  content: "";
}

.fi-sr-toggle-off:before {
  content: "";
}

.fi-sr-together-people:before {
  content: "";
}

.fi-sr-to-do:before {
  content: "";
}

.fi-sr-to-do-alt:before {
  content: "";
}

.fi-sr-tired:before {
  content: "";
}

.fi-sr-tire:before {
  content: "";
}

.fi-sr-tire-rugged:before {
  content: "";
}

.fi-sr-tire-pressure-warning:before {
  content: "";
}

.fi-sr-tire-flat:before {
  content: "";
}

.fi-sr-tip-coin:before {
  content: "";
}

.fi-sr-tint-slash:before {
  content: "";
}

.fi-sr-times-hexagon:before {
  content: "";
}

.fi-sr-time-twenty-four:before {
  content: "";
}

.fi-sr-time-quarter-to:before {
  content: "";
}

.fi-sr-time-quarter-past:before {
  content: "";
}

.fi-sr-time-past:before {
  content: "";
}

.fi-sr-time-oclock:before {
  content: "";
}

.fi-sr-time-half-past:before {
  content: "";
}

.fi-sr-time-forward:before {
  content: "";
}

.fi-sr-time-forward-ten:before {
  content: "";
}

.fi-sr-time-forward-sixty:before {
  content: "";
}

.fi-sr-time-fast:before {
  content: "";
}

.fi-sr-time-delete:before {
  content: "";
}

.fi-sr-time-check:before {
  content: "";
}

.fi-sr-time-add:before {
  content: "";
}

.fi-sr-tilde:before {
  content: "";
}

.fi-sr-tie:before {
  content: "";
}

.fi-sr-tickets-airline:before {
  content: "";
}

.fi-sr-ticket:before {
  content: "";
}

.fi-sr-ticket-alt:before {
  content: "";
}

.fi-sr-ticket-airline:before {
  content: "";
}

.fi-sr-thursday:before {
  content: "";
}

.fi-sr-thunderstorm:before {
  content: "";
}

.fi-sr-thunderstorm-sun:before {
  content: "";
}

.fi-sr-thunderstorm-moon:before {
  content: "";
}

.fi-sr-thumbtack:before {
  content: "";
}

.fi-sr-thumbs-up:before {
  content: "";
}

.fi-sr-thumbs-down:before {
  content: "";
}

.fi-sr-thought-bubble:before {
  content: "";
}

.fi-sr-third:before {
  content: "";
}

.fi-sr-third-medal:before {
  content: "";
}

.fi-sr-third-laurel:before {
  content: "";
}

.fi-sr-third-award:before {
  content: "";
}

.fi-sr-theta:before {
  content: "";
}

.fi-sr-thermometer-three-quarters:before {
  content: "";
}

.fi-sr-thermometer-quarter:before {
  content: "";
}

.fi-sr-thermometer-half:before {
  content: "";
}

.fi-sr-thermometer-full:before {
  content: "";
}

.fi-sr-thermometer-empty:before {
  content: "";
}

.fi-sr-thermometer-alt:before {
  content: "";
}

.fi-sr-theater-masks:before {
  content: "";
}

.fi-sr-text:before {
  content: "";
}

.fi-sr-text-width:before {
  content: "";
}

.fi-sr-text-slash:before {
  content: "";
}

.fi-sr-text-size:before {
  content: "";
}

.fi-sr-text-shadow:before {
  content: "";
}

.fi-sr-text-height:before {
  content: "";
}

.fi-sr-text-check:before {
  content: "";
}

.fi-sr-text-box:before {
  content: "";
}

.fi-sr-text-box-edit:before {
  content: "";
}

.fi-sr-text-box-dots:before {
  content: "";
}

.fi-sr-test:before {
  content: "";
}

.fi-sr-test-tube:before {
  content: "";
}

.fi-sr-terrace:before {
  content: "";
}

.fi-sr-terminal:before {
  content: "";
}

.fi-sr-tents:before {
  content: "";
}

.fi-sr-tent-arrows-down:before {
  content: "";
}

.fi-sr-tent-arrow-turn-left:before {
  content: "";
}

.fi-sr-tent-arrow-left-right:before {
  content: "";
}

.fi-sr-tent-arrow-down-to-line:before {
  content: "";
}

.fi-sr-tennis:before {
  content: "";
}

.fi-sr-tenge:before {
  content: "";
}

.fi-sr-template:before {
  content: "";
}

.fi-sr-template-alt:before {
  content: "";
}

.fi-sr-temperature-up:before {
  content: "";
}

.fi-sr-temperature-low:before {
  content: "";
}

.fi-sr-temperature-list:before {
  content: "";
}

.fi-sr-temperature-high:before {
  content: "";
}

.fi-sr-temperature-frigid:before {
  content: "";
}

.fi-sr-temperature-down:before {
  content: "";
}

.fi-sr-telescope:before {
  content: "";
}

.fi-sr-teeth-open:before {
  content: "";
}

.fi-sr-teddy-bear:before {
  content: "";
}

.fi-sr-team-check:before {
  content: "";
}

.fi-sr-team-check-alt:before {
  content: "";
}

.fi-sr-taxi:before {
  content: "";
}

.fi-sr-taxi-bus:before {
  content: "";
}

.fi-sr-tax:before {
  content: "";
}

.fi-sr-tax-alt:before {
  content: "";
}

.fi-sr-target:before {
  content: "";
}

.fi-sr-target-audience:before {
  content: "";
}

.fi-sr-tape:before {
  content: "";
}

.fi-sr-tap:before {
  content: "";
}

.fi-sr-tank-water:before {
  content: "";
}

.fi-sr-tamale:before {
  content: "";
}

.fi-sr-tally:before {
  content: "";
}

.fi-sr-tally-4:before {
  content: "";
}

.fi-sr-tally-3:before {
  content: "";
}

.fi-sr-tally-2:before {
  content: "";
}

.fi-sr-tally-1:before {
  content: "";
}

.fi-sr-tags:before {
  content: "";
}

.fi-sr-taco:before {
  content: "";
}

.fi-sr-tachometer:before {
  content: "";
}

.fi-sr-tachometer-slowest:before {
  content: "";
}

.fi-sr-tachometer-slow:before {
  content: "";
}

.fi-sr-tachometer-fastest:before {
  content: "";
}

.fi-sr-tachometer-fast:before {
  content: "";
}

.fi-sr-tachometer-average:before {
  content: "";
}

.fi-sr-tachometer-alt-slowest:before {
  content: "";
}

.fi-sr-tachometer-alt-slow:before {
  content: "";
}

.fi-sr-tachometer-alt-fastest:before {
  content: "";
}

.fi-sr-tachometer-alt-average:before {
  content: "";
}

.fi-sr-tablet:before {
  content: "";
}

.fi-sr-tablet-rugged:before {
  content: "";
}

.fi-sr-tablet-android:before {
  content: "";
}

.fi-sr-tablet-android-alt:before {
  content: "";
}

.fi-sr-table:before {
  content: "";
}

.fi-sr-table-tree:before {
  content: "";
}

.fi-sr-table-rows:before {
  content: "";
}

.fi-sr-table-pivot:before {
  content: "";
}

.fi-sr-table-picnic:before {
  content: "";
}

.fi-sr-table-list:before {
  content: "";
}

.fi-sr-table-layout:before {
  content: "";
}

.fi-sr-table-columns:before {
  content: "";
}

.fi-sr-tab-folder:before {
  content: "";
}

.fi-sr-t:before {
  content: "";
}

.fi-sr-syringe:before {
  content: "";
}

.fi-sr-synagogue:before {
  content: "";
}

.fi-sr-symbols:before {
  content: "";
}

.fi-sr-symbol:before {
  content: "";
}

.fi-sr-sword:before {
  content: "";
}

.fi-sr-swipe-up:before {
  content: "";
}

.fi-sr-swipe-right:before {
  content: "";
}

.fi-sr-swipe-left:before {
  content: "";
}

.fi-sr-swipe-down:before {
  content: "";
}

.fi-sr-swimming-pool:before {
  content: "";
}

.fi-sr-swimmer:before {
  content: "";
}

.fi-sr-swatchbook:before {
  content: "";
}

.fi-sr-svg:before {
  content: "";
}

.fi-sr-sushi:before {
  content: "";
}

.fi-sr-sushi-roll:before {
  content: "";
}

.fi-sr-sushi-alt:before {
  content: "";
}

.fi-sr-surprise:before {
  content: "";
}

.fi-sr-surfing:before {
  content: "";
}

.fi-sr-superscript:before {
  content: "";
}

.fi-sr-sunset:before {
  content: "";
}

.fi-sr-sunrise:before {
  content: "";
}

.fi-sr-sunrise-alt:before {
  content: "";
}

.fi-sr-sunglasses:before {
  content: "";
}

.fi-sr-sunglasses-alt:before {
  content: "";
}

.fi-sr-sunday:before {
  content: "";
}

.fi-sr-sun:before {
  content: "";
}

.fi-sr-sun-plant-wilt:before {
  content: "";
}

.fi-sr-sun-dust:before {
  content: "";
}

.fi-sr-summer:before {
  content: "";
}

.fi-sr-suitcase-alt:before {
  content: "";
}

.fi-sr-subway:before {
  content: "";
}

.fi-sr-subtitles:before {
  content: "";
}

.fi-sr-subscription:before {
  content: "";
}

.fi-sr-subscription-alt:before {
  content: "";
}

.fi-sr-subscript:before {
  content: "";
}

.fi-sr-student:before {
  content: "";
}

.fi-sr-student-alt:before {
  content: "";
}

.fi-sr-stroopwafel:before {
  content: "";
}

.fi-sr-strikethrough:before {
  content: "";
}

.fi-sr-stretcher:before {
  content: "";
}

.fi-sr-street-view:before {
  content: "";
}

.fi-sr-strawberry:before {
  content: "";
}

.fi-sr-story-fantasy:before {
  content: "";
}

.fi-sr-story-fairy-tale:before {
  content: "";
}

.fi-sr-story-book:before {
  content: "";
}

.fi-sr-store-slash:before {
  content: "";
}

.fi-sr-store-lock:before {
  content: "";
}

.fi-sr-store-alt:before {
  content: "";
}

.fi-sr-stopwatch:before {
  content: "";
}

.fi-sr-stop:before {
  content: "";
}

.fi-sr-stop-circle:before {
  content: "";
}

.fi-sr-stomach:before {
  content: "";
}

.fi-sr-stocking:before {
  content: "";
}

.fi-sr-sticker:before {
  content: "";
}

.fi-sr-stethoscope:before {
  content: "";
}

.fi-sr-sterling-sign:before {
  content: "";
}

.fi-sr-step-forward:before {
  content: "";
}

.fi-sr-step-backward:before {
  content: "";
}

.fi-sr-steering-wheel:before {
  content: "";
}

.fi-sr-steak:before {
  content: "";
}

.fi-sr-stats:before {
  content: "";
}

.fi-sr-state-country:before {
  content: "";
}

.fi-sr-stars:before {
  content: "";
}

.fi-sr-starfighter:before {
  content: "";
}

.fi-sr-star:before {
  content: "";
}

.fi-sr-star-shooting:before {
  content: "";
}

.fi-sr-star-sharp-half:before {
  content: "";
}

.fi-sr-star-sharp-half-stroke:before {
  content: "";
}

.fi-sr-star-of-david:before {
  content: "";
}

.fi-sr-star-octogram:before {
  content: "";
}

.fi-sr-star-exclamation:before {
  content: "";
}

.fi-sr-star-comment-alt:before {
  content: "";
}

.fi-sr-star-christmas:before {
  content: "";
}

.fi-sr-star-and-crescent:before {
  content: "";
}

.fi-sr-standard-definition:before {
  content: "";
}

.fi-sr-stamp:before {
  content: "";
}

.fi-sr-stairs:before {
  content: "";
}

.fi-sr-stage:before {
  content: "";
}

.fi-sr-stage-theatre:before {
  content: "";
}

.fi-sr-stage-concert:before {
  content: "";
}

.fi-sr-staff:before {
  content: "";
}

.fi-sr-squirrel:before {
  content: "";
}

.fi-sr-squircle:before {
  content: "";
}

.fi-sr-squid:before {
  content: "";
}

.fi-sr-square:before {
  content: "";
}

.fi-sr-square-z:before {
  content: "";
}

.fi-sr-square-y:before {
  content: "";
}

.fi-sr-square-x:before {
  content: "";
}

.fi-sr-square-w:before {
  content: "";
}

.fi-sr-square-v:before {
  content: "";
}

.fi-sr-square-up-right:before {
  content: "";
}

.fi-sr-square-u:before {
  content: "";
}

.fi-sr-square-terminal:before {
  content: "";
}

.fi-sr-square-t:before {
  content: "";
}

.fi-sr-square-star:before {
  content: "";
}

.fi-sr-square-small:before {
  content: "";
}

.fi-sr-square-s:before {
  content: "";
}

.fi-sr-square-root:before {
  content: "";
}

.fi-sr-square-r:before {
  content: "";
}

.fi-sr-square-quote:before {
  content: "";
}

.fi-sr-square-q:before {
  content: "";
}

.fi-sr-square-poll-vertical:before {
  content: "";
}

.fi-sr-square-poll-horizontal:before {
  content: "";
}

.fi-sr-square-plus:before {
  content: "";
}

.fi-sr-square-phone-hangup:before {
  content: "";
}

.fi-sr-square-p:before {
  content: "";
}

.fi-sr-square-o:before {
  content: "";
}

.fi-sr-square-n:before {
  content: "";
}

.fi-sr-square-minus:before {
  content: "";
}

.fi-sr-square-m:before {
  content: "";
}

.fi-sr-square-l:before {
  content: "";
}

.fi-sr-square-kanban:before {
  content: "";
}

.fi-sr-square-k:before {
  content: "";
}

.fi-sr-square-j:before {
  content: "";
}

.fi-sr-square-info:before {
  content: "";
}

.fi-sr-square-i:before {
  content: "";
}

.fi-sr-square-heart:before {
  content: "";
}

.fi-sr-square-h:before {
  content: "";
}

.fi-sr-square-g:before {
  content: "";
}

.fi-sr-square-f:before {
  content: "";
}

.fi-sr-square-exclamation:before {
  content: "";
}

.fi-sr-square-ellipsis:before {
  content: "";
}

.fi-sr-square-ellipsis-vertical:before {
  content: "";
}

.fi-sr-square-e:before {
  content: "";
}

.fi-sr-square-divide:before {
  content: "";
}

.fi-sr-square-dashed:before {
  content: "";
}

.fi-sr-square-d:before {
  content: "";
}

.fi-sr-square-code:before {
  content: "";
}

.fi-sr-square-c:before {
  content: "";
}

.fi-sr-square-bolt:before {
  content: "";
}

.fi-sr-square-b:before {
  content: "";
}

.fi-sr-square-a:before {
  content: "";
}

.fi-sr-square-9:before {
  content: "";
}

.fi-sr-square-8:before {
  content: "";
}

.fi-sr-square-7:before {
  content: "";
}

.fi-sr-square-6:before {
  content: "";
}

.fi-sr-square-5:before {
  content: "";
}

.fi-sr-square-4:before {
  content: "";
}

.fi-sr-square-3:before {
  content: "";
}

.fi-sr-square-2:before {
  content: "";
}

.fi-sr-square-1:before {
  content: "";
}

.fi-sr-square-0:before {
  content: "";
}

.fi-sr-spy:before {
  content: "";
}

.fi-sr-sprinkler:before {
  content: "";
}

.fi-sr-spring-calendar:before {
  content: "";
}

.fi-sr-spray-can:before {
  content: "";
}

.fi-sr-spray-can-sparkles:before {
  content: "";
}

.fi-sr-spoon:before {
  content: "";
}

.fi-sr-splotch:before {
  content: "";
}

.fi-sr-split:before {
  content: "";
}

.fi-sr-spinner:before {
  content: "";
}

.fi-sr-spider:before {
  content: "";
}

.fi-sr-spider-web:before {
  content: "";
}

.fi-sr-spider-black-widow:before {
  content: "";
}

.fi-sr-sphere:before {
  content: "";
}

.fi-sr-speedometer-kpi:before {
  content: "";
}

.fi-sr-speedometer-arrow:before {
  content: "";
}

.fi-sr-speakers:before {
  content: "";
}

.fi-sr-speaker:before {
  content: "";
}

.fi-sr-sparkles:before {
  content: "";
}

.fi-sr-spaghetti-monster-flying:before {
  content: "";
}

.fi-sr-spade:before {
  content: "";
}

.fi-sr-space-station-moon:before {
  content: "";
}

.fi-sr-space-station-moon-alt:before {
  content: "";
}

.fi-sr-space-shuttle:before {
  content: "";
}

.fi-sr-spa:before {
  content: "";
}

.fi-sr-source-document:before {
  content: "";
}

.fi-sr-source-document-alt:before {
  content: "";
}

.fi-sr-source-data:before {
  content: "";
}

.fi-sr-soup:before {
  content: "";
}

.fi-sr-sort:before {
  content: "";
}

.fi-sr-sort-size-up:before {
  content: "";
}

.fi-sr-sort-size-down:before {
  content: "";
}

.fi-sr-sort-shapes-up:before {
  content: "";
}

.fi-sr-sort-shapes-down:before {
  content: "";
}

.fi-sr-sort-numeric-down:before {
  content: "";
}

.fi-sr-sort-numeric-down-alt:before {
  content: "";
}

.fi-sr-sort-down:before {
  content: "";
}

.fi-sr-sort-circle:before {
  content: "";
}

.fi-sr-sort-circle-up:before {
  content: "";
}

.fi-sr-sort-circle-down:before {
  content: "";
}

.fi-sr-sort-amount-up:before {
  content: "";
}

.fi-sr-sort-amount-up-alt:before {
  content: "";
}

.fi-sr-sort-amount-down:before {
  content: "";
}

.fi-sr-sort-amount-down-alt:before {
  content: "";
}

.fi-sr-sort-alt:before {
  content: "";
}

.fi-sr-sort-alpha-up:before {
  content: "";
}

.fi-sr-sort-alpha-up-alt:before {
  content: "";
}

.fi-sr-sort-alpha-down:before {
  content: "";
}

.fi-sr-sort-alpha-down-alt:before {
  content: "";
}

.fi-sr-sold-signal:before {
  content: "";
}

.fi-sr-sold-house:before {
  content: "";
}

.fi-sr-solar-system:before {
  content: "";
}

.fi-sr-solar-panel:before {
  content: "";
}

.fi-sr-sofa:before {
  content: "";
}

.fi-sr-sofa-size:before {
  content: "";
}

.fi-sr-socks:before {
  content: "";
}

.fi-sr-soap:before {
  content: "";
}

.fi-sr-soap-alt:before {
  content: "";
}

.fi-sr-snowplow:before {
  content: "";
}

.fi-sr-snowmobile:before {
  content: "";
}

.fi-sr-snowman-head:before {
  content: "";
}

.fi-sr-snowman-alt:before {
  content: "";
}

.fi-sr-snowflakes:before {
  content: "";
}

.fi-sr-snowflake:before {
  content: "";
}

.fi-sr-snowflake-droplets:before {
  content: "";
}

.fi-sr-snowboarding:before {
  content: "";
}

.fi-sr-snow-blowing:before {
  content: "";
}

.fi-sr-snooze:before {
  content: "";
}

.fi-sr-snap:before {
  content: "";
}

.fi-sr-snake:before {
  content: "";
}

.fi-sr-smoking:before {
  content: "";
}

.fi-sr-smoking-ban:before {
  content: "";
}

.fi-sr-smoke:before {
  content: "";
}

.fi-sr-smog:before {
  content: "";
}

.fi-sr-smiley-comment-alt:before {
  content: "";
}

.fi-sr-smile:before {
  content: "";
}

.fi-sr-smile-wink:before {
  content: "";
}

.fi-sr-smile-plus:before {
  content: "";
}

.fi-sr-smile-beam:before {
  content: "";
}

.fi-sr-smartphone:before {
  content: "";
}

.fi-sr-sliders-v:before {
  content: "";
}

.fi-sr-sliders-v-square:before {
  content: "";
}

.fi-sr-sliders-h-square:before {
  content: "";
}

.fi-sr-sleigh:before {
  content: "";
}

.fi-sr-sledding:before {
  content: "";
}

.fi-sr-slash:before {
  content: "";
}

.fi-sr-skull:before {
  content: "";
}

.fi-sr-skull-crossbones:before {
  content: "";
}

.fi-sr-skull-cow:before {
  content: "";
}

.fi-sr-skin:before {
  content: "";
}

.fi-sr-skin-hair:before {
  content: "";
}

.fi-sr-skin-drop:before {
  content: "";
}

.fi-sr-skin-arrow:before {
  content: "";
}

.fi-sr-skill:before {
  content: "";
}

.fi-sr-skill-user:before {
  content: "";
}

.fi-sr-skill-alt:before {
  content: "";
}

.fi-sr-skiing:before {
  content: "";
}

.fi-sr-skiing-nordic:before {
  content: "";
}

.fi-sr-ski-lift:before {
  content: "";
}

.fi-sr-ski-jump:before {
  content: "";
}

.fi-sr-ski-boot-ski:before {
  content: "";
}

.fi-sr-skewer:before {
  content: "";
}

.fi-sr-skeleton:before {
  content: "";
}

.fi-sr-skating:before {
  content: "";
}

.fi-sr-skateboard:before {
  content: "";
}

.fi-sr-sitemap:before {
  content: "";
}

.fi-sr-site:before {
  content: "";
}

.fi-sr-site-browser:before {
  content: "";
}

.fi-sr-site-alt:before {
  content: "";
}

.fi-sr-siren-on:before {
  content: "";
}

.fi-sr-sink:before {
  content: "";
}

.fi-sr-sim-cards:before {
  content: "";
}

.fi-sr-sim-card:before {
  content: "";
}

.fi-sr-signature:before {
  content: "";
}

.fi-sr-signature-slash:before {
  content: "";
}

.fi-sr-signature-lock:before {
  content: "";
}

.fi-sr-signal-stream:before {
  content: "";
}

.fi-sr-signal-stream-slash:before {
  content: "";
}

.fi-sr-signal-bars-weak:before {
  content: "";
}

.fi-sr-signal-bars-good:before {
  content: "";
}

.fi-sr-signal-bars-fair:before {
  content: "";
}

.fi-sr-signal-alt:before {
  content: "";
}

.fi-sr-signal-alt-slash:before {
  content: "";
}

.fi-sr-signal-alt-2:before {
  content: "";
}

.fi-sr-signal-alt-1:before {
  content: "";
}

.fi-sr-sign-up:before {
  content: "";
}

.fi-sr-sign-out-alt:before {
  content: "";
}

.fi-sr-sign-in-alt:before {
  content: "";
}

.fi-sr-sign-hanging:before {
  content: "";
}

.fi-sr-sigma:before {
  content: "";
}

.fi-sr-sidebar:before {
  content: "";
}

.fi-sr-sidebar-flip:before {
  content: "";
}

.fi-sr-shuttlecock:before {
  content: "";
}

.fi-sr-shuttle-van:before {
  content: "";
}

.fi-sr-shuffle:before {
  content: "";
}

.fi-sr-shrimp:before {
  content: "";
}

.fi-sr-shredder:before {
  content: "";
}

.fi-sr-shower:before {
  content: "";
}

.fi-sr-shower-down:before {
  content: "";
}

.fi-sr-shovel:before {
  content: "";
}

.fi-sr-shovel-snow:before {
  content: "";
}

.fi-sr-shopping-cart:before {
  content: "";
}

.fi-sr-shopping-cart-check:before {
  content: "";
}

.fi-sr-shopping-cart-add:before {
  content: "";
}

.fi-sr-shopping-basket:before {
  content: "";
}

.fi-sr-shopping-bag:before {
  content: "";
}

.fi-sr-shopping-bag-add:before {
  content: "";
}

.fi-sr-shop:before {
  content: "";
}

.fi-sr-shop-slash:before {
  content: "";
}

.fi-sr-shop-lock:before {
  content: "";
}

.fi-sr-shoe-prints:before {
  content: "";
}

.fi-sr-shish-kebab:before {
  content: "";
}

.fi-sr-shirt:before {
  content: "";
}

.fi-sr-shirt-tank-top:before {
  content: "";
}

.fi-sr-shirt-running:before {
  content: "";
}

.fi-sr-shirt-long-sleeve:before {
  content: "";
}

.fi-sr-shipping-timed:before {
  content: "";
}

.fi-sr-shipping-fast:before {
  content: "";
}

.fi-sr-ship:before {
  content: "";
}

.fi-sr-ship-side:before {
  content: "";
}

.fi-sr-shield:before {
  content: "";
}

.fi-sr-shield-xmark:before {
  content: "";
}

.fi-sr-shield-virus:before {
  content: "";
}

.fi-sr-shield-slash:before {
  content: "";
}

.fi-sr-shield-plus:before {
  content: "";
}

.fi-sr-shield-minus:before {
  content: "";
}

.fi-sr-shield-keyhole:before {
  content: "";
}

.fi-sr-shield-interrogation:before {
  content: "";
}

.fi-sr-shield-exclamation:before {
  content: "";
}

.fi-sr-shield-dog:before {
  content: "";
}

.fi-sr-shield-cross:before {
  content: "";
}

.fi-sr-shield-check:before {
  content: "";
}

.fi-sr-shield-cat:before {
  content: "";
}

.fi-sr-shield-alt:before {
  content: "";
}

.fi-sr-shelves:before {
  content: "";
}

.fi-sr-shekel-sign:before {
  content: "";
}

.fi-sr-sheep:before {
  content: "";
}

.fi-sr-share:before {
  content: "";
}

.fi-sr-share-square:before {
  content: "";
}

.fi-sr-share-alt-square:before {
  content: "";
}

.fi-sr-settings:before {
  content: "";
}

.fi-sr-settings-sliders:before {
  content: "";
}

.fi-sr-sensor:before {
  content: "";
}

.fi-sr-sensor-smoke:before {
  content: "";
}

.fi-sr-sensor-on:before {
  content: "";
}

.fi-sr-sensor-fire:before {
  content: "";
}

.fi-sr-sensor-alert:before {
  content: "";
}

.fi-sr-send-backward:before {
  content: "";
}

.fi-sr-send-back:before {
  content: "";
}

.fi-sr-seller:before {
  content: "";
}

.fi-sr-seller-store:before {
  content: "";
}

.fi-sr-selection:before {
  content: "";
}

.fi-sr-select:before {
  content: "";
}

.fi-sr-seedling:before {
  content: "";
}

.fi-sr-second:before {
  content: "";
}

.fi-sr-second-medal:before {
  content: "";
}

.fi-sr-second-laurel:before {
  content: "";
}

.fi-sr-second-award:before {
  content: "";
}

.fi-sr-seat-airline:before {
  content: "";
}

.fi-sr-search:before {
  content: "";
}

.fi-sr-search-location:before {
  content: "";
}

.fi-sr-search-heart:before {
  content: "";
}

.fi-sr-search-dollar:before {
  content: "";
}

.fi-sr-search-alt:before {
  content: "";
}

.fi-sr-seal:before {
  content: "";
}

.fi-sr-seal-question:before {
  content: "";
}

.fi-sr-seal-exclamation:before {
  content: "";
}

.fi-sr-sd-cards:before {
  content: "";
}

.fi-sr-sd-card:before {
  content: "";
}

.fi-sr-scythe:before {
  content: "";
}

.fi-sr-scrubber:before {
  content: "";
}

.fi-sr-scroll:before {
  content: "";
}

.fi-sr-scroll-torah:before {
  content: "";
}

.fi-sr-scroll-old:before {
  content: "";
}

.fi-sr-script:before {
  content: "";
}

.fi-sr-scribble:before {
  content: "";
}

.fi-sr-screwdriver:before {
  content: "";
}

.fi-sr-screencast:before {
  content: "";
}

.fi-sr-screen:before {
  content: "";
}

.fi-sr-scissors:before {
  content: "";
}

.fi-sr-school:before {
  content: "";
}

.fi-sr-school-bus:before {
  content: "";
}

.fi-sr-scarf:before {
  content: "";
}

.fi-sr-scarecrow:before {
  content: "";
}

.fi-sr-scanner-touchscreen:before {
  content: "";
}

.fi-sr-scanner-keyboard:before {
  content: "";
}

.fi-sr-scanner-image:before {
  content: "";
}

.fi-sr-scanner-gun:before {
  content: "";
}

.fi-sr-scalpel:before {
  content: "";
}

.fi-sr-scalpel-path:before {
  content: "";
}

.fi-sr-scale:before {
  content: "";
}

.fi-sr-scale-comparison:before {
  content: "";
}

.fi-sr-scale-comparison-alt:before {
  content: "";
}

.fi-sr-saxophone:before {
  content: "";
}

.fi-sr-sax-hot:before {
  content: "";
}

.fi-sr-sausage:before {
  content: "";
}

.fi-sr-sauce:before {
  content: "";
}

.fi-sr-saturday:before {
  content: "";
}

.fi-sr-satellite:before {
  content: "";
}

.fi-sr-satellite-dish:before {
  content: "";
}

.fi-sr-sandwich:before {
  content: "";
}

.fi-sr-sandwich-alt:before {
  content: "";
}

.fi-sr-salt-shaker:before {
  content: "";
}

.fi-sr-salt-pepper:before {
  content: "";
}

.fi-sr-salary-alt:before {
  content: "";
}

.fi-sr-salad:before {
  content: "";
}

.fi-sr-sailboat:before {
  content: "";
}

.fi-sr-safe-box:before {
  content: "";
}

.fi-sr-sad:before {
  content: "";
}

.fi-sr-sad-tear:before {
  content: "";
}

.fi-sr-sad-cry:before {
  content: "";
}

.fi-sr-sack:before {
  content: "";
}

.fi-sr-sack-dollar:before {
  content: "";
}

.fi-sr-s:before {
  content: "";
}

.fi-sr-rv:before {
  content: "";
}

.fi-sr-rupiah-sign:before {
  content: "";
}

.fi-sr-rupee-sign:before {
  content: "";
}

.fi-sr-running:before {
  content: "";
}

.fi-sr-rules:before {
  content: "";
}

.fi-sr-rules-alt:before {
  content: "";
}

.fi-sr-ruler-vertical:before {
  content: "";
}

.fi-sr-ruler-triangle:before {
  content: "";
}

.fi-sr-ruler-horizontal:before {
  content: "";
}

.fi-sr-ruler-combined:before {
  content: "";
}

.fi-sr-rugby:before {
  content: "";
}

.fi-sr-rugby-helmet:before {
  content: "";
}

.fi-sr-ruble-sign:before {
  content: "";
}

.fi-sr-rss:before {
  content: "";
}

.fi-sr-rss-alt:before {
  content: "";
}

.fi-sr-router:before {
  content: "";
}

.fi-sr-route:before {
  content: "";
}

.fi-sr-route-interstate:before {
  content: "";
}

.fi-sr-route-highway:before {
  content: "";
}

.fi-sr-rotate-square:before {
  content: "";
}

.fi-sr-rotate-right:before {
  content: "";
}

.fi-sr-rotate-left:before {
  content: "";
}

.fi-sr-rotate-exclamation:before {
  content: "";
}

.fi-sr-room-service:before {
  content: "";
}

.fi-sr-roller-coaster:before {
  content: "";
}

.fi-sr-rocket:before {
  content: "";
}

.fi-sr-rocket-lunch:before {
  content: "";
}

.fi-sr-robot:before {
  content: "";
}

.fi-sr-road:before {
  content: "";
}

.fi-sr-risk:before {
  content: "";
}

.fi-sr-risk-alt:before {
  content: "";
}

.fi-sr-rings-wedding:before {
  content: "";
}

.fi-sr-ring:before {
  content: "";
}

.fi-sr-ring-diamond:before {
  content: "";
}

.fi-sr-right:before {
  content: "";
}

.fi-sr-ribbon:before {
  content: "";
}

.fi-sr-rhombus:before {
  content: "";
}

.fi-sr-rewind:before {
  content: "";
}

.fi-sr-review:before {
  content: "";
}

.fi-sr-revenue-alt:before {
  content: "";
}

.fi-sr-restroom-simple:before {
  content: "";
}

.fi-sr-restaurant:before {
  content: "";
}

.fi-sr-resources:before {
  content: "";
}

.fi-sr-resize:before {
  content: "";
}

.fi-sr-reservation-table:before {
  content: "";
}

.fi-sr-reservation-smartphone:before {
  content: "";
}

.fi-sr-republican:before {
  content: "";
}

.fi-sr-reply-all:before {
  content: "";
}

.fi-sr-replace:before {
  content: "";
}

.fi-sr-rent:before {
  content: "";
}

.fi-sr-rent-signal:before {
  content: "";
}

.fi-sr-remove-user:before {
  content: "";
}

.fi-sr-remove-folder:before {
  content: "";
}

.fi-sr-registration-paper:before {
  content: "";
}

.fi-sr-registered:before {
  content: "";
}

.fi-sr-region-pin:before {
  content: "";
}

.fi-sr-region-pin-alt:before {
  content: "";
}

.fi-sr-refund:before {
  content: "";
}

.fi-sr-refund-alt:before {
  content: "";
}

.fi-sr-refrigerator:before {
  content: "";
}

.fi-sr-refresh:before {
  content: "";
}

.fi-sr-reflect:before {
  content: "";
}

.fi-sr-reflect-vertical:before {
  content: "";
}

.fi-sr-reflect-horizontal:before {
  content: "";
}

.fi-sr-refer:before {
  content: "";
}

.fi-sr-refer-arrow:before {
  content: "";
}

.fi-sr-reel:before {
  content: "";
}

.fi-sr-redo:before {
  content: "";
}

.fi-sr-redo-alt:before {
  content: "";
}

.fi-sr-recycle:before {
  content: "";
}

.fi-sr-rectangles-mixed:before {
  content: "";
}

.fi-sr-rectangle-xmark:before {
  content: "";
}

.fi-sr-rectangle-vertical:before {
  content: "";
}

.fi-sr-rectangle-vertical-history:before {
  content: "";
}

.fi-sr-rectangle-pro:before {
  content: "";
}

.fi-sr-rectangle-panoramic:before {
  content: "";
}

.fi-sr-rectangle-list:before {
  content: "";
}

.fi-sr-rectangle-horizontal:before {
  content: "";
}

.fi-sr-rectangle-history-circle-plus:before {
  content: "";
}

.fi-sr-rectangle-code:before {
  content: "";
}

.fi-sr-rectangle-barcode:before {
  content: "";
}

.fi-sr-rectabgle-vertical:before {
  content: "";
}

.fi-sr-record-vinyl:before {
  content: "";
}

.fi-sr-recipe:before {
  content: "";
}

.fi-sr-recipe-book:before {
  content: "";
}

.fi-sr-receipt:before {
  content: "";
}

.fi-sr-rec:before {
  content: "";
}

.fi-sr-react:before {
  content: "";
}

.fi-sr-razor-barber:before {
  content: "";
}

.fi-sr-raygun:before {
  content: "";
}

.fi-sr-ranking-stars:before {
  content: "";
}

.fi-sr-ranking-star:before {
  content: "";
}

.fi-sr-ranking-podium:before {
  content: "";
}

.fi-sr-ranking-podium-empty:before {
  content: "";
}

.fi-sr-rank:before {
  content: "";
}

.fi-sr-ramp-loading:before {
  content: "";
}

.fi-sr-ram:before {
  content: "";
}

.fi-sr-raindrops:before {
  content: "";
}

.fi-sr-rainbow:before {
  content: "";
}

.fi-sr-radish:before {
  content: "";
}

.fi-sr-radio:before {
  content: "";
}

.fi-sr-radio-alt:before {
  content: "";
}

.fi-sr-radiation:before {
  content: "";
}

.fi-sr-radiation-alt:before {
  content: "";
}

.fi-sr-radar:before {
  content: "";
}

.fi-sr-racquet:before {
  content: "";
}

.fi-sr-rabbit:before {
  content: "";
}

.fi-sr-rabbit-fast:before {
  content: "";
}

.fi-sr-r:before {
  content: "";
}

.fi-sr-quote-right:before {
  content: "";
}

.fi-sr-quiz:before {
  content: "";
}

.fi-sr-quiz-alt:before {
  content: "";
}

.fi-sr-queue:before {
  content: "";
}

.fi-sr-queue-signal:before {
  content: "";
}

.fi-sr-queue-line:before {
  content: "";
}

.fi-sr-queue-alt:before {
  content: "";
}

.fi-sr-question:before {
  content: "";
}

.fi-sr-question-square:before {
  content: "";
}

.fi-sr-qrcode:before {
  content: "";
}

.fi-sr-qr-scan:before {
  content: "";
}

.fi-sr-q:before {
  content: "";
}

.fi-sr-pyramid:before {
  content: "";
}

.fi-sr-puzzle-pieces:before {
  content: "";
}

.fi-sr-puzzle-piece:before {
  content: "";
}

.fi-sr-puzzle-alt:before {
  content: "";
}

.fi-sr-pumpkin:before {
  content: "";
}

.fi-sr-pumpkin-alt:before {
  content: "";
}

.fi-sr-pumpkin-alt-2:before {
  content: "";
}

.fi-sr-pump:before {
  content: "";
}

.fi-sr-pump-medical:before {
  content: "";
}

.fi-sr-pulse:before {
  content: "";
}

.fi-sr-protractor:before {
  content: "";
}

.fi-sr-projector:before {
  content: "";
}

.fi-sr-productivity:before {
  content: "";
}

.fi-sr-process:before {
  content: "";
}

.fi-sr-procedures:before {
  content: "";
}

.fi-sr-priority-importance:before {
  content: "";
}

.fi-sr-priority-arrows:before {
  content: "";
}

.fi-sr-priority-arrow:before {
  content: "";
}

.fi-sr-print:before {
  content: "";
}

.fi-sr-print-slash:before {
  content: "";
}

.fi-sr-print-magnifying-glass:before {
  content: "";
}

.fi-sr-preview:before {
  content: "";
}

.fi-sr-presentation:before {
  content: "";
}

.fi-sr-prescription:before {
  content: "";
}

.fi-sr-prescription-bottle:before {
  content: "";
}

.fi-sr-prescription-bottle-pill:before {
  content: "";
}

.fi-sr-prescription-bottle-alt:before {
  content: "";
}

.fi-sr-praying-hands:before {
  content: "";
}

.fi-sr-practice:before {
  content: "";
}

.fi-sr-power:before {
  content: "";
}

.fi-sr-pound:before {
  content: "";
}

.fi-sr-potato:before {
  content: "";
}

.fi-sr-pot:before {
  content: "";
}

.fi-sr-portrait:before {
  content: "";
}

.fi-sr-portal-exit:before {
  content: "";
}

.fi-sr-portal-enter:before {
  content: "";
}

.fi-sr-popsicle:before {
  content: "";
}

.fi-sr-popcorn:before {
  content: "";
}

.fi-sr-poop:before {
  content: "";
}

.fi-sr-pool-8-ball:before {
  content: "";
}

.fi-sr-poo:before {
  content: "";
}

.fi-sr-poo-bolt:before {
  content: "";
}

.fi-sr-pompebled:before {
  content: "";
}

.fi-sr-pollution:before {
  content: "";
}

.fi-sr-poll-h:before {
  content: "";
}

.fi-sr-police-box:before {
  content: "";
}

.fi-sr-point-of-sale:before {
  content: "";
}

.fi-sr-point-of-sale-signal:before {
  content: "";
}

.fi-sr-point-of-sale-bill:before {
  content: "";
}

.fi-sr-podium:before {
  content: "";
}

.fi-sr-podium-star:before {
  content: "";
}

.fi-sr-podcast:before {
  content: "";
}

.fi-sr-plus:before {
  content: "";
}

.fi-sr-plus-small:before {
  content: "";
}

.fi-sr-plus-minus:before {
  content: "";
}

.fi-sr-plus-hexagon:before {
  content: "";
}

.fi-sr-plug:before {
  content: "";
}

.fi-sr-plug-connection:before {
  content: "";
}

.fi-sr-plug-cable:before {
  content: "";
}

.fi-sr-plug-alt:before {
  content: "";
}

.fi-sr-play:before {
  content: "";
}

.fi-sr-play-pause:before {
  content: "";
}

.fi-sr-play-circle:before {
  content: "";
}

.fi-sr-play-alt:before {
  content: "";
}

.fi-sr-plate:before {
  content: "";
}

.fi-sr-plate-wheat:before {
  content: "";
}

.fi-sr-plate-utensils:before {
  content: "";
}

.fi-sr-plant-wilt:before {
  content: "";
}

.fi-sr-planet-ringed:before {
  content: "";
}

.fi-sr-planet-moon:before {
  content: "";
}

.fi-sr-plane:before {
  content: "";
}

.fi-sr-plane-tail:before {
  content: "";
}

.fi-sr-plane-slash:before {
  content: "";
}

.fi-sr-plane-prop:before {
  content: "";
}

.fi-sr-plane-departure:before {
  content: "";
}

.fi-sr-plane-arrival:before {
  content: "";
}

.fi-sr-plane-alt:before {
  content: "";
}

.fi-sr-plan:before {
  content: "";
}

.fi-sr-plan-strategy:before {
  content: "";
}

.fi-sr-place-of-worship:before {
  content: "";
}

.fi-sr-pinata:before {
  content: "";
}

.fi-sr-pizza-slice:before {
  content: "";
}

.fi-sr-pipette:before {
  content: "";
}

.fi-sr-pipe-smoking:before {
  content: "";
}

.fi-sr-pineapple:before {
  content: "";
}

.fi-sr-pineapple-alt:before {
  content: "";
}

.fi-sr-pills:before {
  content: "";
}

.fi-sr-piggy-bank:before {
  content: "";
}

.fi-sr-pig:before {
  content: "";
}

.fi-sr-pig-face:before {
  content: "";
}

.fi-sr-pie:before {
  content: "";
}

.fi-sr-picture:before {
  content: "";
}

.fi-sr-picpeople:before {
  content: "";
}

.fi-sr-picpeople-filled:before {
  content: "";
}

.fi-sr-picnic:before {
  content: "";
}

.fi-sr-picking:before {
  content: "";
}

.fi-sr-pickaxe:before {
  content: "";
}

.fi-sr-piano:before {
  content: "";
}

.fi-sr-piano-keyboard:before {
  content: "";
}

.fi-sr-physics:before {
  content: "";
}

.fi-sr-photo-video:before {
  content: "";
}

.fi-sr-photo-film-music:before {
  content: "";
}

.fi-sr-photo-capture:before {
  content: "";
}

.fi-sr-phone-slash:before {
  content: "";
}

.fi-sr-phone-rotary:before {
  content: "";
}

.fi-sr-phone-plus:before {
  content: "";
}

.fi-sr-phone-pause:before {
  content: "";
}

.fi-sr-phone-office:before {
  content: "";
}

.fi-sr-phone-flip:before {
  content: "";
}

.fi-sr-phone-cross:before {
  content: "";
}

.fi-sr-phone-call:before {
  content: "";
}

.fi-sr-pharmacy:before {
  content: "";
}

.fi-sr-pets:before {
  content: "";
}

.fi-sr-peso-sign:before {
  content: "";
}

.fi-sr-peseta-sign:before {
  content: "";
}

.fi-sr-person-walking-with-cane:before {
  content: "";
}

.fi-sr-person-simple:before {
  content: "";
}

.fi-sr-person-sign:before {
  content: "";
}

.fi-sr-person-shelter:before {
  content: "";
}

.fi-sr-person-seat:before {
  content: "";
}

.fi-sr-person-seat-reclined:before {
  content: "";
}

.fi-sr-person-pregnant:before {
  content: "";
}

.fi-sr-person-praying:before {
  content: "";
}

.fi-sr-person-dress:before {
  content: "";
}

.fi-sr-person-dress-simple:before {
  content: "";
}

.fi-sr-person-dolly:before {
  content: "";
}

.fi-sr-person-dolly-empty:before {
  content: "";
}

.fi-sr-person-cv:before {
  content: "";
}

.fi-sr-person-circle-xmark:before {
  content: "";
}

.fi-sr-person-circle-question:before {
  content: "";
}

.fi-sr-person-circle-plus:before {
  content: "";
}

.fi-sr-person-circle-minus:before {
  content: "";
}

.fi-sr-person-circle-exclamation:before {
  content: "";
}

.fi-sr-person-circle-check:before {
  content: "";
}

.fi-sr-person-carry-box:before {
  content: "";
}

.fi-sr-percentage:before {
  content: "";
}

.fi-sr-pepper:before {
  content: "";
}

.fi-sr-pepper-hot:before {
  content: "";
}

.fi-sr-pepper-alt:before {
  content: "";
}

.fi-sr-people:before {
  content: "";
}

.fi-sr-people-roof:before {
  content: "";
}

.fi-sr-people-poll:before {
  content: "";
}

.fi-sr-people-pants:before {
  content: "";
}

.fi-sr-people-dress:before {
  content: "";
}

.fi-sr-people-carry-box:before {
  content: "";
}

.fi-sr-people-arrows-left-right:before {
  content: "";
}

.fi-sr-pennant:before {
  content: "";
}

.fi-sr-pending:before {
  content: "";
}

.fi-sr-pencil:before {
  content: "";
}

.fi-sr-pencil-slash:before {
  content: "";
}

.fi-sr-pencil-ruler:before {
  content: "";
}

.fi-sr-pencil-paintbrush:before {
  content: "";
}

.fi-sr-pen-swirl:before {
  content: "";
}

.fi-sr-pen-square:before {
  content: "";
}

.fi-sr-pen-slash:before {
  content: "";
}

.fi-sr-pen-nib:before {
  content: "";
}

.fi-sr-pen-nib-slash:before {
  content: "";
}

.fi-sr-pen-field:before {
  content: "";
}

.fi-sr-pen-fancy:before {
  content: "";
}

.fi-sr-pen-fancy-slash:before {
  content: "";
}

.fi-sr-pen-clip:before {
  content: "";
}

.fi-sr-pen-clip-slash:before {
  content: "";
}

.fi-sr-pen-circle:before {
  content: "";
}

.fi-sr-pedestal:before {
  content: "";
}

.fi-sr-pear:before {
  content: "";
}

.fi-sr-peapod:before {
  content: "";
}

.fi-sr-peanuts:before {
  content: "";
}

.fi-sr-peanut:before {
  content: "";
}

.fi-sr-peach:before {
  content: "";
}

.fi-sr-peace:before {
  content: "";
}

.fi-sr-payment-pos:before {
  content: "";
}

.fi-sr-paw:before {
  content: "";
}

.fi-sr-paw-heart:before {
  content: "";
}

.fi-sr-paw-claws:before {
  content: "";
}

.fi-sr-pause:before {
  content: "";
}

.fi-sr-pause-circle:before {
  content: "";
}

.fi-sr-pattern:before {
  content: "";
}

.fi-sr-paste:before {
  content: "";
}

.fi-sr-password:before {
  content: "";
}

.fi-sr-password-smartphone:before {
  content: "";
}

.fi-sr-password-email:before {
  content: "";
}

.fi-sr-password-computer:before {
  content: "";
}

.fi-sr-password-alt:before {
  content: "";
}

.fi-sr-passport:before {
  content: "";
}

.fi-sr-party:before {
  content: "";
}

.fi-sr-party-horn:before {
  content: "";
}

.fi-sr-party-bell:before {
  content: "";
}

.fi-sr-parking:before {
  content: "";
}

.fi-sr-parking-slash:before {
  content: "";
}

.fi-sr-parking-circle:before {
  content: "";
}

.fi-sr-parking-circle-slash:before {
  content: "";
}

.fi-sr-paragraph:before {
  content: "";
}

.fi-sr-paragraph-left:before {
  content: "";
}

.fi-sr-parachute-box:before {
  content: "";
}

.fi-sr-paperclip-vertical:before {
  content: "";
}

.fi-sr-paper-plane:before {
  content: "";
}

.fi-sr-paper-plane-top:before {
  content: "";
}

.fi-sr-panorama:before {
  content: "";
}

.fi-sr-pancakes:before {
  content: "";
}

.fi-sr-pan:before {
  content: "";
}

.fi-sr-pan-frying:before {
  content: "";
}

.fi-sr-pan-food:before {
  content: "";
}

.fi-sr-pallet:before {
  content: "";
}

.fi-sr-pallet-alt:before {
  content: "";
}

.fi-sr-palette:before {
  content: "";
}

.fi-sr-paintbrush-pencil:before {
  content: "";
}

.fi-sr-paint:before {
  content: "";
}

.fi-sr-paint-roller:before {
  content: "";
}

.fi-sr-paid:before {
  content: "";
}

.fi-sr-pager:before {
  content: "";
}

.fi-sr-page-break:before {
  content: "";
}

.fi-sr-padlock-check:before {
  content: "";
}

.fi-sr-package:before {
  content: "";
}

.fi-sr-p:before {
  content: "";
}

.fi-sr-overview:before {
  content: "";
}

.fi-sr-overline:before {
  content: "";
}

.fi-sr-oven:before {
  content: "";
}

.fi-sr-oval:before {
  content: "";
}

.fi-sr-oval-alt:before {
  content: "";
}

.fi-sr-outdent:before {
  content: "";
}

.fi-sr-otter:before {
  content: "";
}

.fi-sr-otp:before {
  content: "";
}

.fi-sr-ornament:before {
  content: "";
}

.fi-sr-organization-chart:before {
  content: "";
}

.fi-sr-operation:before {
  content: "";
}

.fi-sr-opacity:before {
  content: "";
}

.fi-sr-onion:before {
  content: "";
}

.fi-sr-onboarding:before {
  content: "";
}

.fi-sr-omega:before {
  content: "";
}

.fi-sr-om:before {
  content: "";
}

.fi-sr-olives:before {
  content: "";
}

.fi-sr-olive:before {
  content: "";
}

.fi-sr-olive-oil:before {
  content: "";
}

.fi-sr-oil-temp:before {
  content: "";
}

.fi-sr-oil-can:before {
  content: "";
}

.fi-sr-octagon:before {
  content: "";
}

.fi-sr-octagon-xmark:before {
  content: "";
}

.fi-sr-octagon-plus:before {
  content: "";
}

.fi-sr-octagon-minus:before {
  content: "";
}

.fi-sr-octagon-exclamation:before {
  content: "";
}

.fi-sr-octagon-divide:before {
  content: "";
}

.fi-sr-octagon-check:before {
  content: "";
}

.fi-sr-objects-column:before {
  content: "";
}

.fi-sr-object-union:before {
  content: "";
}

.fi-sr-object-ungroup:before {
  content: "";
}

.fi-sr-object-subtract:before {
  content: "";
}

.fi-sr-object-intersect:before {
  content: "";
}

.fi-sr-object-group:before {
  content: "";
}

.fi-sr-object-exclude:before {
  content: "";
}

.fi-sr-o:before {
  content: "";
}

.fi-sr-notes:before {
  content: "";
}

.fi-sr-notes-medical:before {
  content: "";
}

.fi-sr-notebook:before {
  content: "";
}

.fi-sr-notebook-alt:before {
  content: "";
}

.fi-sr-note:before {
  content: "";
}

.fi-sr-note-sticky:before {
  content: "";
}

.fi-sr-note-medical:before {
  content: "";
}

.fi-sr-notdef:before {
  content: "";
}

.fi-sr-not-found:before {
  content: "";
}

.fi-sr-not-found-magnifying-glass:before {
  content: "";
}

.fi-sr-not-found-alt:before {
  content: "";
}

.fi-sr-not-equal:before {
  content: "";
}

.fi-sr-nose:before {
  content: "";
}

.fi-sr-noodles:before {
  content: "";
}

.fi-sr-no-people:before {
  content: "";
}

.fi-sr-no-fee:before {
  content: "";
}

.fi-sr-nfc:before {
  content: "";
}

.fi-sr-nfc-trash:before {
  content: "";
}

.fi-sr-nfc-slash:before {
  content: "";
}

.fi-sr-nfc-pen:before {
  content: "";
}

.fi-sr-nfc-magnifying-glass:before {
  content: "";
}

.fi-sr-nfc-lock:before {
  content: "";
}

.fi-sr-newspaper:before {
  content: "";
}

.fi-sr-newspaper-open:before {
  content: "";
}

.fi-sr-neuter:before {
  content: "";
}

.fi-sr-network:before {
  content: "";
}

.fi-sr-network-cloud:before {
  content: "";
}

.fi-sr-nesting-dolls:before {
  content: "";
}

.fi-sr-navigation:before {
  content: "";
}

.fi-sr-narwhal:before {
  content: "";
}

.fi-sr-naira-sign:before {
  content: "";
}

.fi-sr-n:before {
  content: "";
}

.fi-sr-music:before {
  content: "";
}

.fi-sr-music-slash:before {
  content: "";
}

.fi-sr-music-note:before {
  content: "";
}

.fi-sr-music-file:before {
  content: "";
}

.fi-sr-music-alt:before {
  content: "";
}

.fi-sr-music-alt-slash:before {
  content: "";
}

.fi-sr-mushroom:before {
  content: "";
}

.fi-sr-mushroom-alt:before {
  content: "";
}

.fi-sr-muscle:before {
  content: "";
}

.fi-sr-multiple:before {
  content: "";
}

.fi-sr-multiple-alt:before {
  content: "";
}

.fi-sr-mug:before {
  content: "";
}

.fi-sr-mug-tea:before {
  content: "";
}

.fi-sr-mug-tea-saucer:before {
  content: "";
}

.fi-sr-mug-marshmallows:before {
  content: "";
}

.fi-sr-mug-hot:before {
  content: "";
}

.fi-sr-mug-hot-alt:before {
  content: "";
}

.fi-sr-mug-alt:before {
  content: "";
}

.fi-sr-mp3-player:before {
  content: "";
}

.fi-sr-move-to-folder:before {
  content: "";
}

.fi-sr-move-to-folder-2:before {
  content: "";
}

.fi-sr-mouse:before {
  content: "";
}

.fi-sr-mountains:before {
  content: "";
}

.fi-sr-mountain:before {
  content: "";
}

.fi-sr-mountain-city:before {
  content: "";
}

.fi-sr-mound:before {
  content: "";
}

.fi-sr-motorcycle:before {
  content: "";
}

.fi-sr-mosquito:before {
  content: "";
}

.fi-sr-mosquito-net:before {
  content: "";
}

.fi-sr-mosque:before {
  content: "";
}

.fi-sr-mosque-moon:before {
  content: "";
}

.fi-sr-mosque-alt:before {
  content: "";
}

.fi-sr-mortar-pestle:before {
  content: "";
}

.fi-sr-moped:before {
  content: "";
}

.fi-sr-moon:before {
  content: "";
}

.fi-sr-moon-stars:before {
  content: "";
}

.fi-sr-monument:before {
  content: "";
}

.fi-sr-monkey:before {
  content: "";
}

.fi-sr-money:before {
  content: "";
}

.fi-sr-money-transfer-smartphone:before {
  content: "";
}

.fi-sr-money-transfer-coin-arrow:before {
  content: "";
}

.fi-sr-money-transfer-alt:before {
  content: "";
}

.fi-sr-money-simple-from-bracket:before {
  content: "";
}

.fi-sr-money-from-bracket:before {
  content: "";
}

.fi-sr-money-coin-transfer:before {
  content: "";
}

.fi-sr-money-check:before {
  content: "";
}

.fi-sr-money-check-edit:before {
  content: "";
}

.fi-sr-money-check-edit-alt:before {
  content: "";
}

.fi-sr-money-bills:before {
  content: "";
}

.fi-sr-money-bills-simple:before {
  content: "";
}

.fi-sr-money-bill-wave:before {
  content: "";
}

.fi-sr-money-bill-wave-alt:before {
  content: "";
}

.fi-sr-money-bill-transfer:before {
  content: "";
}

.fi-sr-money-bill-simple:before {
  content: "";
}

.fi-sr-monday:before {
  content: "";
}

.fi-sr-module:before {
  content: "";
}

.fi-sr-model-cube:before {
  content: "";
}

.fi-sr-model-cube-space:before {
  content: "";
}

.fi-sr-model-cube-arrows:before {
  content: "";
}

.fi-sr-mode:before {
  content: "";
}

.fi-sr-mode-portrait:before {
  content: "";
}

.fi-sr-mode-landscape:before {
  content: "";
}

.fi-sr-mode-alt:before {
  content: "";
}

.fi-sr-mockup:before {
  content: "";
}

.fi-sr-mobile:before {
  content: "";
}

.fi-sr-mobile-notch:before {
  content: "";
}

.fi-sr-mobile-button:before {
  content: "";
}

.fi-sr-mix:before {
  content: "";
}

.fi-sr-mistletoe:before {
  content: "";
}

.fi-sr-minus:before {
  content: "";
}

.fi-sr-minus-small:before {
  content: "";
}

.fi-sr-minus-hexagon:before {
  content: "";
}

.fi-sr-minus-circle:before {
  content: "";
}

.fi-sr-mind-share:before {
  content: "";
}

.fi-sr-mill-sign:before {
  content: "";
}

.fi-sr-milk:before {
  content: "";
}

.fi-sr-milk-alt:before {
  content: "";
}

.fi-sr-microwave:before {
  content: "";
}

.fi-sr-microscope:before {
  content: "";
}

.fi-sr-microphone:before {
  content: "";
}

.fi-sr-microphone-slash:before {
  content: "";
}

.fi-sr-microphone-alt:before {
  content: "";
}

.fi-sr-microchip:before {
  content: "";
}

.fi-sr-microchip-ai:before {
  content: "";
}

.fi-sr-meter:before {
  content: "";
}

.fi-sr-meter-fire:before {
  content: "";
}

.fi-sr-meter-droplet:before {
  content: "";
}

.fi-sr-meter-bolt:before {
  content: "";
}

.fi-sr-meteor:before {
  content: "";
}

.fi-sr-messages:before {
  content: "";
}

.fi-sr-messages-question:before {
  content: "";
}

.fi-sr-messages-dollar:before {
  content: "";
}

.fi-sr-message-xmark:before {
  content: "";
}

.fi-sr-message-text:before {
  content: "";
}

.fi-sr-message-sms:before {
  content: "";
}

.fi-sr-message-slash:before {
  content: "";
}

.fi-sr-message-quote:before {
  content: "";
}

.fi-sr-message-question:before {
  content: "";
}

.fi-sr-message-image:before {
  content: "";
}

.fi-sr-message-dollar:before {
  content: "";
}

.fi-sr-message-code:before {
  content: "";
}

.fi-sr-message-bot:before {
  content: "";
}

.fi-sr-message-arrow-up:before {
  content: "";
}

.fi-sr-message-arrow-up-right:before {
  content: "";
}

.fi-sr-message-arrow-down:before {
  content: "";
}

.fi-sr-mercury:before {
  content: "";
}

.fi-sr-menu-dots:before {
  content: "";
}

.fi-sr-menu-dots-vertical:before {
  content: "";
}

.fi-sr-menu-burger:before {
  content: "";
}

.fi-sr-memory:before {
  content: "";
}

.fi-sr-memo:before {
  content: "";
}

.fi-sr-memo-pad:before {
  content: "";
}

.fi-sr-memo-circle-check:before {
  content: "";
}

.fi-sr-membership:before {
  content: "";
}

.fi-sr-membership-vip:before {
  content: "";
}

.fi-sr-melon:before {
  content: "";
}

.fi-sr-melon-alt:before {
  content: "";
}

.fi-sr-meh:before {
  content: "";
}

.fi-sr-meh-rolling-eyes:before {
  content: "";
}

.fi-sr-meh-blank:before {
  content: "";
}

.fi-sr-megaphone:before {
  content: "";
}

.fi-sr-meeting:before {
  content: "";
}

.fi-sr-meeting-alt:before {
  content: "";
}

.fi-sr-medicine:before {
  content: "";
}

.fi-sr-medical-star:before {
  content: "";
}

.fi-sr-medal:before {
  content: "";
}

.fi-sr-meat:before {
  content: "";
}

.fi-sr-mattress-pillow:before {
  content: "";
}

.fi-sr-match-fire:before {
  content: "";
}

.fi-sr-massage:before {
  content: "";
}

.fi-sr-mask-snorkel:before {
  content: "";
}

.fi-sr-mask-face:before {
  content: "";
}

.fi-sr-mask-carnival:before {
  content: "";
}

.fi-sr-martini-glass-empty:before {
  content: "";
}

.fi-sr-martini-glass-citrus:before {
  content: "";
}

.fi-sr-mars:before {
  content: "";
}

.fi-sr-mars-stroke-up:before {
  content: "";
}

.fi-sr-mars-stroke-right:before {
  content: "";
}

.fi-sr-mars-double:before {
  content: "";
}

.fi-sr-marketplace:before {
  content: "";
}

.fi-sr-marketplace-store:before {
  content: "";
}

.fi-sr-marketplace-alt:before {
  content: "";
}

.fi-sr-marker:before {
  content: "";
}

.fi-sr-marker-time:before {
  content: "";
}

.fi-sr-map:before {
  content: "";
}

.fi-sr-map-pin:before {
  content: "";
}

.fi-sr-map-marker:before {
  content: "";
}

.fi-sr-map-marker-smile:before {
  content: "";
}

.fi-sr-map-marker-slash:before {
  content: "";
}

.fi-sr-map-marker-question:before {
  content: "";
}

.fi-sr-map-marker-plus:before {
  content: "";
}

.fi-sr-map-marker-minus:before {
  content: "";
}

.fi-sr-map-marker-home:before {
  content: "";
}

.fi-sr-map-marker-edit:before {
  content: "";
}

.fi-sr-map-marker-cross:before {
  content: "";
}

.fi-sr-map-marker-check:before {
  content: "";
}

.fi-sr-manhole:before {
  content: "";
}

.fi-sr-mango:before {
  content: "";
}

.fi-sr-mandolin:before {
  content: "";
}

.fi-sr-manat-sign:before {
  content: "";
}

.fi-sr-man-scientist:before {
  content: "";
}

.fi-sr-man-head:before {
  content: "";
}

.fi-sr-makeup-brush:before {
  content: "";
}

.fi-sr-mailbox:before {
  content: "";
}

.fi-sr-magnet:before {
  content: "";
}

.fi-sr-magnet-user:before {
  content: "";
}

.fi-sr-mace:before {
  content: "";
}

.fi-sr-m:before {
  content: "";
}

.fi-sr-lungs:before {
  content: "";
}

.fi-sr-lungs-virus:before {
  content: "";
}

.fi-sr-luggage-rolling:before {
  content: "";
}

.fi-sr-luggage-cart:before {
  content: "";
}

.fi-sr-luchador:before {
  content: "";
}

.fi-sr-low-vision:before {
  content: "";
}

.fi-sr-loveseat:before {
  content: "";
}

.fi-sr-locust:before {
  content: "";
}

.fi-sr-lock:before {
  content: "";
}

.fi-sr-lock-open-alt:before {
  content: "";
}

.fi-sr-lock-hashtag:before {
  content: "";
}

.fi-sr-lock-alt:before {
  content: "";
}

.fi-sr-location-exclamation:before {
  content: "";
}

.fi-sr-location-dot-slash:before {
  content: "";
}

.fi-sr-location-crosshairs:before {
  content: "";
}

.fi-sr-location-crosshairs-slash:before {
  content: "";
}

.fi-sr-location-arrow:before {
  content: "";
}

.fi-sr-location-alt:before {
  content: "";
}

.fi-sr-lobster:before {
  content: "";
}

.fi-sr-loan:before {
  content: "";
}

.fi-sr-loading:before {
  content: "";
}

.fi-sr-live:before {
  content: "";
}

.fi-sr-live-alt:before {
  content: "";
}

.fi-sr-litecoin-sign:before {
  content: "";
}

.fi-sr-list:before {
  content: "";
}

.fi-sr-list-timeline:before {
  content: "";
}

.fi-sr-list-music:before {
  content: "";
}

.fi-sr-list-dropdown:before {
  content: "";
}

.fi-sr-list-check:before {
  content: "";
}

.fi-sr-lira-sign:before {
  content: "";
}

.fi-sr-lipstick:before {
  content: "";
}

.fi-sr-lips:before {
  content: "";
}

.fi-sr-lion:before {
  content: "";
}

.fi-sr-lion-head:before {
  content: "";
}

.fi-sr-link:before {
  content: "";
}

.fi-sr-link-slash:before {
  content: "";
}

.fi-sr-link-slash-alt:before {
  content: "";
}

.fi-sr-link-horizontal:before {
  content: "";
}

.fi-sr-link-horizontal-slash:before {
  content: "";
}

.fi-sr-link-alt:before {
  content: "";
}

.fi-sr-line-width:before {
  content: "";
}

.fi-sr-limit-speedometer:before {
  content: "";
}

.fi-sr-lights-holiday:before {
  content: "";
}

.fi-sr-lightbulb-slash:before {
  content: "";
}

.fi-sr-lightbulb-setting:before {
  content: "";
}

.fi-sr-lightbulb-question:before {
  content: "";
}

.fi-sr-lightbulb-on:before {
  content: "";
}

.fi-sr-lightbulb-head:before {
  content: "";
}

.fi-sr-lightbulb-exclamation:before {
  content: "";
}

.fi-sr-lightbulb-dollar:before {
  content: "";
}

.fi-sr-light-switch:before {
  content: "";
}

.fi-sr-light-switch-on:before {
  content: "";
}

.fi-sr-light-switch-off:before {
  content: "";
}

.fi-sr-light-emergency:before {
  content: "";
}

.fi-sr-light-emergency-on:before {
  content: "";
}

.fi-sr-light-ceiling:before {
  content: "";
}

.fi-sr-life:before {
  content: "";
}

.fi-sr-life-ring:before {
  content: "";
}

.fi-sr-license:before {
  content: "";
}

.fi-sr-level-up:before {
  content: "";
}

.fi-sr-level-up-alt:before {
  content: "";
}

.fi-sr-level-down:before {
  content: "";
}

.fi-sr-level-down-alt:before {
  content: "";
}

.fi-sr-lettuce:before {
  content: "";
}

.fi-sr-letter-case:before {
  content: "";
}

.fi-sr-lesson:before {
  content: "";
}

.fi-sr-lesson-class:before {
  content: "";
}

.fi-sr-less-than:before {
  content: "";
}

.fi-sr-less-than-equal:before {
  content: "";
}

.fi-sr-lemon:before {
  content: "";
}

.fi-sr-legal:before {
  content: "";
}

.fi-sr-left:before {
  content: "";
}

.fi-sr-leave:before {
  content: "";
}

.fi-sr-leafy-green:before {
  content: "";
}

.fi-sr-leaf:before {
  content: "";
}

.fi-sr-leaf-oak:before {
  content: "";
}

.fi-sr-leaf-maple:before {
  content: "";
}

.fi-sr-leaf-heart:before {
  content: "";
}

.fi-sr-leadership:before {
  content: "";
}

.fi-sr-leadership-alt:before {
  content: "";
}

.fi-sr-leaderboard:before {
  content: "";
}

.fi-sr-leaderboard-trophy:before {
  content: "";
}

.fi-sr-leaderboard-alt:before {
  content: "";
}

.fi-sr-leader:before {
  content: "";
}

.fi-sr-leader-alt:before {
  content: "";
}

.fi-sr-layout-fluid:before {
  content: "";
}

.fi-sr-layers:before {
  content: "";
}

.fi-sr-layer-plus:before {
  content: "";
}

.fi-sr-layer-minus:before {
  content: "";
}

.fi-sr-lawyer-woman:before {
  content: "";
}

.fi-sr-lawyer-man:before {
  content: "";
}

.fi-sr-laugh:before {
  content: "";
}

.fi-sr-laugh-wink:before {
  content: "";
}

.fi-sr-laugh-squint:before {
  content: "";
}

.fi-sr-laugh-beam:before {
  content: "";
}

.fi-sr-lasso:before {
  content: "";
}

.fi-sr-lasso-sparkles:before {
  content: "";
}

.fi-sr-lari-sign:before {
  content: "";
}

.fi-sr-laptop:before {
  content: "";
}

.fi-sr-laptop-slash:before {
  content: "";
}

.fi-sr-laptop-mobile:before {
  content: "";
}

.fi-sr-laptop-medical:before {
  content: "";
}

.fi-sr-laptop-code:before {
  content: "";
}

.fi-sr-laptop-arrow-down:before {
  content: "";
}

.fi-sr-language:before {
  content: "";
}

.fi-sr-landmark-alt:before {
  content: "";
}

.fi-sr-land-location:before {
  content: "";
}

.fi-sr-land-layers:before {
  content: "";
}

.fi-sr-land-layer-location:before {
  content: "";
}

.fi-sr-lamp:before {
  content: "";
}

.fi-sr-lamp-street:before {
  content: "";
}

.fi-sr-lamp-floor:before {
  content: "";
}

.fi-sr-lamp-desk:before {
  content: "";
}

.fi-sr-lambda:before {
  content: "";
}

.fi-sr-lacrosse-stick:before {
  content: "";
}

.fi-sr-lacrosse-stick-ball:before {
  content: "";
}

.fi-sr-label:before {
  content: "";
}

.fi-sr-l:before {
  content: "";
}

.fi-sr-kpi:before {
  content: "";
}

.fi-sr-kpi-evaluation:before {
  content: "";
}

.fi-sr-knitting:before {
  content: "";
}

.fi-sr-knife:before {
  content: "";
}

.fi-sr-knife-kitchen:before {
  content: "";
}

.fi-sr-kiwi-fruit:before {
  content: "";
}

.fi-sr-kiwi-bird:before {
  content: "";
}

.fi-sr-kite:before {
  content: "";
}

.fi-sr-kiss:before {
  content: "";
}

.fi-sr-kiss-wink-heart:before {
  content: "";
}

.fi-sr-kiss-beam:before {
  content: "";
}

.fi-sr-kip-sign:before {
  content: "";
}

.fi-sr-kidneys:before {
  content: "";
}

.fi-sr-keynote:before {
  content: "";
}

.fi-sr-keyboard:before {
  content: "";
}

.fi-sr-keyboard-left:before {
  content: "";
}

.fi-sr-keyboard-down:before {
  content: "";
}

.fi-sr-keyboard-brightness:before {
  content: "";
}

.fi-sr-keyboard-brightness-low:before {
  content: "";
}

.fi-sr-key:before {
  content: "";
}

.fi-sr-key-skeleton-left-right:before {
  content: "";
}

.fi-sr-kerning:before {
  content: "";
}

.fi-sr-kazoo:before {
  content: "";
}

.fi-sr-kaaba:before {
  content: "";
}

.fi-sr-k:before {
  content: "";
}

.fi-sr-jug:before {
  content: "";
}

.fi-sr-jug-bottle:before {
  content: "";
}

.fi-sr-jug-alt:before {
  content: "";
}

.fi-sr-jpg:before {
  content: "";
}

.fi-sr-joystick:before {
  content: "";
}

.fi-sr-journey:before {
  content: "";
}

.fi-sr-journal:before {
  content: "";
}

.fi-sr-journal-alt:before {
  content: "";
}

.fi-sr-joint:before {
  content: "";
}

.fi-sr-javascript:before {
  content: "";
}

.fi-sr-jar-wheat:before {
  content: "";
}

.fi-sr-jar-alt:before {
  content: "";
}

.fi-sr-jam:before {
  content: "";
}

.fi-sr-j:before {
  content: "";
}

.fi-sr-italic:before {
  content: "";
}

.fi-sr-italian-lira-sign:before {
  content: "";
}

.fi-sr-it:before {
  content: "";
}

.fi-sr-it-computer:before {
  content: "";
}

.fi-sr-it-alt:before {
  content: "";
}

.fi-sr-island-tropical:before {
  content: "";
}

.fi-sr-invite:before {
  content: "";
}

.fi-sr-invite-alt:before {
  content: "";
}

.fi-sr-investment:before {
  content: "";
}

.fi-sr-invest:before {
  content: "";
}

.fi-sr-inventory-alt:before {
  content: "";
}

.fi-sr-introduction:before {
  content: "";
}

.fi-sr-introduction-handshake:before {
  content: "";
}

.fi-sr-intersection:before {
  content: "";
}

.fi-sr-interrogation:before {
  content: "";
}

.fi-sr-interlining:before {
  content: "";
}

.fi-sr-interactive:before {
  content: "";
}

.fi-sr-integral:before {
  content: "";
}

.fi-sr-insight:before {
  content: "";
}

.fi-sr-insight-head:before {
  content: "";
}

.fi-sr-insight-alt:before {
  content: "";
}

.fi-sr-insert:before {
  content: "";
}

.fi-sr-insert-square:before {
  content: "";
}

.fi-sr-insert-arrows:before {
  content: "";
}

.fi-sr-insert-alt:before {
  content: "";
}

.fi-sr-input-text:before {
  content: "";
}

.fi-sr-input-pipe:before {
  content: "";
}

.fi-sr-input-numeric:before {
  content: "";
}

.fi-sr-inhaler:before {
  content: "";
}

.fi-sr-information:before {
  content: "";
}

.fi-sr-info:before {
  content: "";
}

.fi-sr-info-guide:before {
  content: "";
}

.fi-sr-infinity:before {
  content: "";
}

.fi-sr-industry-windows:before {
  content: "";
}

.fi-sr-industry-alt:before {
  content: "";
}

.fi-sr-indian-rupee-sign:before {
  content: "";
}

.fi-sr-indent:before {
  content: "";
}

.fi-sr-incognito:before {
  content: "";
}

.fi-sr-inboxes:before {
  content: "";
}

.fi-sr-inbox:before {
  content: "";
}

.fi-sr-inbox-out:before {
  content: "";
}

.fi-sr-inbox-in:before {
  content: "";
}

.fi-sr-images:before {
  content: "";
}

.fi-sr-images-user:before {
  content: "";
}

.fi-sr-image-slash:before {
  content: "";
}

.fi-sr-igloo:before {
  content: "";
}

.fi-sr-id-card-clip-alt:before {
  content: "";
}

.fi-sr-id-badge:before {
  content: "";
}

.fi-sr-icon-star:before {
  content: "";
}

.fi-sr-icicles:before {
  content: "";
}

.fi-sr-ice-cream:before {
  content: "";
}

.fi-sr-i:before {
  content: "";
}

.fi-sr-hurricane:before {
  content: "";
}

.fi-sr-hundred-points:before {
  content: "";
}

.fi-sr-humidity:before {
  content: "";
}

.fi-sr-hryvnia:before {
  content: "";
}

.fi-sr-hr:before {
  content: "";
}

.fi-sr-hr-person:before {
  content: "";
}

.fi-sr-hr-group:before {
  content: "";
}

.fi-sr-house-window:before {
  content: "";
}

.fi-sr-house-user:before {
  content: "";
}

.fi-sr-house-turret:before {
  content: "";
}

.fi-sr-house-tsunami:before {
  content: "";
}

.fi-sr-house-tree:before {
  content: "";
}

.fi-sr-house-signal:before {
  content: "";
}

.fi-sr-house-return:before {
  content: "";
}

.fi-sr-house-night:before {
  content: "";
}

.fi-sr-house-medical:before {
  content: "";
}

.fi-sr-house-leave:before {
  content: "";
}

.fi-sr-house-laptop:before {
  content: "";
}

.fi-sr-house-flood:before {
  content: "";
}

.fi-sr-house-day:before {
  content: "";
}

.fi-sr-house-crack:before {
  content: "";
}

.fi-sr-house-chimney:before {
  content: "";
}

.fi-sr-house-chimney-window:before {
  content: "";
}

.fi-sr-house-chimney-user:before {
  content: "";
}

.fi-sr-house-chimney-medical:before {
  content: "";
}

.fi-sr-house-chimney-heart:before {
  content: "";
}

.fi-sr-house-chimney-crack:before {
  content: "";
}

.fi-sr-house-chimney-blank:before {
  content: "";
}

.fi-sr-house-building:before {
  content: "";
}

.fi-sr-house-blank:before {
  content: "";
}

.fi-sr-hourglass:before {
  content: "";
}

.fi-sr-hourglass-start:before {
  content: "";
}

.fi-sr-hourglass-end:before {
  content: "";
}

.fi-sr-hotel:before {
  content: "";
}

.fi-sr-hotdog:before {
  content: "";
}

.fi-sr-hot-tub:before {
  content: "";
}

.fi-sr-hospitals:before {
  content: "";
}

.fi-sr-hospital:before {
  content: "";
}

.fi-sr-hospital-user:before {
  content: "";
}

.fi-sr-hospital-symbol:before {
  content: "";
}

.fi-sr-hose:before {
  content: "";
}

.fi-sr-hose-reel:before {
  content: "";
}

.fi-sr-horse:before {
  content: "";
}

.fi-sr-horse-saddle:before {
  content: "";
}

.fi-sr-horse-head:before {
  content: "";
}

.fi-sr-horizontal-rule:before {
  content: "";
}

.fi-sr-hood-cloak:before {
  content: "";
}

.fi-sr-honey-pot:before {
  content: "";
}

.fi-sr-home:before {
  content: "";
}

.fi-sr-home-location:before {
  content: "";
}

.fi-sr-home-location-alt:before {
  content: "";
}

.fi-sr-home-heart:before {
  content: "";
}

.fi-sr-holly-berry:before {
  content: "";
}

.fi-sr-hockey-sticks:before {
  content: "";
}

.fi-sr-hockey-stick-puck:before {
  content: "";
}

.fi-sr-hockey-puck:before {
  content: "";
}

.fi-sr-hockey-mask:before {
  content: "";
}

.fi-sr-hippo:before {
  content: "";
}

.fi-sr-hiking:before {
  content: "";
}

.fi-sr-highlighter:before {
  content: "";
}

.fi-sr-highlighter-line:before {
  content: "";
}

.fi-sr-high-definition:before {
  content: "";
}

.fi-sr-hexagon:before {
  content: "";
}

.fi-sr-hexagon-exclamation:before {
  content: "";
}

.fi-sr-hexagon-divide:before {
  content: "";
}

.fi-sr-hexagon-check:before {
  content: "";
}

.fi-sr-helmet-battle:before {
  content: "";
}

.fi-sr-helicopter-side:before {
  content: "";
}

.fi-sr-heat:before {
  content: "";
}

.fi-sr-heart:before {
  content: "";
}

.fi-sr-heart-rate:before {
  content: "";
}

.fi-sr-heart-half:before {
  content: "";
}

.fi-sr-heart-half-stroke:before {
  content: "";
}

.fi-sr-heart-crack:before {
  content: "";
}

.fi-sr-heart-arrow:before {
  content: "";
}

.fi-sr-headset:before {
  content: "";
}

.fi-sr-headphones:before {
  content: "";
}

.fi-sr-heading:before {
  content: "";
}

.fi-sr-head-vr:before {
  content: "";
}

.fi-sr-head-side:before {
  content: "";
}

.fi-sr-head-side-virus:before {
  content: "";
}

.fi-sr-head-side-thinking:before {
  content: "";
}

.fi-sr-head-side-medical:before {
  content: "";
}

.fi-sr-head-side-mask:before {
  content: "";
}

.fi-sr-head-side-heart:before {
  content: "";
}

.fi-sr-head-side-headphones:before {
  content: "";
}

.fi-sr-head-side-cough:before {
  content: "";
}

.fi-sr-head-side-cough-slash:before {
  content: "";
}

.fi-sr-head-side-brain:before {
  content: "";
}

.fi-sr-hdd:before {
  content: "";
}

.fi-sr-hat-wizard:before {
  content: "";
}

.fi-sr-hat-witch:before {
  content: "";
}

.fi-sr-hat-winter:before {
  content: "";
}

.fi-sr-hat-santa:before {
  content: "";
}

.fi-sr-hat-cowboy:before {
  content: "";
}

.fi-sr-hat-cowboy-side:before {
  content: "";
}

.fi-sr-hat-chef:before {
  content: "";
}

.fi-sr-hat-birthday:before {
  content: "";
}

.fi-sr-hastag:before {
  content: "";
}

.fi-sr-hashtag-lock:before {
  content: "";
}

.fi-sr-hard-hat:before {
  content: "";
}

.fi-sr-handshake:before {
  content: "";
}

.fi-sr-handshake-simple-slash:before {
  content: "";
}

.fi-sr-handshake-angle:before {
  content: "";
}

.fi-sr-hands-usd:before {
  content: "";
}

.fi-sr-hands-holding:before {
  content: "";
}

.fi-sr-hands-holding-diamond:before {
  content: "";
}

.fi-sr-hands-heart:before {
  content: "";
}

.fi-sr-hands-clapping:before {
  content: "";
}

.fi-sr-hands-bubbles:before {
  content: "";
}

.fi-sr-handmade:before {
  content: "";
}

.fi-sr-hand-wave:before {
  content: "";
}

.fi-sr-hand-spock:before {
  content: "";
}

.fi-sr-hand-sparkles:before {
  content: "";
}

.fi-sr-hand-scissors:before {
  content: "";
}

.fi-sr-hand-point-ribbon:before {
  content: "";
}

.fi-sr-hand-peace:before {
  content: "";
}

.fi-sr-hand-paper:before {
  content: "";
}

.fi-sr-hand-middle-finger:before {
  content: "";
}

.fi-sr-hand-love:before {
  content: "";
}

.fi-sr-hand-lizard:before {
  content: "";
}

.fi-sr-hand-horns:before {
  content: "";
}

.fi-sr-hand-holding-water:before {
  content: "";
}

.fi-sr-hand-holding-usd:before {
  content: "";
}

.fi-sr-hand-holding-skull:before {
  content: "";
}

.fi-sr-hand-holding-seeding:before {
  content: "";
}

.fi-sr-hand-holding-medical:before {
  content: "";
}

.fi-sr-hand-holding-magic:before {
  content: "";
}

.fi-sr-hand-holding-heart:before {
  content: "";
}

.fi-sr-hand-holding-droplet:before {
  content: "";
}

.fi-sr-hand-holding-box:before {
  content: "";
}

.fi-sr-hand-heart:before {
  content: "";
}

.fi-sr-hand-fist:before {
  content: "";
}

.fi-sr-hand-fingers-crossed:before {
  content: "";
}

.fi-sr-hand-dots:before {
  content: "";
}

.fi-sr-hand-bill:before {
  content: "";
}

.fi-sr-hand-back-point-right:before {
  content: "";
}

.fi-sr-hand-back-point-ribbon:before {
  content: "";
}

.fi-sr-hand-back-point-left:before {
  content: "";
}

.fi-sr-hand-back-point-down:before {
  content: "";
}

.fi-sr-hand-back-fist:before {
  content: "";
}

.fi-sr-hamsa:before {
  content: "";
}

.fi-sr-hammer:before {
  content: "";
}

.fi-sr-hammer-war:before {
  content: "";
}

.fi-sr-hammer-crash:before {
  content: "";
}

.fi-sr-hamburger:before {
  content: "";
}

.fi-sr-hamburger-soda:before {
  content: "";
}

.fi-sr-hair-clipper:before {
  content: "";
}

.fi-sr-h4:before {
  content: "";
}

.fi-sr-h3:before {
  content: "";
}

.fi-sr-h2:before {
  content: "";
}

.fi-sr-h1:before {
  content: "";
}

.fi-sr-h:before {
  content: "";
}

.fi-sr-h-square:before {
  content: "";
}

.fi-sr-gym:before {
  content: "";
}

.fi-sr-gun-squirt:before {
  content: "";
}

.fi-sr-guitars:before {
  content: "";
}

.fi-sr-guitar:before {
  content: "";
}

.fi-sr-guitar-electric:before {
  content: "";
}

.fi-sr-guide:before {
  content: "";
}

.fi-sr-guide-alt:before {
  content: "";
}

.fi-sr-guarani-sign:before {
  content: "";
}

.fi-sr-grocery-basket:before {
  content: "";
}

.fi-sr-grocery-bag:before {
  content: "";
}

.fi-sr-grip-vertical:before {
  content: "";
}

.fi-sr-grip-lines:before {
  content: "";
}

.fi-sr-grip-lines-vertical:before {
  content: "";
}

.fi-sr-grip-horizontal:before {
  content: "";
}

.fi-sr-grip-dots:before {
  content: "";
}

.fi-sr-grip-dots-vertical:before {
  content: "";
}

.fi-sr-grin:before {
  content: "";
}

.fi-sr-grin-wink:before {
  content: "";
}

.fi-sr-grin-tongue:before {
  content: "";
}

.fi-sr-grin-tongue-wink:before {
  content: "";
}

.fi-sr-grin-tongue-squint:before {
  content: "";
}

.fi-sr-grin-tears:before {
  content: "";
}

.fi-sr-grin-stars:before {
  content: "";
}

.fi-sr-grin-squint:before {
  content: "";
}

.fi-sr-grin-squint-tears:before {
  content: "";
}

.fi-sr-grin-hearts:before {
  content: "";
}

.fi-sr-grin-beam:before {
  content: "";
}

.fi-sr-grin-beam-sweat:before {
  content: "";
}

.fi-sr-grin-alt:before {
  content: "";
}

.fi-sr-grimace:before {
  content: "";
}

.fi-sr-grill:before {
  content: "";
}

.fi-sr-grill-hot-alt:before {
  content: "";
}

.fi-sr-grid:before {
  content: "";
}

.fi-sr-grid-dividers:before {
  content: "";
}

.fi-sr-grid-alt:before {
  content: "";
}

.fi-sr-greater-than:before {
  content: "";
}

.fi-sr-greater-than-equal:before {
  content: "";
}

.fi-sr-grate:before {
  content: "";
}

.fi-sr-grate-droplet:before {
  content: "";
}

.fi-sr-graphic-tablet:before {
  content: "";
}

.fi-sr-graphic-style:before {
  content: "";
}

.fi-sr-graph-curve:before {
  content: "";
}

.fi-sr-grape:before {
  content: "";
}

.fi-sr-gramophone:before {
  content: "";
}

.fi-sr-graduation-cap:before {
  content: "";
}

.fi-sr-government-user:before {
  content: "";
}

.fi-sr-government-flag:before {
  content: "";
}

.fi-sr-gopuram:before {
  content: "";
}

.fi-sr-golf:before {
  content: "";
}

.fi-sr-golf-club:before {
  content: "";
}

.fi-sr-golf-ball:before {
  content: "";
}

.fi-sr-goal-net:before {
  content: "";
}

.fi-sr-globe:before {
  content: "";
}

.fi-sr-globe-snow:before {
  content: "";
}

.fi-sr-globe-alt:before {
  content: "";
}

.fi-sr-glasses:before {
  content: "";
}

.fi-sr-glass:before {
  content: "";
}

.fi-sr-glass-whiskey:before {
  content: "";
}

.fi-sr-glass-whiskey-rocks:before {
  content: "";
}

.fi-sr-glass-water-droplet:before {
  content: "";
}

.fi-sr-glass-half:before {
  content: "";
}

.fi-sr-glass-empty:before {
  content: "";
}

.fi-sr-glass-citrus:before {
  content: "";
}

.fi-sr-glass-cheers:before {
  content: "";
}

.fi-sr-glass-champagne:before {
  content: "";
}

.fi-sr-gingerbread-man:before {
  content: "";
}

.fi-sr-gifts:before {
  content: "";
}

.fi-sr-gift:before {
  content: "";
}

.fi-sr-gift-card:before {
  content: "";
}

.fi-sr-gif:before {
  content: "";
}

.fi-sr-gif-square:before {
  content: "";
}

.fi-sr-ghost:before {
  content: "";
}

.fi-sr-gem:before {
  content: "";
}

.fi-sr-gears:before {
  content: "";
}

.fi-sr-gavel:before {
  content: "";
}

.fi-sr-gauge-circle-plus:before {
  content: "";
}

.fi-sr-gauge-circle-minus:before {
  content: "";
}

.fi-sr-gauge-circle-bolt:before {
  content: "";
}

.fi-sr-gas-pump:before {
  content: "";
}

.fi-sr-gas-pump-slash:before {
  content: "";
}

.fi-sr-gas-pump-alt:before {
  content: "";
}

.fi-sr-garlic:before {
  content: "";
}

.fi-sr-garlic-alt:before {
  content: "";
}

.fi-sr-garage:before {
  content: "";
}

.fi-sr-garage-open:before {
  content: "";
}

.fi-sr-garage-car:before {
  content: "";
}

.fi-sr-games:before {
  content: "";
}

.fi-sr-gamepad:before {
  content: "";
}

.fi-sr-game:before {
  content: "";
}

.fi-sr-game-board-alt:before {
  content: "";
}

.fi-sr-gallery:before {
  content: "";
}

.fi-sr-gallery-thumbnails:before {
  content: "";
}

.fi-sr-galaxy:before {
  content: "";
}

.fi-sr-galaxy-star:before {
  content: "";
}

.fi-sr-galaxy-planet:before {
  content: "";
}

.fi-sr-galaxy-alt:before {
  content: "";
}

.fi-sr-g:before {
  content: "";
}

.fi-sr-funnel-dollar:before {
  content: "";
}

.fi-sr-function:before {
  content: "";
}

.fi-sr-function-square:before {
  content: "";
}

.fi-sr-function-process:before {
  content: "";
}

.fi-sr-ftp:before {
  content: "";
}

.fi-sr-frown:before {
  content: "";
}

.fi-sr-frog:before {
  content: "";
}

.fi-sr-friday:before {
  content: "";
}

.fi-sr-french-fries:before {
  content: "";
}

.fi-sr-franc-sign:before {
  content: "";
}

.fi-sr-frame:before {
  content: "";
}

.fi-sr-fox:before {
  content: "";
}

.fi-sr-forward:before {
  content: "";
}

.fi-sr-forward-fast:before {
  content: "";
}

.fi-sr-fort:before {
  content: "";
}

.fi-sr-form:before {
  content: "";
}

.fi-sr-forklift:before {
  content: "";
}

.fi-sr-fork:before {
  content: "";
}

.fi-sr-football:before {
  content: "";
}

.fi-sr-fondue-pot:before {
  content: "";
}

.fi-sr-following:before {
  content: "";
}

.fi-sr-followcollection:before {
  content: "";
}

.fi-sr-follow-folder:before {
  content: "";
}

.fi-sr-folders:before {
  content: "";
}

.fi-sr-folder:before {
  content: "";
}

.fi-sr-folder-xmark:before {
  content: "";
}

.fi-sr-folder-upload:before {
  content: "";
}

.fi-sr-folder-tree:before {
  content: "";
}

.fi-sr-folder-times:before {
  content: "";
}

.fi-sr-folder-open:before {
  content: "";
}

.fi-sr-folder-minus:before {
  content: "";
}

.fi-sr-folder-download:before {
  content: "";
}

.fi-sr-fog:before {
  content: "";
}

.fi-sr-flying-disc:before {
  content: "";
}

.fi-sr-fly-insect:before {
  content: "";
}

.fi-sr-flux-capacitor:before {
  content: "";
}

.fi-sr-flute:before {
  content: "";
}

.fi-sr-flushed:before {
  content: "";
}

.fi-sr-flower:before {
  content: "";
}

.fi-sr-flower-tulip:before {
  content: "";
}

.fi-sr-flower-daffodil:before {
  content: "";
}

.fi-sr-flower-butterfly:before {
  content: "";
}

.fi-sr-florin-sign:before {
  content: "";
}

.fi-sr-floppy-disks:before {
  content: "";
}

.fi-sr-floppy-disk-pen:before {
  content: "";
}

.fi-sr-floppy-disk-circle-xmark:before {
  content: "";
}

.fi-sr-floppy-disk-circle-arrow-right:before {
  content: "";
}

.fi-sr-floor:before {
  content: "";
}

.fi-sr-floor-layer:before {
  content: "";
}

.fi-sr-floor-alt:before {
  content: "";
}

.fi-sr-flip-horizontal:before {
  content: "";
}

.fi-sr-flatbread:before {
  content: "";
}

.fi-sr-flatbread-stuffed:before {
  content: "";
}

.fi-sr-flask:before {
  content: "";
}

.fi-sr-flask-potion:before {
  content: "";
}

.fi-sr-flask-poison:before {
  content: "";
}

.fi-sr-flashlight:before {
  content: "";
}

.fi-sr-flame:before {
  content: "";
}

.fi-sr-flag:before {
  content: "";
}

.fi-sr-flag-usa:before {
  content: "";
}

.fi-sr-flag-checkered:before {
  content: "";
}

.fi-sr-flag-alt:before {
  content: "";
}

.fi-sr-fishing-rod:before {
  content: "";
}

.fi-sr-fish:before {
  content: "";
}

.fi-sr-fish-cooked:before {
  content: "";
}

.fi-sr-fish-bones:before {
  content: "";
}

.fi-sr-first:before {
  content: "";
}

.fi-sr-first-medal:before {
  content: "";
}

.fi-sr-first-laurel:before {
  content: "";
}

.fi-sr-first-award:before {
  content: "";
}

.fi-sr-fireplace:before {
  content: "";
}

.fi-sr-fire-smoke:before {
  content: "";
}

.fi-sr-fire-hydrant:before {
  content: "";
}

.fi-sr-fire-flame-simple:before {
  content: "";
}

.fi-sr-fire-flame-curved:before {
  content: "";
}

.fi-sr-fire-extinguisher:before {
  content: "";
}

.fi-sr-fire-burner:before {
  content: "";
}

.fi-sr-fingerprint:before {
  content: "";
}

.fi-sr-filters:before {
  content: "";
}

.fi-sr-filter:before {
  content: "";
}

.fi-sr-filter-slash:before {
  content: "";
}

.fi-sr-films:before {
  content: "";
}

.fi-sr-film:before {
  content: "";
}

.fi-sr-film-slash:before {
  content: "";
}

.fi-sr-film-canister:before {
  content: "";
}

.fi-sr-fill:before {
  content: "";
}

.fi-sr-files-medical:before {
  content: "";
}

.fi-sr-file:before {
  content: "";
}

.fi-sr-file-zipper:before {
  content: "";
}

.fi-sr-file-word:before {
  content: "";
}

.fi-sr-file-video:before {
  content: "";
}

.fi-sr-file-user:before {
  content: "";
}

.fi-sr-file-upload:before {
  content: "";
}

.fi-sr-file-spreadsheet:before {
  content: "";
}

.fi-sr-file-signature:before {
  content: "";
}

.fi-sr-file-psd:before {
  content: "";
}

.fi-sr-file-prescription:before {
  content: "";
}

.fi-sr-file-powerpoint:before {
  content: "";
}

.fi-sr-file-pdf:before {
  content: "";
}

.fi-sr-file-minus:before {
  content: "";
}

.fi-sr-file-medical:before {
  content: "";
}

.fi-sr-file-medical-alt:before {
  content: "";
}

.fi-sr-file-invoice:before {
  content: "";
}

.fi-sr-file-invoice-dollar:before {
  content: "";
}

.fi-sr-file-import:before {
  content: "";
}

.fi-sr-file-image:before {
  content: "";
}

.fi-sr-file-export:before {
  content: "";
}

.fi-sr-file-exclamation:before {
  content: "";
}

.fi-sr-file-excel:before {
  content: "";
}

.fi-sr-file-eps:before {
  content: "";
}

.fi-sr-file-edit:before {
  content: "";
}

.fi-sr-file-download:before {
  content: "";
}

.fi-sr-file-csv:before {
  content: "";
}

.fi-sr-file-code:before {
  content: "";
}

.fi-sr-file-circle-info:before {
  content: "";
}

.fi-sr-file-chart-pie:before {
  content: "";
}

.fi-sr-file-chart-line:before {
  content: "";
}

.fi-sr-file-binary:before {
  content: "";
}

.fi-sr-file-audio:before {
  content: "";
}

.fi-sr-file-ai:before {
  content: "";
}

.fi-sr-fighter-jet:before {
  content: "";
}

.fi-sr-field-hockey:before {
  content: "";
}

.fi-sr-fence:before {
  content: "";
}

.fi-sr-feedback:before {
  content: "";
}

.fi-sr-feedback-review:before {
  content: "";
}

.fi-sr-feedback-hand:before {
  content: "";
}

.fi-sr-feedback-alt:before {
  content: "";
}

.fi-sr-fee:before {
  content: "";
}

.fi-sr-fee-receipt:before {
  content: "";
}

.fi-sr-features:before {
  content: "";
}

.fi-sr-features-alt:before {
  content: "";
}

.fi-sr-feather:before {
  content: "";
}

.fi-sr-feather-pointed:before {
  content: "";
}

.fi-sr-fax:before {
  content: "";
}

.fi-sr-faucet:before {
  content: "";
}

.fi-sr-faucet-drip:before {
  content: "";
}

.fi-sr-farm:before {
  content: "";
}

.fi-sr-fan:before {
  content: "";
}

.fi-sr-fan-table:before {
  content: "";
}

.fi-sr-family:before {
  content: "";
}

.fi-sr-family-pants:before {
  content: "";
}

.fi-sr-family-dress:before {
  content: "";
}

.fi-sr-falafel:before {
  content: "";
}

.fi-sr-fail:before {
  content: "";
}

.fi-sr-face-zipper:before {
  content: "";
}

.fi-sr-face-zany:before {
  content: "";
}

.fi-sr-face-worried:before {
  content: "";
}

.fi-sr-face-woozy:before {
  content: "";
}

.fi-sr-face-weary:before {
  content: "";
}

.fi-sr-face-vomit:before {
  content: "";
}

.fi-sr-face-viewfinder:before {
  content: "";
}

.fi-sr-face-unamused:before {
  content: "";
}

.fi-sr-face-tongue-sweat:before {
  content: "";
}

.fi-sr-face-tongue-money:before {
  content: "";
}

.fi-sr-face-tissue:before {
  content: "";
}

.fi-sr-face-thinking:before {
  content: "";
}

.fi-sr-face-thermometer:before {
  content: "";
}

.fi-sr-face-swear:before {
  content: "";
}

.fi-sr-face-sunglasses:before {
  content: "";
}

.fi-sr-face-sunglasses-alt:before {
  content: "";
}

.fi-sr-face-smirking:before {
  content: "";
}

.fi-sr-face-smiling-hands:before {
  content: "";
}

.fi-sr-face-smile-upside-down:before {
  content: "";
}

.fi-sr-face-smile-tongue:before {
  content: "";
}

.fi-sr-face-smile-tear:before {
  content: "";
}

.fi-sr-face-smile-horns:before {
  content: "";
}

.fi-sr-face-smile-hearts:before {
  content: "";
}

.fi-sr-face-smile-halo:before {
  content: "";
}

.fi-sr-face-sleepy:before {
  content: "";
}

.fi-sr-face-sleeping:before {
  content: "";
}

.fi-sr-face-shush:before {
  content: "";
}

.fi-sr-face-scream:before {
  content: "";
}

.fi-sr-face-sad-sweat:before {
  content: "";
}

.fi-sr-face-relieved:before {
  content: "";
}

.fi-sr-face-raised-eyebrow:before {
  content: "";
}

.fi-sr-face-pleading:before {
  content: "";
}

.fi-sr-face-persevering:before {
  content: "";
}

.fi-sr-face-pensive:before {
  content: "";
}

.fi-sr-face-party:before {
  content: "";
}

.fi-sr-face-nose-steam:before {
  content: "";
}

.fi-sr-face-nauseated:before {
  content: "";
}

.fi-sr-face-monocle:before {
  content: "";
}

.fi-sr-face-mask:before {
  content: "";
}

.fi-sr-face-lying:before {
  content: "";
}

.fi-sr-face-icicles:before {
  content: "豈";
}

.fi-sr-face-hushed:before {
  content: "更";
}

.fi-sr-face-head-bandage:before {
  content: "車";
}

.fi-sr-face-hand-yawn:before {
  content: "賈";
}

.fi-sr-face-grin-tongue-wink:before {
  content: "滑";
}

.fi-sr-face-glasses:before {
  content: "串";
}

.fi-sr-face-fearful:before {
  content: "句";
}

.fi-sr-face-eyes-xmarks:before {
  content: "龜";
}

.fi-sr-face-expressionless:before {
  content: "龜";
}

.fi-sr-face-explode:before {
  content: "契";
}

.fi-sr-face-drooling:before {
  content: "金";
}

.fi-sr-face-downcast-sweat:before {
  content: "喇";
}

.fi-sr-face-disguise:before {
  content: "奈";
}

.fi-sr-face-disappointed:before {
  content: "懶";
}

.fi-sr-face-cowboy-hat:before {
  content: "癩";
}

.fi-sr-face-confused:before {
  content: "羅";
}

.fi-sr-face-confounded:before {
  content: "蘿";
}

.fi-sr-face-beam-hand-over-mouth:before {
  content: "螺";
}

.fi-sr-face-awesome:before {
  content: "裸";
}

.fi-sr-face-astonished:before {
  content: "邏";
}

.fi-sr-face-anxious-sweat:before {
  content: "樂";
}

.fi-sr-face-anguished:before {
  content: "洛";
}

.fi-sr-face-angry-horns:before {
  content: "烙";
}

.fi-sr-fabric:before {
  content: "珞";
}

.fi-sr-f:before {
  content: "落";
}

.fi-sr-eyes:before {
  content: "酪";
}

.fi-sr-eye:before {
  content: "駱";
}

.fi-sr-eye-dropper-half:before {
  content: "亂";
}

.fi-sr-eye-crossed:before {
  content: "卵";
}

.fi-sr-external-world:before {
  content: "欄";
}

.fi-sr-external-hard-drive:before {
  content: "爛";
}

.fi-sr-expense:before {
  content: "蘭";
}

.fi-sr-expense-bill:before {
  content: "鸞";
}

.fi-sr-expand:before {
  content: "嵐";
}

.fi-sr-expand-arrows:before {
  content: "濫";
}

.fi-sr-expand-arrows-alt:before {
  content: "藍";
}

.fi-sr-exit:before {
  content: "襤";
}

.fi-sr-exit-alt:before {
  content: "拉";
}

.fi-sr-exclamation:before {
  content: "臘";
}

.fi-sr-exchange:before {
  content: "蠟";
}

.fi-sr-exchange-cryptocurrency:before {
  content: "廊";
}

.fi-sr-exchange-alt:before {
  content: "朗";
}

.fi-sr-euro:before {
  content: "浪";
}

.fi-sr-ethernet:before {
  content: "狼";
}

.fi-sr-escalator:before {
  content: "郎";
}

.fi-sr-eraser:before {
  content: "來";
}

.fi-sr-equipment:before {
  content: "冷";
}

.fi-sr-equals:before {
  content: "勞";
}

.fi-sr-equality:before {
  content: "擄";
}

.fi-sr-envelopes:before {
  content: "櫓";
}

.fi-sr-envelope:before {
  content: "爐";
}

.fi-sr-envelope-plus:before {
  content: "盧";
}

.fi-sr-envelope-open:before {
  content: "老";
}

.fi-sr-envelope-open-text:before {
  content: "蘆";
}

.fi-sr-envelope-open-dollar:before {
  content: "虜";
}

.fi-sr-envelope-marker:before {
  content: "路";
}

.fi-sr-envelope-download:before {
  content: "露";
}

.fi-sr-envelope-dot:before {
  content: "魯";
}

.fi-sr-envelope-bulk:before {
  content: "鷺";
}

.fi-sr-envelope-ban:before {
  content: "碌";
}

.fi-sr-entertainment:before {
  content: "祿";
}

.fi-sr-enter:before {
  content: "綠";
}

.fi-sr-engine:before {
  content: "菉";
}

.fi-sr-engine-warning:before {
  content: "錄";
}

.fi-sr-empty-set:before {
  content: "鹿";
}

.fi-sr-employee-man:before {
  content: "論";
}

.fi-sr-employee-man-alt:before {
  content: "壟";
}

.fi-sr-employee-alt:before {
  content: "弄";
}

.fi-sr-elevator:before {
  content: "籠";
}

.fi-sr-elephant:before {
  content: "聾";
}

.fi-sr-eject:before {
  content: "牢";
}

.fi-sr-egg:before {
  content: "磊";
}

.fi-sr-egg-fried:before {
  content: "賂";
}

.fi-sr-effect:before {
  content: "雷";
}

.fi-sr-edit:before {
  content: "壘";
}

.fi-sr-edit-alt:before {
  content: "屢";
}

.fi-sr-eclipse:before {
  content: "樓";
}

.fi-sr-eclipse-alt:before {
  content: "淚";
}

.fi-sr-earth-europa:before {
  content: "漏";
}

.fi-sr-earth-asia:before {
  content: "累";
}

.fi-sr-earth-americas:before {
  content: "縷";
}

.fi-sr-earth-africa:before {
  content: "陋";
}

.fi-sr-earnings:before {
  content: "勒";
}

.fi-sr-ear:before {
  content: "肋";
}

.fi-sr-ear-muffs:before {
  content: "凜";
}

.fi-sr-ear-deaf:before {
  content: "凌";
}

.fi-sr-e:before {
  content: "稜";
}

.fi-sr-e-learning:before {
  content: "綾";
}

.fi-sr-duration:before {
  content: "菱";
}

.fi-sr-duration-alt:before {
  content: "陵";
}

.fi-sr-duplicate:before {
  content: "讀";
}

.fi-sr-dungeon:before {
  content: "拏";
}

.fi-sr-dumpster:before {
  content: "樂";
}

.fi-sr-dumpster-fire:before {
  content: "諾";
}

.fi-sr-duck:before {
  content: "丹";
}

.fi-sr-dryer:before {
  content: "寧";
}

.fi-sr-dryer-alt:before {
  content: "怒";
}

.fi-sr-drumstick:before {
  content: "率";
}

.fi-sr-drumstick-bite:before {
  content: "異";
}

.fi-sr-drum:before {
  content: "北";
}

.fi-sr-drum-steelpan:before {
  content: "磻";
}

.fi-sr-drop-down:before {
  content: "便";
}

.fi-sr-drone:before {
  content: "復";
}

.fi-sr-drone-front:before {
  content: "不";
}

.fi-sr-drone-alt:before {
  content: "泌";
}

.fi-sr-driver-woman:before {
  content: "數";
}

.fi-sr-driver-man:before {
  content: "索";
}

.fi-sr-drink-alt:before {
  content: "參";
}

.fi-sr-dreidel:before {
  content: "塞";
}

.fi-sr-drawer:before {
  content: "省";
}

.fi-sr-drawer-alt:before {
  content: "葉";
}

.fi-sr-draw-square:before {
  content: "說";
}

.fi-sr-draw-polygon:before {
  content: "殺";
}

.fi-sr-dragon:before {
  content: "辰";
}

.fi-sr-drafting-compass:before {
  content: "沈";
}

.fi-sr-download:before {
  content: "拾";
}

.fi-sr-down:before {
  content: "若";
}

.fi-sr-down-to-line:before {
  content: "掠";
}

.fi-sr-down-right:before {
  content: "略";
}

.fi-sr-down-left:before {
  content: "亮";
}

.fi-sr-down-left-and-up-right-to-center:before {
  content: "兩";
}

.fi-sr-dove:before {
  content: "凉";
}

.fi-sr-dot-pending:before {
  content: "梁";
}

.fi-sr-dot-circle:before {
  content: "糧";
}

.fi-sr-door-open:before {
  content: "良";
}

.fi-sr-door-closed:before {
  content: "諒";
}

.fi-sr-donut:before {
  content: "量";
}

.fi-sr-dong-sign:before {
  content: "勵";
}

.fi-sr-donate:before {
  content: "呂";
}

.fi-sr-domino-effect:before {
  content: "女";
}

.fi-sr-dolphin:before {
  content: "廬";
}

.fi-sr-dolly-flatbed:before {
  content: "旅";
}

.fi-sr-dolly-flatbed-empty:before {
  content: "濾";
}

.fi-sr-dolly-flatbed-alt:before {
  content: "礪";
}

.fi-sr-dollar:before {
  content: "閭";
}

.fi-sr-dog:before {
  content: "驪";
}

.fi-sr-dog-leashed:before {
  content: "麗";
}

.fi-sr-document:before {
  content: "黎";
}

.fi-sr-document-signed:before {
  content: "力";
}

.fi-sr-document-paid:before {
  content: "曆";
}

.fi-sr-doctor:before {
  content: "歷";
}

.fi-sr-do-not-enter:before {
  content: "轢";
}

.fi-sr-dna:before {
  content: "年";
}

.fi-sr-dizzy:before {
  content: "憐";
}

.fi-sr-divide:before {
  content: "戀";
}

.fi-sr-distribute-spacing-vertical:before {
  content: "撚";
}

.fi-sr-distribute-spacing-horizontal:before {
  content: "漣";
}

.fi-sr-display-slash:before {
  content: "煉";
}

.fi-sr-display-medical:before {
  content: "璉";
}

.fi-sr-display-code:before {
  content: "秊";
}

.fi-sr-display-arrow-down:before {
  content: "練";
}

.fi-sr-disk:before {
  content: "聯";
}

.fi-sr-disease:before {
  content: "輦";
}

.fi-sr-discover:before {
  content: "蓮";
}

.fi-sr-disc-drive:before {
  content: "連";
}

.fi-sr-direction-signal:before {
  content: "鍊";
}

.fi-sr-direction-signal-arrow:before {
  content: "列";
}

.fi-sr-diploma:before {
  content: "劣";
}

.fi-sr-dinner:before {
  content: "咽";
}

.fi-sr-digital-tachograph:before {
  content: "烈";
}

.fi-sr-digging:before {
  content: "裂";
}

.fi-sr-dice:before {
  content: "說";
}

.fi-sr-dice-two:before {
  content: "廉";
}

.fi-sr-dice-three:before {
  content: "念";
}

.fi-sr-dice-six:before {
  content: "捻";
}

.fi-sr-dice-one:before {
  content: "殮";
}

.fi-sr-dice-four:before {
  content: "簾";
}

.fi-sr-dice-d8:before {
  content: "獵";
}

.fi-sr-dice-d6:before {
  content: "令";
}

.fi-sr-dice-d4:before {
  content: "囹";
}

.fi-sr-dice-d20:before {
  content: "寧";
}

.fi-sr-dice-d12:before {
  content: "嶺";
}

.fi-sr-dice-d10:before {
  content: "怜";
}

.fi-sr-dice-alt:before {
  content: "玲";
}

.fi-sr-diary-clasp:before {
  content: "瑩";
}

.fi-sr-diary-bookmarks:before {
  content: "羚";
}

.fi-sr-diary-bookmark-down:before {
  content: "聆";
}

.fi-sr-diamond:before {
  content: "鈴";
}

.fi-sr-diamond-turn-right:before {
  content: "零";
}

.fi-sr-diamond-exclamation:before {
  content: "靈";
}

.fi-sr-dial:before {
  content: "領";
}

.fi-sr-dial-off:before {
  content: "例";
}

.fi-sr-dial-min:before {
  content: "禮";
}

.fi-sr-dial-med:before {
  content: "醴";
}

.fi-sr-dial-med-low:before {
  content: "隸";
}

.fi-sr-dial-max:before {
  content: "惡";
}

.fi-sr-dial-low:before {
  content: "了";
}

.fi-sr-dial-high:before {
  content: "僚";
}

.fi-sr-diagram-venn:before {
  content: "寮";
}

.fi-sr-diagram-successor:before {
  content: "尿";
}

.fi-sr-diagram-subtask:before {
  content: "料";
}

.fi-sr-diagram-sankey:before {
  content: "樂";
}

.fi-sr-diagram-project:before {
  content: "燎";
}

.fi-sr-diagram-previous:before {
  content: "療";
}

.fi-sr-diagram-predecessor:before {
  content: "蓼";
}

.fi-sr-diagram-next:before {
  content: "遼";
}

.fi-sr-diagram-nested:before {
  content: "龍";
}

.fi-sr-diagram-lean-canvas:before {
  content: "暈";
}

.fi-sr-diagram-cells:before {
  content: "阮";
}

.fi-sr-dharmachakra:before {
  content: "劉";
}

.fi-sr-dewpoint:before {
  content: "杻";
}

.fi-sr-devices:before {
  content: "柳";
}

.fi-sr-desktop-wallpaper:before {
  content: "流";
}

.fi-sr-desktop-arrow-down:before {
  content: "溜";
}

.fi-sr-description:before {
  content: "琉";
}

.fi-sr-description-alt:before {
  content: "留";
}

.fi-sr-deposit:before {
  content: "硫";
}

.fi-sr-deposit-alt:before {
  content: "紐";
}

.fi-sr-department:before {
  content: "類";
}

.fi-sr-department-structure:before {
  content: "六";
}

.fi-sr-democrat:before {
  content: "戮";
}

.fi-sr-delete:before {
  content: "陸";
}

.fi-sr-delete-user:before {
  content: "倫";
}

.fi-sr-delete-right:before {
  content: "崙";
}

.fi-sr-delete-document:before {
  content: "淪";
}

.fi-sr-deer:before {
  content: "輪";
}

.fi-sr-deer-rudolph:before {
  content: "律";
}

.fi-sr-database:before {
  content: "慄";
}

.fi-sr-data-transfer:before {
  content: "栗";
}

.fi-sr-dashboard:before {
  content: "率";
}

.fi-sr-dart:before {
  content: "隆";
}

.fi-sr-damage:before {
  content: "利";
}

.fi-sr-daily-calendar:before {
  content: "吏";
}

.fi-sr-dagger:before {
  content: "履";
}

.fi-sr-d:before {
  content: "易";
}

.fi-sr-customize:before {
  content: "李";
}

.fi-sr-customize-edit:before {
  content: "梨";
}

.fi-sr-customize-computer:before {
  content: "泥";
}

.fi-sr-customization:before {
  content: "理";
}

.fi-sr-customization-cogwheel:before {
  content: "痢";
}

.fi-sr-customer-service:before {
  content: "罹";
}

.fi-sr-customer-care:before {
  content: "裏";
}

.fi-sr-custard:before {
  content: "裡";
}

.fi-sr-curve:before {
  content: "里";
}

.fi-sr-curve-arrow:before {
  content: "離";
}

.fi-sr-curve-alt:before {
  content: "匿";
}

.fi-sr-cursor:before {
  content: "溺";
}

.fi-sr-cursor-text:before {
  content: "吝";
}

.fi-sr-cursor-text-alt:before {
  content: "燐";
}

.fi-sr-cursor-plus:before {
  content: "璘";
}

.fi-sr-cursor-finger:before {
  content: "藺";
}

.fi-sr-curling:before {
  content: "隣";
}

.fi-sr-cupcake:before {
  content: "鱗";
}

.fi-sr-cupcake-alt:before {
  content: "麟";
}

.fi-sr-cup-togo:before {
  content: "林";
}

.fi-sr-cup-straw:before {
  content: "淋";
}

.fi-sr-cup-straw-swoosh:before {
  content: "臨";
}

.fi-sr-cucumber:before {
  content: "立";
}

.fi-sr-cubes:before {
  content: "笠";
}

.fi-sr-cubes-stacked:before {
  content: "粒";
}

.fi-sr-cube:before {
  content: "狀";
}

.fi-sr-crystal-ball:before {
  content: "炙";
}

.fi-sr-cryptocurrency:before {
  content: "識";
}

.fi-sr-cruzeiro-sign:before {
  content: "什";
}

.fi-sr-crutches:before {
  content: "茶";
}

.fi-sr-crutch:before {
  content: "刺";
}

.fi-sr-crown:before {
  content: "切";
}

.fi-sr-crow:before {
  content: "度";
}

.fi-sr-cross:before {
  content: "拓";
}

.fi-sr-cross-small:before {
  content: "糖";
}

.fi-sr-cross-religion:before {
  content: "宅";
}

.fi-sr-cross-circle:before {
  content: "洞";
}

.fi-sr-croissant:before {
  content: "暴";
}

.fi-sr-crm-computer:before {
  content: "輻";
}

.fi-sr-crm-alt:before {
  content: "行";
}

.fi-sr-cricket:before {
  content: "降";
}

.fi-sr-credit-card:before {
  content: "見";
}

.fi-sr-cream:before {
  content: "廓";
}

.fi-sr-crate-empty:before {
  content: "兀";
}

.fi-sr-crab:before {
  content: "嗀";
}

.fi-sr-cowbell:before {
  content: "﨎";
}

.fi-sr-cowbell-more:before {
  content: "﨏";
}

.fi-sr-cowbell-circle-plus:before {
  content: "塚";
}

.fi-sr-cow:before {
  content: "﨑";
}

.fi-sr-cow-alt:before {
  content: "晴";
}

.fi-sr-couch:before {
  content: "﨓";
}

.fi-sr-corn:before {
  content: "﨔";
}

.fi-sr-copyright:before {
  content: "凞";
}

.fi-sr-copy:before {
  content: "猪";
}

.fi-sr-copy-image:before {
  content: "益";
}

.fi-sr-copy-alt:before {
  content: "礼";
}

.fi-sr-cookie:before {
  content: "神";
}

.fi-sr-cookie-alt:before {
  content: "祥";
}

.fi-sr-conveyor-belt:before {
  content: "福";
}

.fi-sr-conveyor-belt-empty:before {
  content: "靖";
}

.fi-sr-conveyor-belt-alt:before {
  content: "精";
}

.fi-sr-convert-shapes:before {
  content: "羽";
}

.fi-sr-convert-document:before {
  content: "﨟";
}

.fi-sr-container-storage:before {
  content: "蘒";
}

.fi-sr-constellation:before {
  content: "﨡";
}

.fi-sr-confetti:before {
  content: "諸";
}

.fi-sr-condition:before {
  content: "﨣";
}

.fi-sr-condition-alt:before {
  content: "﨤";
}

.fi-sr-concierge-bell:before {
  content: "逸";
}

.fi-sr-computer:before {
  content: "都";
}

.fi-sr-computer-speaker:before {
  content: "﨧";
}

.fi-sr-computer-mouse:before {
  content: "﨨";
}

.fi-sr-computer-classic:before {
  content: "﨩";
}

.fi-sr-compress:before {
  content: "飯";
}

.fi-sr-compress-alt:before {
  content: "飼";
}

.fi-sr-compliance:before {
  content: "館";
}

.fi-sr-compliance-document:before {
  content: "鶴";
}

.fi-sr-compliance-clipboard:before {
  content: "郞";
}

.fi-sr-completed:before {
  content: "隷";
}

.fi-sr-compass-slash:before {
  content: "侮";
}

.fi-sr-comments:before {
  content: "僧";
}

.fi-sr-comments-question:before {
  content: "免";
}

.fi-sr-comments-question-check:before {
  content: "勉";
}

.fi-sr-comments-dollar:before {
  content: "勤";
}

.fi-sr-comment:before {
  content: "卑";
}

.fi-sr-comment-xmark:before {
  content: "喝";
}

.fi-sr-comment-user:before {
  content: "嘆";
}

.fi-sr-comment-text:before {
  content: "器";
}

.fi-sr-comment-sms:before {
  content: "塀";
}

.fi-sr-comment-smile:before {
  content: "墨";
}

.fi-sr-comment-slash:before {
  content: "層";
}

.fi-sr-comment-quote:before {
  content: "屮";
}

.fi-sr-comment-question:before {
  content: "悔";
}

.fi-sr-comment-pen:before {
  content: "慨";
}

.fi-sr-comment-minus:before {
  content: "憎";
}

.fi-sr-comment-medical:before {
  content: "懲";
}

.fi-sr-comment-info:before {
  content: "敏";
}

.fi-sr-comment-image:before {
  content: "既";
}

.fi-sr-comment-heart:before {
  content: "暑";
}

.fi-sr-comment-exclamation:before {
  content: "梅";
}

.fi-sr-comment-dots:before {
  content: "海";
}

.fi-sr-comment-dollar:before {
  content: "渚";
}

.fi-sr-comment-code:before {
  content: "漢";
}

.fi-sr-comment-check:before {
  content: "煮";
}

.fi-sr-comment-arrow-up:before {
  content: "爫";
}

.fi-sr-comment-arrow-up-right:before {
  content: "琢";
}

.fi-sr-comment-arrow-down:before {
  content: "碑";
}

.fi-sr-comment-alt:before {
  content: "社";
}

.fi-sr-comment-alt-music:before {
  content: "祉";
}

.fi-sr-comment-alt-minus:before {
  content: "祈";
}

.fi-sr-comment-alt-middle:before {
  content: "祐";
}

.fi-sr-comment-alt-middle-top:before {
  content: "祖";
}

.fi-sr-comment-alt-medical:before {
  content: "祝";
}

.fi-sr-comment-alt-edit:before {
  content: "禍";
}

.fi-sr-comment-alt-dots:before {
  content: "禎";
}

.fi-sr-comment-alt-check:before {
  content: "穀";
}

.fi-sr-command:before {
  content: "突";
}

.fi-sr-comet:before {
  content: "節";
}

.fi-sr-columns-3:before {
  content: "練";
}

.fi-sr-colon-sign:before {
  content: "縉";
}

.fi-sr-coins:before {
  content: "繁";
}

.fi-sr-coin:before {
  content: "署";
}

.fi-sr-coin-up-arrow:before {
  content: "者";
}

.fi-sr-coffin:before {
  content: "臭";
}

.fi-sr-coffin-cross:before {
  content: "艹";
}

.fi-sr-coffee:before {
  content: "艹";
}

.fi-sr-coffee-pot:before {
  content: "著";
}

.fi-sr-coffee-beans:before {
  content: "褐";
}

.fi-sr-coffee-bean:before {
  content: "視";
}

.fi-sr-code-simple:before {
  content: "謁";
}

.fi-sr-code-pull-request:before {
  content: "謹";
}

.fi-sr-code-pull-request-draft:before {
  content: "賓";
}

.fi-sr-code-pull-request-closed:before {
  content: "贈";
}

.fi-sr-code-merge:before {
  content: "辶";
}

.fi-sr-code-fork:before {
  content: "逸";
}

.fi-sr-code-compare:before {
  content: "難";
}

.fi-sr-code-commit:before {
  content: "響";
}

.fi-sr-code-branch:before {
  content: "頻";
}

.fi-sr-coconut:before {
  content: "恵";
}

.fi-sr-cocktail:before {
  content: "𤋮";
}

.fi-sr-cocktail-alt:before {
  content: "舘";
}

.fi-sr-club:before {
  content: "﩮";
}

.fi-sr-clover-alt:before {
  content: "﩯";
}

.fi-sr-clouds:before {
  content: "並";
}

.fi-sr-clouds-sun:before {
  content: "况";
}

.fi-sr-clouds-moon:before {
  content: "全";
}

.fi-sr-cloud:before {
  content: "侀";
}

.fi-sr-cloud-upload:before {
  content: "充";
}

.fi-sr-cloud-upload-alt:before {
  content: "冀";
}

.fi-sr-cloud-sun:before {
  content: "勇";
}

.fi-sr-cloud-sun-rain:before {
  content: "勺";
}

.fi-sr-cloud-snow:before {
  content: "喝";
}

.fi-sr-cloud-sleet:before {
  content: "啕";
}

.fi-sr-cloud-showers:before {
  content: "喙";
}

.fi-sr-cloud-showers-heavy:before {
  content: "嗢";
}

.fi-sr-cloud-share:before {
  content: "塚";
}

.fi-sr-cloud-rainbow:before {
  content: "墳";
}

.fi-sr-cloud-rain:before {
  content: "奄";
}

.fi-sr-cloud-question:before {
  content: "奔";
}

.fi-sr-cloud-moon:before {
  content: "婢";
}

.fi-sr-cloud-moon-rain:before {
  content: "嬨";
}

.fi-sr-cloud-meatball:before {
  content: "廒";
}

.fi-sr-cloud-hail:before {
  content: "廙";
}

.fi-sr-cloud-hail-mixed:before {
  content: "彩";
}

.fi-sr-cloud-exclamation:before {
  content: "徭";
}

.fi-sr-cloud-drizzle:before {
  content: "惘";
}

.fi-sr-cloud-download:before {
  content: "慎";
}

.fi-sr-cloud-download-alt:before {
  content: "愈";
}

.fi-sr-cloud-disabled:before {
  content: "憎";
}

.fi-sr-cloud-code:before {
  content: "慠";
}

.fi-sr-cloud-check:before {
  content: "懲";
}

.fi-sr-clothes-hanger:before {
  content: "戴";
}

.fi-sr-closed-captioning-slash:before {
  content: "揄";
}

.fi-sr-clone:before {
  content: "搜";
}

.fi-sr-clock:before {
  content: "摒";
}

.fi-sr-clock-up-arrow:before {
  content: "敖";
}

.fi-sr-clock-two:before {
  content: "晴";
}

.fi-sr-clock-two-thirty:before {
  content: "朗";
}

.fi-sr-clock-twelve:before {
  content: "望";
}

.fi-sr-clock-twelve-thirty:before {
  content: "杖";
}

.fi-sr-clock-three:before {
  content: "歹";
}

.fi-sr-clock-three-thirty:before {
  content: "殺";
}

.fi-sr-clock-ten:before {
  content: "流";
}

.fi-sr-clock-ten-thirty:before {
  content: "滛";
}

.fi-sr-clock-six:before {
  content: "滋";
}

.fi-sr-clock-six-thirty:before {
  content: "漢";
}

.fi-sr-clock-seven:before {
  content: "瀞";
}

.fi-sr-clock-seven-thirty:before {
  content: "煮";
}

.fi-sr-clock-one:before {
  content: "瞧";
}

.fi-sr-clock-one-thirty:before {
  content: "爵";
}

.fi-sr-clock-nine:before {
  content: "犯";
}

.fi-sr-clock-nine-thirty:before {
  content: "猪";
}

.fi-sr-clock-four-thirty:before {
  content: "瑱";
}

.fi-sr-clock-five:before {
  content: "甆";
}

.fi-sr-clock-five-thirty:before {
  content: "画";
}

.fi-sr-clock-eleven:before {
  content: "瘝";
}

.fi-sr-clock-eleven-thirty:before {
  content: "瘟";
}

.fi-sr-clock-eight-thirty:before {
  content: "益";
}

.fi-sr-clock-desk:before {
  content: "盛";
}

.fi-sr-clipboard:before {
  content: "直";
}

.fi-sr-clipboard-user:before {
  content: "睊";
}

.fi-sr-clipboard-prescription:before {
  content: "着";
}

.fi-sr-clipboard-list:before {
  content: "磌";
}

.fi-sr-clipboard-list-check:before {
  content: "窱";
}

.fi-sr-clipboard-check:before {
  content: "節";
}

.fi-sr-clip:before {
  content: "类";
}

.fi-sr-clear-alt:before {
  content: "絛";
}

.fi-sr-claw-marks:before {
  content: "練";
}

.fi-sr-clarinet:before {
  content: "缾";
}

.fi-sr-clapperboard:before {
  content: "者";
}

.fi-sr-clapperboard-play:before {
  content: "荒";
}

.fi-sr-city:before {
  content: "華";
}

.fi-sr-citrus:before {
  content: "蝹";
}

.fi-sr-citrus-slice:before {
  content: "襁";
}

.fi-sr-circle:before {
  content: "覆";
}

.fi-sr-circle-z:before {
  content: "視";
}

.fi-sr-circle-y:before {
  content: "調";
}

.fi-sr-circle-xmark:before {
  content: "諸";
}

.fi-sr-circle-x:before {
  content: "請";
}

.fi-sr-circle-waveform-lines:before {
  content: "謁";
}

.fi-sr-circle-w:before {
  content: "諾";
}

.fi-sr-circle-video:before {
  content: "諭";
}

.fi-sr-circle-v:before {
  content: "謹";
}

.fi-sr-circle-user:before {
  content: "變";
}

.fi-sr-circle-u:before {
  content: "贈";
}

.fi-sr-circle-trash:before {
  content: "輸";
}

.fi-sr-circle-three-quarters:before {
  content: "遲";
}

.fi-sr-circle-t:before {
  content: "醙";
}

.fi-sr-circle-star:before {
  content: "鉶";
}

.fi-sr-circle-small:before {
  content: "陼";
}

.fi-sr-circle-s:before {
  content: "難";
}

.fi-sr-circle-r:before {
  content: "靖";
}

.fi-sr-circle-quarters-alt:before {
  content: "韛";
}

.fi-sr-circle-quarter:before {
  content: "響";
}

.fi-sr-circle-q:before {
  content: "頋";
}

.fi-sr-circle-phone:before {
  content: "頻";
}

.fi-sr-circle-phone-hangup:before {
  content: "鬒";
}

.fi-sr-circle-phone-flip:before {
  content: "龜";
}

.fi-sr-circle-p:before {
  content: "𢡊";
}

.fi-sr-circle-o:before {
  content: "𢡄";
}

.fi-sr-circle-n:before {
  content: "𣏕";
}

.fi-sr-circle-microphone:before {
  content: "㮝";
}

.fi-sr-circle-microphone-lines:before {
  content: "䀘";
}

.fi-sr-circle-m:before {
  content: "䀹";
}

.fi-sr-circle-l:before {
  content: "𥉉";
}

.fi-sr-circle-k:before {
  content: "𥳐";
}

.fi-sr-circle-j:before {
  content: "𧻓";
}

.fi-sr-circle-i:before {
  content: "齃";
}

.fi-sr-circle-heart:before {
  content: "龎";
}

.fi-sr-circle-half:before {
  content: "﫚";
}

.fi-sr-circle-half-stroke:before {
  content: "﫛";
}

.fi-sr-circle-h:before {
  content: "﫜";
}

.fi-sr-circle-g:before {
  content: "﫝";
}

.fi-sr-circle-f:before {
  content: "﫞";
}

.fi-sr-circle-exclamation-check:before {
  content: "﫟";
}

.fi-sr-circle-envelope:before {
  content: "﫠";
}

.fi-sr-circle-ellipsis:before {
  content: "﫡";
}

.fi-sr-circle-ellipsis-vertical:before {
  content: "﫢";
}

.fi-sr-circle-e:before {
  content: "﫣";
}

.fi-sr-circle-divide:before {
  content: "﫤";
}

.fi-sr-circle-dashed:before {
  content: "﫥";
}

.fi-sr-circle-d:before {
  content: "﫦";
}

.fi-sr-circle-camera:before {
  content: "﫧";
}

.fi-sr-circle-calendar:before {
  content: "﫨";
}

.fi-sr-circle-c:before {
  content: "﫩";
}

.fi-sr-circle-bookmark:before {
  content: "﫪";
}

.fi-sr-circle-book-open:before {
  content: "﫫";
}

.fi-sr-circle-bolt:before {
  content: "﫬";
}

.fi-sr-circle-b:before {
  content: "﫭";
}

.fi-sr-circle-a:before {
  content: "﫮";
}

.fi-sr-circle-9:before {
  content: "﫯";
}

.fi-sr-circle-8:before {
  content: "﫰";
}

.fi-sr-circle-7:before {
  content: "﫱";
}

.fi-sr-circle-6:before {
  content: "﫲";
}

.fi-sr-circle-5:before {
  content: "﫳";
}

.fi-sr-circle-4:before {
  content: "﫴";
}

.fi-sr-circle-3:before {
  content: "﫵";
}

.fi-sr-circle-2:before {
  content: "﫶";
}

.fi-sr-circle-1:before {
  content: "﫷";
}

.fi-sr-circle-0:before {
  content: "﫸";
}

.fi-sr-church:before {
  content: "﫹";
}

.fi-sr-choose:before {
  content: "﫺";
}

.fi-sr-choose-alt:before {
  content: "﫻";
}

.fi-sr-chocolate:before {
  content: "﫼";
}

.fi-sr-chimney:before {
  content: "﫽";
}

.fi-sr-child:before {
  content: "﫾";
}

.fi-sr-child-head:before {
  content: "﫿";
}

.fi-sr-chevron-double-up:before {
  content: "ﬀ";
}

.fi-sr-chevron-double-down:before {
  content: "ﬁ";
}

.fi-sr-chess:before {
  content: "ﬂ";
}

.fi-sr-chess-rook:before {
  content: "ﬃ";
}

.fi-sr-chess-rook-alt:before {
  content: "ﬄ";
}

.fi-sr-chess-queen:before {
  content: "ﬅ";
}

.fi-sr-chess-queen-alt:before {
  content: "ﬆ";
}

.fi-sr-chess-piece:before {
  content: "﬇";
}

.fi-sr-chess-pawn:before {
  content: "﬈";
}

.fi-sr-chess-pawn-alt:before {
  content: "﬉";
}

.fi-sr-chess-knight:before {
  content: "﬊";
}

.fi-sr-chess-knight-alt:before {
  content: "﬋";
}

.fi-sr-chess-king:before {
  content: "﬌";
}

.fi-sr-chess-king-alt:before {
  content: "﬍";
}

.fi-sr-chess-clock:before {
  content: "﬎";
}

.fi-sr-chess-clock-alt:before {
  content: "﬏";
}

.fi-sr-chess-board:before {
  content: "﬐";
}

.fi-sr-chess-bishop:before {
  content: "﬑";
}

.fi-sr-cherry:before {
  content: "﬒";
}

.fi-sr-cheeseburger:before {
  content: "ﬓ";
}

.fi-sr-cheese:before {
  content: "ﬔ";
}

.fi-sr-cheese-alt:before {
  content: "ﬕ";
}

.fi-sr-checkbox:before {
  content: "ﬖ";
}

.fi-sr-check:before {
  content: "ﬗ";
}

.fi-sr-check-out-calendar:before {
  content: "﬘";
}

.fi-sr-check-in-calendar:before {
  content: "﬙";
}

.fi-sr-check-double:before {
  content: "﬚";
}

.fi-sr-check-circle:before {
  content: "﬛";
}

.fi-sr-chatbot:before {
  content: "﬜";
}

.fi-sr-chatbot-speech-bubble:before {
  content: "יִ";
}

.fi-sr-chat-arrow-grow:before {
  content: "ﬞ";
}

.fi-sr-chat-arrow-down:before {
  content: "ײַ";
}

.fi-sr-chart-waterfall:before {
  content: "ﬠ";
}

.fi-sr-chart-user:before {
  content: "ﬡ";
}

.fi-sr-chart-tree:before {
  content: "ﬢ";
}

.fi-sr-chart-tree-map:before {
  content: "ﬣ";
}

.fi-sr-chart-simple:before {
  content: "ﬤ";
}

.fi-sr-chart-simple-horizontal:before {
  content: "ﬥ";
}

.fi-sr-chart-set-theory:before {
  content: "ﬦ";
}

.fi-sr-chart-scatter:before {
  content: "ﬧ";
}

.fi-sr-chart-scatter-bubble:before {
  content: "ﬨ";
}

.fi-sr-chart-scatter-3d:before {
  content: "﬩";
}

.fi-sr-chart-radar:before {
  content: "שׁ";
}

.fi-sr-chart-pyramid:before {
  content: "שׂ";
}

.fi-sr-chart-pie:before {
  content: "שּׁ";
}

.fi-sr-chart-pie-alt:before {
  content: "שּׂ";
}

.fi-sr-chart-network:before {
  content: "אַ";
}

.fi-sr-chart-mixed:before {
  content: "אָ";
}

.fi-sr-chart-line-up:before {
  content: "אּ";
}

.fi-sr-chart-histogram:before {
  content: "בּ";
}

.fi-sr-chart-gantt:before {
  content: "גּ";
}

.fi-sr-chart-connected:before {
  content: "דּ";
}

.fi-sr-chart-candlestick:before {
  content: "הּ";
}

.fi-sr-chart-bullet:before {
  content: "וּ";
}

.fi-sr-chart-area:before {
  content: "זּ";
}

.fi-sr-charging-station:before {
  content: "﬷";
}

.fi-sr-channel:before {
  content: "טּ";
}

.fi-sr-challenge:before {
  content: "יּ";
}

.fi-sr-challenge-alt:before {
  content: "ךּ";
}

.fi-sr-chalkboard:before {
  content: "כּ";
}

.fi-sr-chalkboard-user:before {
  content: "לּ";
}

.fi-sr-chair:before {
  content: "﬽";
}

.fi-sr-chair-office:before {
  content: "מּ";
}

.fi-sr-cent-sign:before {
  content: "﬿";
}

.fi-sr-cello:before {
  content: "נּ";
}

.fi-sr-cedi-sign:before {
  content: "סּ";
}

.fi-sr-cauldron:before {
  content: "﭂";
}

.fi-sr-category:before {
  content: "ףּ";
}

.fi-sr-category-alt:before {
  content: "פּ";
}

.fi-sr-catalog:before {
  content: "﭅";
}

.fi-sr-catalog-magazine:before {
  content: "צּ";
}

.fi-sr-catalog-alt:before {
  content: "קּ";
}

.fi-sr-cat:before {
  content: "רּ";
}

.fi-sr-cat-space:before {
  content: "שּ";
}

.fi-sr-cat-head:before {
  content: "תּ";
}

.fi-sr-castle:before {
  content: "וֹ";
}

.fi-sr-cassette-vhs:before {
  content: "בֿ";
}

.fi-sr-cassette-tape:before {
  content: "כֿ";
}

.fi-sr-cash-register:before {
  content: "פֿ";
}

.fi-sr-cart-shopping-fast:before {
  content: "ﭏ";
}

.fi-sr-cart-minus:before {
  content: "ﭐ";
}

.fi-sr-cart-arrow-down:before {
  content: "ﭑ";
}

.fi-sr-cars:before {
  content: "ﭒ";
}

.fi-sr-cars-crash:before {
  content: "ﭓ";
}

.fi-sr-carrot:before {
  content: "ﭔ";
}

.fi-sr-caret-up:before {
  content: "ﭕ";
}

.fi-sr-caret-square-right:before {
  content: "ﭖ";
}

.fi-sr-caret-square-left_1:before {
  content: "ﭗ";
}

.fi-sr-caret-square-left:before {
  content: "ﭘ";
}

.fi-sr-caret-square-down:before {
  content: "ﭙ";
}

.fi-sr-caret-right:before {
  content: "ﭚ";
}

.fi-sr-caret-quare-up:before {
  content: "ﭛ";
}

.fi-sr-caret-left:before {
  content: "ﭜ";
}

.fi-sr-caret-down:before {
  content: "ﭝ";
}

.fi-sr-caret-circle-up:before {
  content: "ﭞ";
}

.fi-sr-caret-circle-right:before {
  content: "ﭟ";
}

.fi-sr-caret-circle-down:before {
  content: "ﭠ";
}

.fi-sr-card-spade:before {
  content: "ﭡ";
}

.fi-sr-card-heart:before {
  content: "ﭢ";
}

.fi-sr-card-diamond:before {
  content: "ﭣ";
}

.fi-sr-card-club:before {
  content: "ﭤ";
}

.fi-sr-caravan:before {
  content: "ﭥ";
}

.fi-sr-caravan-alt:before {
  content: "ﭦ";
}

.fi-sr-car:before {
  content: "ﭧ";
}

.fi-sr-car-wash:before {
  content: "ﭨ";
}

.fi-sr-car-tilt:before {
  content: "ﭩ";
}

.fi-sr-car-side:before {
  content: "ﭪ";
}

.fi-sr-car-side-bolt:before {
  content: "ﭫ";
}

.fi-sr-car-rear:before {
  content: "ﭬ";
}

.fi-sr-car-mechanic:before {
  content: "ﭭ";
}

.fi-sr-car-journey:before {
  content: "ﭮ";
}

.fi-sr-car-garage:before {
  content: "ﭯ";
}

.fi-sr-car-crash:before {
  content: "ﭰ";
}

.fi-sr-car-circle-bolt:before {
  content: "ﭱ";
}

.fi-sr-car-bus:before {
  content: "ﭲ";
}

.fi-sr-car-bump:before {
  content: "ﭳ";
}

.fi-sr-car-building:before {
  content: "ﭴ";
}

.fi-sr-car-bolt:before {
  content: "ﭵ";
}

.fi-sr-car-battery:before {
  content: "ﭶ";
}

.fi-sr-car-alt:before {
  content: "ﭷ";
}

.fi-sr-capsules:before {
  content: "ﭸ";
}

.fi-sr-canned-food:before {
  content: "ﭹ";
}

.fi-sr-candy:before {
  content: "ﭺ";
}

.fi-sr-candy-sweet:before {
  content: "ﭻ";
}

.fi-sr-candy-corn:before {
  content: "ﭼ";
}

.fi-sr-candy-cane:before {
  content: "ﭽ";
}

.fi-sr-candy-bar:before {
  content: "ﭾ";
}

.fi-sr-candy-alt:before {
  content: "ﭿ";
}

.fi-sr-candle-holder:before {
  content: "ﮀ";
}

.fi-sr-can-food:before {
  content: "ﮁ";
}

.fi-sr-camping:before {
  content: "ﮂ";
}

.fi-sr-campfire:before {
  content: "ﮃ";
}

.fi-sr-camera:before {
  content: "ﮄ";
}

.fi-sr-camera-viewfinder:before {
  content: "ﮅ";
}

.fi-sr-camera-slash:before {
  content: "ﮆ";
}

.fi-sr-camera-security:before {
  content: "ﮇ";
}

.fi-sr-camera-rotate:before {
  content: "ﮈ";
}

.fi-sr-camera-retro:before {
  content: "ﮉ";
}

.fi-sr-camera-movie:before {
  content: "ﮊ";
}

.fi-sr-camera-cctv:before {
  content: "ﮋ";
}

.fi-sr-camcorder:before {
  content: "ﮌ";
}

.fi-sr-call-outgoing:before {
  content: "ﮍ";
}

.fi-sr-call-missed:before {
  content: "ﮎ";
}

.fi-sr-call-incoming:before {
  content: "ﮏ";
}

.fi-sr-call-history:before {
  content: "ﮐ";
}

.fi-sr-call-duration:before {
  content: "ﮑ";
}

.fi-sr-calendars:before {
  content: "ﮒ";
}

.fi-sr-calendar:before {
  content: "ﮓ";
}

.fi-sr-calendar-xmark:before {
  content: "ﮔ";
}

.fi-sr-calendar-week:before {
  content: "ﮕ";
}

.fi-sr-calendar-star:before {
  content: "ﮖ";
}

.fi-sr-calendar-salary:before {
  content: "ﮗ";
}

.fi-sr-calendar-plus:before {
  content: "ﮘ";
}

.fi-sr-calendar-pen:before {
  content: "ﮙ";
}

.fi-sr-calendar-minus:before {
  content: "ﮚ";
}

.fi-sr-calendar-lines:before {
  content: "ﮛ";
}

.fi-sr-calendar-lines-pen:before {
  content: "ﮜ";
}

.fi-sr-calendar-image:before {
  content: "ﮝ";
}

.fi-sr-calendar-heart:before {
  content: "ﮞ";
}

.fi-sr-calendar-exclamation:before {
  content: "ﮟ";
}

.fi-sr-calendar-days:before {
  content: "ﮠ";
}

.fi-sr-calendar-day:before {
  content: "ﮡ";
}

.fi-sr-calendar-clock:before {
  content: "ﮢ";
}

.fi-sr-calendar-check:before {
  content: "ﮣ";
}

.fi-sr-calendar-arrow-up:before {
  content: "ﮤ";
}

.fi-sr-calendar-arrow-down:before {
  content: "ﮥ";
}

.fi-sr-calculator:before {
  content: "ﮦ";
}

.fi-sr-calculator-simple:before {
  content: "ﮧ";
}

.fi-sr-calculator-money:before {
  content: "ﮨ";
}

.fi-sr-calculator-bill:before {
  content: "ﮩ";
}

.fi-sr-cake-wedding:before {
  content: "ﮪ";
}

.fi-sr-cake-slice:before {
  content: "ﮫ";
}

.fi-sr-cake-birthday:before {
  content: "ﮬ";
}

.fi-sr-cactus:before {
  content: "ﮭ";
}

.fi-sr-cabin:before {
  content: "ﮮ";
}

.fi-sr-c:before {
  content: "ﮯ";
}

.fi-sr-butterfly:before {
  content: "ﮰ";
}

.fi-sr-business-time:before {
  content: "ﮱ";
}

.fi-sr-bus:before {
  content: "﮲";
}

.fi-sr-bus-alt:before {
  content: "﮳";
}

.fi-sr-burrito:before {
  content: "﮴";
}

.fi-sr-burger-glass:before {
  content: "﮵";
}

.fi-sr-burger-fries:before {
  content: "﮶";
}

.fi-sr-burger-alt:before {
  content: "﮷";
}

.fi-sr-bullseye:before {
  content: "﮸";
}

.fi-sr-bullseye-pointer:before {
  content: "﮹";
}

.fi-sr-bullseye-arrow:before {
  content: "﮺";
}

.fi-sr-bullhorn:before {
  content: "﮻";
}

.fi-sr-bullet:before {
  content: "﮼";
}

.fi-sr-bulb:before {
  content: "﮽";
}

.fi-sr-building:before {
  content: "﮾";
}

.fi-sr-building-ngo:before {
  content: "﮿";
}

.fi-sr-building-circle-arrow-right:before {
  content: "﯀";
}

.fi-sr-build:before {
  content: "﯁";
}

.fi-sr-build-alt:before {
  content: "﯂";
}

.fi-sr-bugs:before {
  content: "﯃";
}

.fi-sr-bug:before {
  content: "﯄";
}

.fi-sr-bug-slash:before {
  content: "﯅";
}

.fi-sr-budget:before {
  content: "﯆";
}

.fi-sr-budget-alt:before {
  content: "﯇";
}

.fi-sr-bucket:before {
  content: "﯈";
}

.fi-sr-brush:before {
  content: "﯉";
}

.fi-sr-browsers:before {
  content: "﯊";
}

.fi-sr-browser:before {
  content: "﯋";
}

.fi-sr-browser-ui:before {
  content: "﯌";
}

.fi-sr-broom:before {
  content: "﯍";
}

.fi-sr-broom-ball:before {
  content: "﯎";
}

.fi-sr-broken-image:before {
  content: "﯏";
}

.fi-sr-broccoli:before {
  content: "﯐";
}

.fi-sr-broadcast-tower:before {
  content: "﯑";
}

.fi-sr-bring-front:before {
  content: "﯒";
}

.fi-sr-bring-forward:before {
  content: "ﯓ";
}

.fi-sr-brightness:before {
  content: "ﯔ";
}

.fi-sr-brightness-low:before {
  content: "ﯕ";
}

.fi-sr-briefcase:before {
  content: "ﯖ";
}

.fi-sr-briefcase-blank:before {
  content: "ﯗ";
}

.fi-sr-briefcase-arrow-right:before {
  content: "ﯘ";
}

.fi-sr-bread:before {
  content: "ﯙ";
}

.fi-sr-bread-slice:before {
  content: "ﯚ";
}

.fi-sr-bread-slice-butter:before {
  content: "ﯛ";
}

.fi-sr-bread-loaf:before {
  content: "ﯜ";
}

.fi-sr-branding:before {
  content: "ﯝ";
}

.fi-sr-brand:before {
  content: "ﯞ";
}

.fi-sr-brain:before {
  content: "ﯟ";
}

.fi-sr-brain-circuit:before {
  content: "ﯠ";
}

.fi-sr-braille:before {
  content: "ﯡ";
}

.fi-sr-braille-z:before {
  content: "ﯢ";
}

.fi-sr-braille-y:before {
  content: "ﯣ";
}

.fi-sr-braille-x:before {
  content: "ﯤ";
}

.fi-sr-braille-w:before {
  content: "ﯥ";
}

.fi-sr-braille-v:before {
  content: "ﯦ";
}

.fi-sr-braille-u:before {
  content: "ﯧ";
}

.fi-sr-braille-t:before {
  content: "ﯨ";
}

.fi-sr-braille-s:before {
  content: "ﯩ";
}

.fi-sr-braille-r:before {
  content: "ﯪ";
}

.fi-sr-braille-q:before {
  content: "ﯫ";
}

.fi-sr-braille-p:before {
  content: "ﯬ";
}

.fi-sr-braille-o:before {
  content: "ﯭ";
}

.fi-sr-braille-n:before {
  content: "ﯮ";
}

.fi-sr-braille-n-alt:before {
  content: "ﯯ";
}

.fi-sr-braille-m:before {
  content: "ﯰ";
}

.fi-sr-braille-l:before {
  content: "ﯱ";
}

.fi-sr-braille-k:before {
  content: "ﯲ";
}

.fi-sr-braille-j:before {
  content: "ﯳ";
}

.fi-sr-braille-i:before {
  content: "ﯴ";
}

.fi-sr-braille-h:before {
  content: "ﯵ";
}

.fi-sr-braille-g:before {
  content: "ﯶ";
}

.fi-sr-braille-e:before {
  content: "ﯷ";
}

.fi-sr-braille-d:before {
  content: "ﯸ";
}

.fi-sr-braille-c:before {
  content: "ﯹ";
}

.fi-sr-braille-b:before {
  content: "ﯺ";
}

.fi-sr-braille-a:before {
  content: "ﯻ";
}

.fi-sr-brackets-square:before {
  content: "ﯼ";
}

.fi-sr-brackets-round:before {
  content: "ﯽ";
}

.fi-sr-brackets-curly:before {
  content: "ﯾ";
}

.fi-sr-bracket-square:before {
  content: "ﯿ";
}

.fi-sr-bracket-square-right:before {
  content: "ﰀ";
}

.fi-sr-bracket-round:before {
  content: "ﰁ";
}

.fi-sr-bracket-round-right:before {
  content: "ﰂ";
}

.fi-sr-bracket-curly:before {
  content: "ﰃ";
}

.fi-sr-bracket-curly-right:before {
  content: "ﰄ";
}

.fi-sr-boxing-glove:before {
  content: "ﰅ";
}

.fi-sr-boxes:before {
  content: "ﰆ";
}

.fi-sr-box:before {
  content: "ﰇ";
}

.fi-sr-box-up:before {
  content: "ﰈ";
}

.fi-sr-box-tissue:before {
  content: "ﰉ";
}

.fi-sr-box-open:before {
  content: "ﰊ";
}

.fi-sr-box-open-full:before {
  content: "ﰋ";
}

.fi-sr-box-heart:before {
  content: "ﰌ";
}

.fi-sr-box-fragile:before {
  content: "ﰍ";
}

.fi-sr-box-dollar:before {
  content: "ﰎ";
}

.fi-sr-box-circle-check:before {
  content: "ﰏ";
}

.fi-sr-box-check:before {
  content: "ﰐ";
}

.fi-sr-box-ballot:before {
  content: "ﰑ";
}

.fi-sr-box-alt:before {
  content: "ﰒ";
}

.fi-sr-bowling:before {
  content: "ﰓ";
}

.fi-sr-bowling-pins:before {
  content: "ﰔ";
}

.fi-sr-bowling-ball:before {
  content: "ﰕ";
}

.fi-sr-bowl-spoon:before {
  content: "ﰖ";
}

.fi-sr-bowl-soft-serve:before {
  content: "ﰗ";
}

.fi-sr-bowl-scoops:before {
  content: "ﰘ";
}

.fi-sr-bowl-scoop:before {
  content: "ﰙ";
}

.fi-sr-bowl-rice:before {
  content: "ﰚ";
}

.fi-sr-bowl-chopsticks:before {
  content: "ﰛ";
}

.fi-sr-bowl-chopsticks-noodles:before {
  content: "ﰜ";
}

.fi-sr-bow-arrow:before {
  content: "ﰝ";
}

.fi-sr-bottle:before {
  content: "ﰞ";
}

.fi-sr-bottle-droplet:before {
  content: "ﰟ";
}

.fi-sr-boss:before {
  content: "ﰠ";
}

.fi-sr-border-top:before {
  content: "ﰡ";
}

.fi-sr-border-style:before {
  content: "ﰢ";
}

.fi-sr-border-style-alt:before {
  content: "ﰣ";
}

.fi-sr-border-right:before {
  content: "ﰤ";
}

.fi-sr-border-outer:before {
  content: "ﰥ";
}

.fi-sr-border-none:before {
  content: "ﰦ";
}

.fi-sr-border-left:before {
  content: "ﰧ";
}

.fi-sr-border-inner:before {
  content: "ﰨ";
}

.fi-sr-border-center-v:before {
  content: "ﰩ";
}

.fi-sr-border-center-h:before {
  content: "ﰪ";
}

.fi-sr-border-bottom:before {
  content: "ﰫ";
}

.fi-sr-border-all:before {
  content: "ﰬ";
}

.fi-sr-booth-curtain:before {
  content: "ﰭ";
}

.fi-sr-boot:before {
  content: "ﰮ";
}

.fi-sr-boot-heeled:before {
  content: "ﰯ";
}

.fi-sr-books:before {
  content: "ﰰ";
}

.fi-sr-books-medical:before {
  content: "ﰱ";
}

.fi-sr-bookmark:before {
  content: "ﰲ";
}

.fi-sr-bookmark-slash:before {
  content: "ﰳ";
}

.fi-sr-book:before {
  content: "ﰴ";
}

.fi-sr-book-user:before {
  content: "ﰵ";
}

.fi-sr-book-tanakh:before {
  content: "ﰶ";
}

.fi-sr-book-spells:before {
  content: "ﰷ";
}

.fi-sr-book-section:before {
  content: "ﰸ";
}

.fi-sr-book-quran:before {
  content: "ﰹ";
}

.fi-sr-book-plus:before {
  content: "ﰺ";
}

.fi-sr-book-open-reader:before {
  content: "ﰻ";
}

.fi-sr-book-open-cover:before {
  content: "ﰼ";
}

.fi-sr-book-medical:before {
  content: "ﰽ";
}

.fi-sr-book-heart:before {
  content: "ﰾ";
}

.fi-sr-book-font:before {
  content: "ﰿ";
}

.fi-sr-book-dead:before {
  content: "ﱀ";
}

.fi-sr-book-copy:before {
  content: "ﱁ";
}

.fi-sr-book-circle-arrow-up:before {
  content: "ﱂ";
}

.fi-sr-book-circle-arrow-right:before {
  content: "ﱃ";
}

.fi-sr-book-bookmark:before {
  content: "ﱄ";
}

.fi-sr-book-atlas:before {
  content: "ﱅ";
}

.fi-sr-book-arrow-up:before {
  content: "ﱆ";
}

.fi-sr-book-arrow-right:before {
  content: "ﱇ";
}

.fi-sr-book-alt:before {
  content: "ﱈ";
}

.fi-sr-bonus:before {
  content: "ﱉ";
}

.fi-sr-bonus-star:before {
  content: "ﱊ";
}

.fi-sr-bong:before {
  content: "ﱋ";
}

.fi-sr-bone:before {
  content: "ﱌ";
}

.fi-sr-bone-break:before {
  content: "ﱍ";
}

.fi-sr-bomb:before {
  content: "ﱎ";
}

.fi-sr-bolt:before {
  content: "ﱏ";
}

.fi-sr-bolt-slash:before {
  content: "ﱐ";
}

.fi-sr-bolt-auto:before {
  content: "ﱑ";
}

.fi-sr-bold:before {
  content: "ﱒ";
}

.fi-sr-blueprint:before {
  content: "ﱓ";
}

.fi-sr-blueberries:before {
  content: "ﱔ";
}

.fi-sr-blossom:before {
  content: "ﱕ";
}

.fi-sr-blood:before {
  content: "ﱖ";
}

.fi-sr-blood-test-tube:before {
  content: "ﱗ";
}

.fi-sr-blood-test-tube-alt:before {
  content: "ﱘ";
}

.fi-sr-blog-text:before {
  content: "ﱙ";
}

.fi-sr-blog-pencil:before {
  content: "ﱚ";
}

.fi-sr-block:before {
  content: "ﱛ";
}

.fi-sr-block-quote:before {
  content: "ﱜ";
}

.fi-sr-block-question:before {
  content: "ﱝ";
}

.fi-sr-block-brick:before {
  content: "ﱞ";
}

.fi-sr-blinds:before {
  content: "ﱟ";
}

.fi-sr-blinds-raised:before {
  content: "ﱠ";
}

.fi-sr-blinds-open:before {
  content: "ﱡ";
}

.fi-sr-blender:before {
  content: "ﱢ";
}

.fi-sr-blender-phone:before {
  content: "ﱣ";
}

.fi-sr-blanket:before {
  content: "ﱤ";
}

.fi-sr-bitcoin-sign:before {
  content: "ﱥ";
}

.fi-sr-bird:before {
  content: "ﱦ";
}

.fi-sr-biohazard:before {
  content: "ﱧ";
}

.fi-sr-bio:before {
  content: "ﱨ";
}

.fi-sr-bio-leaves:before {
  content: "ﱩ";
}

.fi-sr-binoculars:before {
  content: "ﱪ";
}

.fi-sr-binary:before {
  content: "ﱫ";
}

.fi-sr-binary-slash:before {
  content: "ﱬ";
}

.fi-sr-binary-lock:before {
  content: "ﱭ";
}

.fi-sr-binary-circle-check:before {
  content: "ﱮ";
}

.fi-sr-billiard:before {
  content: "ﱯ";
}

.fi-sr-bill-arrow:before {
  content: "ﱰ";
}

.fi-sr-biking:before {
  content: "ﱱ";
}

.fi-sr-biking-mountain:before {
  content: "ﱲ";
}

.fi-sr-bike:before {
  content: "ﱳ";
}

.fi-sr-bicycle-journey:before {
  content: "ﱴ";
}

.fi-sr-bible:before {
  content: "ﱵ";
}

.fi-sr-betamax:before {
  content: "ﱶ";
}

.fi-sr-benefit-porcent:before {
  content: "ﱷ";
}

.fi-sr-bench-tree:before {
  content: "ﱸ";
}

.fi-sr-bells:before {
  content: "ﱹ";
}

.fi-sr-bell:before {
  content: "ﱺ";
}

.fi-sr-bell-slash:before {
  content: "ﱻ";
}

.fi-sr-bell-school:before {
  content: "ﱼ";
}

.fi-sr-bell-school-slash:before {
  content: "ﱽ";
}

.fi-sr-bell-ring:before {
  content: "ﱾ";
}

.fi-sr-bell-concierge:before {
  content: "ﱿ";
}

.fi-sr-beer:before {
  content: "ﲀ";
}

.fi-sr-beer-mug-empty:before {
  content: "ﲁ";
}

.fi-sr-bee:before {
  content: "ﲂ";
}

.fi-sr-bed:before {
  content: "ﲃ";
}

.fi-sr-bed-empty:before {
  content: "ﲄ";
}

.fi-sr-bed-bunk:before {
  content: "ﲅ";
}

.fi-sr-bed-alt:before {
  content: "ﲆ";
}

.fi-sr-beacon:before {
  content: "ﲇ";
}

.fi-sr-battery-three-quarters:before {
  content: "ﲈ";
}

.fi-sr-battery-slash:before {
  content: "ﲉ";
}

.fi-sr-battery-quarter:before {
  content: "ﲊ";
}

.fi-sr-battery-half:before {
  content: "ﲋ";
}

.fi-sr-battery-full:before {
  content: "ﲌ";
}

.fi-sr-battery-exclamation:before {
  content: "ﲍ";
}

.fi-sr-battery-empty:before {
  content: "ﲎ";
}

.fi-sr-battery-bolt:before {
  content: "ﲏ";
}

.fi-sr-bath:before {
  content: "ﲐ";
}

.fi-sr-bat:before {
  content: "ﲑ";
}

.fi-sr-basketball:before {
  content: "ﲒ";
}

.fi-sr-basketball-hoop:before {
  content: "ﲓ";
}

.fi-sr-basket-shopping-simple:before {
  content: "ﲔ";
}

.fi-sr-baseball:before {
  content: "ﲕ";
}

.fi-sr-baseball-alt:before {
  content: "ﲖ";
}

.fi-sr-bars-staggered:before {
  content: "ﲗ";
}

.fi-sr-bars-sort:before {
  content: "ﲘ";
}

.fi-sr-bars-progress:before {
  content: "ﲙ";
}

.fi-sr-bars-filter:before {
  content: "ﲚ";
}

.fi-sr-barcode:before {
  content: "ﲛ";
}

.fi-sr-barcode-scan:before {
  content: "ﲜ";
}

.fi-sr-barcode-read:before {
  content: "ﲝ";
}

.fi-sr-barber-shop:before {
  content: "ﲞ";
}

.fi-sr-barber-pole:before {
  content: "ﲟ";
}

.fi-sr-banner:before {
  content: "ﲠ";
}

.fi-sr-banner-5:before {
  content: "ﲡ";
}

.fi-sr-banner-4:before {
  content: "ﲢ";
}

.fi-sr-banner-3:before {
  content: "ﲣ";
}

.fi-sr-banner-2:before {
  content: "ﲤ";
}

.fi-sr-bank:before {
  content: "ﲥ";
}

.fi-sr-banjo:before {
  content: "ﲦ";
}

.fi-sr-bangladeshi-taka-sign:before {
  content: "ﲧ";
}

.fi-sr-band-aid:before {
  content: "ﲨ";
}

.fi-sr-banana:before {
  content: "ﲩ";
}

.fi-sr-ban:before {
  content: "ﲪ";
}

.fi-sr-ban-bug:before {
  content: "ﲫ";
}

.fi-sr-ballot:before {
  content: "ﲬ";
}

.fi-sr-ballot-check:before {
  content: "ﲭ";
}

.fi-sr-balloons:before {
  content: "ﲮ";
}

.fi-sr-balloon:before {
  content: "ﲯ";
}

.fi-sr-ball-pile:before {
  content: "ﲰ";
}

.fi-sr-balance-scale-right:before {
  content: "ﲱ";
}

.fi-sr-balance-scale-left:before {
  content: "ﲲ";
}

.fi-sr-baht-sign:before {
  content: "ﲳ";
}

.fi-sr-bahai:before {
  content: "ﲴ";
}

.fi-sr-baguette:before {
  content: "ﲵ";
}

.fi-sr-bags-shopping:before {
  content: "ﲶ";
}

.fi-sr-bag-seedling:before {
  content: "ﲷ";
}

.fi-sr-badminton:before {
  content: "ﲸ";
}

.fi-sr-badger-honey:before {
  content: "ﲹ";
}

.fi-sr-badge:before {
  content: "ﲺ";
}

.fi-sr-badge-sheriff:before {
  content: "ﲻ";
}

.fi-sr-badge-percent:before {
  content: "ﲼ";
}

.fi-sr-badge-leaf:before {
  content: "ﲽ";
}

.fi-sr-badge-dollar:before {
  content: "ﲾ";
}

.fi-sr-badge-check:before {
  content: "ﲿ";
}

.fi-sr-bacterium:before {
  content: "ﳀ";
}

.fi-sr-bacteria:before {
  content: "ﳁ";
}

.fi-sr-bacon:before {
  content: "ﳂ";
}

.fi-sr-backpack:before {
  content: "ﳃ";
}

.fi-sr-background:before {
  content: "ﳄ";
}

.fi-sr-baby:before {
  content: "ﳅ";
}

.fi-sr-baby-carriage:before {
  content: "ﳆ";
}

.fi-sr-b:before {
  content: "ﳇ";
}

.fi-sr-axe:before {
  content: "ﳈ";
}

.fi-sr-axe-battle:before {
  content: "ﳉ";
}

.fi-sr-award:before {
  content: "ﳊ";
}

.fi-sr-avocado:before {
  content: "ﳋ";
}

.fi-sr-austral-sign:before {
  content: "ﳌ";
}

.fi-sr-audit:before {
  content: "ﳍ";
}

.fi-sr-audit-alt:before {
  content: "ﳎ";
}

.fi-sr-audio-description-slash:before {
  content: "ﳏ";
}

.fi-sr-auction:before {
  content: "ﳐ";
}

.fi-sr-aubergine:before {
  content: "ﳑ";
}

.fi-sr-attribution-pencil:before {
  content: "ﳒ";
}

.fi-sr-attribution-pen:before {
  content: "ﳓ";
}

.fi-sr-at:before {
  content: "ﳔ";
}

.fi-sr-asterik:before {
  content: "ﳕ";
}

.fi-sr-assistive-listening-systems:before {
  content: "ﳖ";
}

.fi-sr-assessment:before {
  content: "ﳗ";
}

.fi-sr-assessment-alt:before {
  content: "ﳘ";
}

.fi-sr-assept-document:before {
  content: "ﳙ";
}

.fi-sr-artificial-intelligence:before {
  content: "ﳚ";
}

.fi-sr-arrows:before {
  content: "ﳛ";
}

.fi-sr-arrows-to-line:before {
  content: "ﳜ";
}

.fi-sr-arrows-to-eye:before {
  content: "ﳝ";
}

.fi-sr-arrows-to-dotted-line:before {
  content: "ﳞ";
}

.fi-sr-arrows-retweet:before {
  content: "ﳟ";
}

.fi-sr-arrows-repeat:before {
  content: "ﳠ";
}

.fi-sr-arrows-repeat-1:before {
  content: "ﳡ";
}

.fi-sr-arrows-h:before {
  content: "ﳢ";
}

.fi-sr-arrows-h-copy:before {
  content: "ﳣ";
}

.fi-sr-arrows-from-line:before {
  content: "ﳤ";
}

.fi-sr-arrows-from-dotted-line:before {
  content: "ﳥ";
}

.fi-sr-arrows-cross:before {
  content: "ﳦ";
}

.fi-sr-arrows-alt:before {
  content: "ﳧ";
}

.fi-sr-arrows-alt-v:before {
  content: "ﳨ";
}

.fi-sr-arrows-alt-h:before {
  content: "ﳩ";
}

.fi-sr-arrow-up:before {
  content: "ﳪ";
}

.fi-sr-arrow-up-to-dotted-line:before {
  content: "ﳫ";
}

.fi-sr-arrow-up-square-triangle:before {
  content: "ﳬ";
}

.fi-sr-arrow-up-small-big:before {
  content: "ﳭ";
}

.fi-sr-arrow-up-right:before {
  content: "ﳮ";
}

.fi-sr-arrow-up-right-from-square:before {
  content: "ﳯ";
}

.fi-sr-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "ﳰ";
}

.fi-sr-arrow-up-left:before {
  content: "ﳱ";
}

.fi-sr-arrow-up-left-from-circle:before {
  content: "ﳲ";
}

.fi-sr-arrow-up-from-square:before {
  content: "ﳳ";
}

.fi-sr-arrow-up-from-dotted-line:before {
  content: "ﳴ";
}

.fi-sr-arrow-turn-down-right:before {
  content: "ﳵ";
}

.fi-sr-arrow-turn-down-left:before {
  content: "ﳶ";
}

.fi-sr-arrow-trend-up:before {
  content: "ﳷ";
}

.fi-sr-arrow-trend-down:before {
  content: "ﳸ";
}

.fi-sr-arrow-to-top:before {
  content: "ﳹ";
}

.fi-sr-arrow-to-right:before {
  content: "ﳺ";
}

.fi-sr-arrow-to-left:before {
  content: "ﳻ";
}

.fi-sr-arrow-to-bottom:before {
  content: "ﳼ";
}

.fi-sr-arrow-square-up:before {
  content: "ﳽ";
}

.fi-sr-arrow-square-right:before {
  content: "ﳾ";
}

.fi-sr-arrow-square-left:before {
  content: "ﳿ";
}

.fi-sr-arrow-square-down:before {
  content: "ﴀ";
}

.fi-sr-arrow-small-up:before {
  content: "ﴁ";
}

.fi-sr-arrow-small-right:before {
  content: "ﴂ";
}

.fi-sr-arrow-small-left:before {
  content: "ﴃ";
}

.fi-sr-arrow-small-down:before {
  content: "ﴄ";
}

.fi-sr-arrow-right:before {
  content: "ﴅ";
}

.fi-sr-arrow-right-to-bracket:before {
  content: "ﴆ";
}

.fi-sr-arrow-progress:before {
  content: "ﴇ";
}

.fi-sr-arrow-left:before {
  content: "ﴈ";
}

.fi-sr-arrow-left-from-line:before {
  content: "ﴉ";
}

.fi-sr-arrow-from-top:before {
  content: "ﴊ";
}

.fi-sr-arrow-from-right:before {
  content: "ﴋ";
}

.fi-sr-arrow-from-left:before {
  content: "ﴌ";
}

.fi-sr-arrow-from-bottom:before {
  content: "ﴍ";
}

.fi-sr-arrow-down:before {
  content: "ﴎ";
}

.fi-sr-arrow-down-triangle-square:before {
  content: "ﴏ";
}

.fi-sr-arrow-down-to-square:before {
  content: "ﴐ";
}

.fi-sr-arrow-down-to-dotted-line:before {
  content: "ﴑ";
}

.fi-sr-arrow-down-small-big:before {
  content: "ﴒ";
}

.fi-sr-arrow-down-left:before {
  content: "ﴓ";
}

.fi-sr-arrow-down-from-dotted-line:before {
  content: "ﴔ";
}

.fi-sr-arrow-comparison:before {
  content: "ﴕ";
}

.fi-sr-arrow-circle-up:before {
  content: "ﴖ";
}

.fi-sr-arrow-circle-right:before {
  content: "ﴗ";
}

.fi-sr-arrow-circle-left:before {
  content: "ﴘ";
}

.fi-sr-arrow-circle-down:before {
  content: "ﴙ";
}

.fi-sr-arrow-alt-up:before {
  content: "ﴚ";
}

.fi-sr-arrow-alt-to-top:before {
  content: "ﴛ";
}

.fi-sr-arrow-alt-to-right:before {
  content: "ﴜ";
}

.fi-sr-arrow-alt-to-left:before {
  content: "ﴝ";
}

.fi-sr-arrow-alt-to-bottom:before {
  content: "ﴞ";
}

.fi-sr-arrow-alt-square-up:before {
  content: "ﴟ";
}

.fi-sr-arrow-alt-square-right:before {
  content: "ﴠ";
}

.fi-sr-arrow-alt-square-left:before {
  content: "ﴡ";
}

.fi-sr-arrow-alt-square-down:before {
  content: "ﴢ";
}

.fi-sr-arrow-alt-right:before {
  content: "ﴣ";
}

.fi-sr-arrow-alt-left:before {
  content: "ﴤ";
}

.fi-sr-arrow-alt-from-top:before {
  content: "ﴥ";
}

.fi-sr-arrow-alt-from-right:before {
  content: "ﴦ";
}

.fi-sr-arrow-alt-from-left:before {
  content: "ﴧ";
}

.fi-sr-arrow-alt-from-bottom:before {
  content: "ﴨ";
}

.fi-sr-arrow-alt-down:before {
  content: "ﴩ";
}

.fi-sr-arrow-alt-circle-up:before {
  content: "ﴪ";
}

.fi-sr-arrow-alt-circle-right:before {
  content: "ﴫ";
}

.fi-sr-arrow-alt-circle-left:before {
  content: "ﴬ";
}

.fi-sr-arrow-alt-circle-down:before {
  content: "ﴭ";
}

.fi-sr-archway:before {
  content: "ﴮ";
}

.fi-sr-archive:before {
  content: "ﴯ";
}

.fi-sr-apps:before {
  content: "ﴰ";
}

.fi-sr-apps-sort:before {
  content: "ﴱ";
}

.fi-sr-apps-delete:before {
  content: "ﴲ";
}

.fi-sr-apps-add:before {
  content: "ﴳ";
}

.fi-sr-apple-whole:before {
  content: "ﴴ";
}

.fi-sr-apple-crate:before {
  content: "ﴵ";
}

.fi-sr-apple-core:before {
  content: "ﴶ";
}

.fi-sr-api:before {
  content: "ﴷ";
}

.fi-sr-aperture:before {
  content: "ﴸ";
}

.fi-sr-apartment:before {
  content: "ﴹ";
}

.fi-sr-answer:before {
  content: "ﴺ";
}

.fi-sr-answer-alt:before {
  content: "ﴻ";
}

.fi-sr-ankh:before {
  content: "ﴼ";
}

.fi-sr-animated-icon:before {
  content: "ﴽ";
}

.fi-sr-angry:before {
  content: "﴾";
}

.fi-sr-angle:before {
  content: "﴿";
}

.fi-sr-angle-up:before {
  content: "﵀";
}

.fi-sr-angle-square-up:before {
  content: "﵁";
}

.fi-sr-angle-square-right:before {
  content: "﵂";
}

.fi-sr-angle-square-left:before {
  content: "﵃";
}

.fi-sr-angle-square-down:before {
  content: "﵄";
}

.fi-sr-angle-small-up:before {
  content: "﵅";
}

.fi-sr-angle-small-right:before {
  content: "﵆";
}

.fi-sr-angle-small-left:before {
  content: "﵇";
}

.fi-sr-angle-small-down:before {
  content: "﵈";
}

.fi-sr-angle-right:before {
  content: "﵉";
}

.fi-sr-angle-left:before {
  content: "﵊";
}

.fi-sr-angle-down:before {
  content: "﵋";
}

.fi-sr-angle-double-small-up:before {
  content: "﵌";
}

.fi-sr-angle-double-small-right:before {
  content: "﵍";
}

.fi-sr-angle-double-small-left:before {
  content: "﵎";
}

.fi-sr-angle-double-small-down:before {
  content: "﵏";
}

.fi-sr-angle-double-right:before {
  content: "ﵐ";
}

.fi-sr-angle-double-left:before {
  content: "ﵑ";
}

.fi-sr-angle-circle-up:before {
  content: "ﵒ";
}

.fi-sr-angle-circle-right:before {
  content: "ﵓ";
}

.fi-sr-angle-circle-left:before {
  content: "ﵔ";
}

.fi-sr-angle-circle-down:before {
  content: "ﵕ";
}

.fi-sr-angle-90:before {
  content: "ﵖ";
}

.fi-sr-angel:before {
  content: "ﵗ";
}

.fi-sr-anchor:before {
  content: "ﵘ";
}

.fi-sr-analyse:before {
  content: "ﵙ";
}

.fi-sr-analyse-alt:before {
  content: "ﵚ";
}

.fi-sr-amusement-park:before {
  content: "ﵛ";
}

.fi-sr-ambulance:before {
  content: "ﵜ";
}

.fi-sr-align-slash:before {
  content: "ﵝ";
}

.fi-sr-align-left:before {
  content: "ﵞ";
}

.fi-sr-align-justify:before {
  content: "ﵟ";
}

.fi-sr-align-center:before {
  content: "ﵠ";
}

.fi-sr-alien:before {
  content: "ﵡ";
}

.fi-sr-alicorn:before {
  content: "ﵢ";
}

.fi-sr-algorithm:before {
  content: "ﵣ";
}

.fi-sr-album:before {
  content: "ﵤ";
}

.fi-sr-album-collection:before {
  content: "ﵥ";
}

.fi-sr-album-circle-user:before {
  content: "ﵦ";
}

.fi-sr-album-circle-plus:before {
  content: "ﵧ";
}

.fi-sr-alarm-snooze:before {
  content: "ﵨ";
}

.fi-sr-alarm-plus:before {
  content: "ﵩ";
}

.fi-sr-alarm-exclamation:before {
  content: "ﵪ";
}

.fi-sr-alarm-clock:before {
  content: "ﵫ";
}

.fi-sr-airplay:before {
  content: "ﵬ";
}

.fi-sr-airplane-journey:before {
  content: "ﵭ";
}

.fi-sr-air-pollution:before {
  content: "ﵮ";
}

.fi-sr-air-freshener:before {
  content: "ﵯ";
}

.fi-sr-air-conditioner:before {
  content: "ﵰ";
}

.fi-sr-age:before {
  content: "ﵱ";
}

.fi-sr-age-restriction-zero:before {
  content: "ﵲ";
}

.fi-sr-age-restriction-twenty-one:before {
  content: "ﵳ";
}

.fi-sr-age-restriction-twelve:before {
  content: "ﵴ";
}

.fi-sr-age-restriction-three:before {
  content: "ﵵ";
}

.fi-sr-age-restriction-thirteen:before {
  content: "ﵶ";
}

.fi-sr-age-restriction-sixteen:before {
  content: "ﵷ";
}

.fi-sr-age-restriction-six:before {
  content: "ﵸ";
}

.fi-sr-age-restriction-seven:before {
  content: "ﵹ";
}

.fi-sr-age-restriction-eighteen:before {
  content: "ﵺ";
}

.fi-sr-age-alt:before {
  content: "ﵻ";
}

.fi-sr-admin:before {
  content: "ﵼ";
}

.fi-sr-admin-alt:before {
  content: "ﵽ";
}

.fi-sr-address-card:before {
  content: "ﵾ";
}

.fi-sr-address-book:before {
  content: "ﵿ";
}

.fi-sr-add:before {
  content: "ﶀ";
}

.fi-sr-add-image:before {
  content: "ﶁ";
}

.fi-sr-add-folder:before {
  content: "ﶂ";
}

.fi-sr-add-document:before {
  content: "ﶃ";
}

.fi-sr-ad:before {
  content: "ﶄ";
}

.fi-sr-ad-paid:before {
  content: "ﶅ";
}

.fi-sr-acorn:before {
  content: "ﶆ";
}

.fi-sr-accident:before {
  content: "ﶇ";
}

.fi-sr-a:before {
  content: "ﶈ";
}

.fi-sr-Siren:before {
  content: "ﶉ";
}

.fi-sr-Sickle:before {
  content: "ﶊ";
}

.fi-sr-Revenue:before {
  content: "ﶋ";
}

.fi-sr-QR:before {
  content: "ﶌ";
}

.fi-sr-Pi:before {
  content: "ﶍ";
}

.fi-sr-Cannabis:before {
  content: "ﶎ";
}

.fi-sr-CV:before {
  content: "ﶏ";
}

.fi-sr-CRM:before {
  content: "﶐";
}

.fi-sr-Butter:before {
  content: "﶑";
}

.fi-sr-Booking:before {
  content: "ﶒ";
}

.fi-sr-9:before {
  content: "ﶓ";
}

.fi-sr-8:before {
  content: "ﶔ";
}

.fi-sr-7:before {
  content: "ﶕ";
}

.fi-sr-6:before {
  content: "ﶖ";
}

.fi-sr-5:before {
  content: "ﶗ";
}

.fi-sr-404:before {
  content: "ﶘ";
}

.fi-sr-4:before {
  content: "ﶙ";
}

.fi-sr-360-degrees:before {
  content: "ﶚ";
}

.fi-sr-3:before {
  content: "ﶛ";
}

.fi-sr-2:before {
  content: "ﶜ";
}

.fi-sr-1:before {
  content: "ﶝ";
}

.fi-sr-0:before {
  content: "ﶞ";
}

header[data-v-ad12e8] {
  max-width: var(--page-width);
  margin: 32px auto;
  padding: 0 16px;
}

#site-title[data-v-ad12e8] {
  height: 30px;
  margin: 0;
  font-family: Rubik, sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  overflow: hidden;
}

nav ul[data-v-ad12e8] {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.nav-link[data-v-ad12e8] {
  text-transform: uppercase;
  color: var(--fg-high);
  font-size: .8rem;
  text-decoration: none;
}

.nav-link[data-v-ad12e8]:not(:first-child) {
  margin-left: 8px;
}

.nav-link[data-v-ad12e8]:hover {
  color: var(--fg-med);
}

main[data-v-9d5ea6] {
  max-width: var(--page-width);
  margin: 0 auto;
  padding: 0 16px;
}

article[data-v-1bef54] {
  margin-bottom: 4rem;
}

h1[data-v-1bef54] {
  color: #515151;
  background-color: var(--bg-high);
  margin: 0 0 1px;
  padding: 16px;
  font-family: Rubik, sans-serif;
  font-size: 2rem;
  font-weight: normal;
}

.subtitle[data-v-1bef54] {
  color: #515151;
  background-color: var(--bg-high);
  margin: 0 0 1px;
  padding: 4px 16px;
  font-size: .8rem;
}

.content[data-v-1bef54] {
  color: #515151;
  background-color: var(--bg-high);
  border-color: red;
  padding: 16px;
}

.keywords {
  flex-wrap: wrap;
  gap: 16px;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.keywords li {
  color: var(--fg-high);
  background-color: var(--bg-med);
  padding: 8px 16px;
  transform: rotate(5deg);
}

.keywords li:nth-child(2n) {
  transform: rotate(-4deg);
}

.keywords li:nth-child(3n) {
  transform: rotate(3deg);
}

.keywords li:nth-child(4n) {
  transform: rotate(-2deg);
}

.quick-actions[data-v-2a03e6] {
  color: var(--background);
  flex-direction: row;
  justify-content: end;
  gap: 1px;
  margin: 1px 0 0;
  padding: 0;
  font-size: .8rem;
  list-style-type: none;
  display: flex;
}

li[data-v-2a03e6] {
  background-color: var(--bg-high);
}

.filler[data-v-2a03e6] {
  flex-grow: 1;
}

li a[data-v-2a03e6] {
  color: var(--background);
  padding: 0 8px;
}

li a[data-v-2a03e6]:hover {
  color: var(--fg-high);
  background-color: var(--bg-med);
}

header[data-v-17ae15] {
  flex-direction: row;
  gap: 1px;
  margin-bottom: 1px;
  display: flex;
}

.event-date[data-v-17ae15] {
  color: var(--bg-inv);
  background-color: var(--bg-high);
  margin: 0;
  padding: 8px 16px;
  font-size: 1rem;
}

.filler[data-v-17ae15] {
  background-color: var(--bg-high);
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.title[data-v-17ae15] {
  color: var(--bg-inv);
  background-color: var(--bg-high);
  margin: 0;
  padding: 8px 16px;
  display: block;
}

.title[data-v-17ae15]:hover {
  color: var(--fg-high);
  background-color: var(--bg-med);
}

.events-filters[data-v-c6ba8d] {
  border-top: 1px solid var(--fg-high);
  border-bottom: 1px solid var(--fg-high);
  flex-direction: row;
  gap: 8px;
  margin: 8px 0;
  padding: 1px 0;
  list-style-type: none;
  display: flex;
}

.events-filters .active[data-v-c6ba8d], .events-filters li[data-v-c6ba8d]:hover {
  color: var(--background);
  background-color: var(--bg-high);
  padding: 0 4px;
}

.left-filter[data-v-c6ba8d] {
  flex-grow: 1;
}

h2[data-v-e77fa9] {
  text-transform: lowercase;
  border-bottom: 1px solid var(--fg-high);
  margin: 32px 0 8px;
  font-size: 1rem;
}

.card[data-v-e77fa9]:not(:last-child) {
  margin-bottom: 8px;
}
/*# sourceMappingURL=index.7b2627c5.css.map */
