:root {
    /* theme */
    --fg-high: #ffffff;
    --fg-med: #ffb816;
    --fg-low: #e1ded7;
    --fg-inv: #4a5d84;
    --background: #b4ab9a;
    --bg-high: #ffffff;
    --bg-med: #ffb816;
    --bg-low: #a79e8d;
    --bg-inv: #443519;
    /* sizes */
    --page-width: 600px;
    --radius-large: 8px;
    --radius-small: 5px;
    --sidebar-gap: 10px;
    --sidebar-item: 40px;
    --sidebar-icon: 26px;
}

/* page title */
@font-face {
    font-family: "Thasadith";
    src: url("../fonts/Thasadith/Thasadith-Regular.ttf") format("truetype");
}

/* headings */
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-VariableFont_wght.ttf") format("truetype");
}

/* text */
@font-face {
    font-family: "Nunito";
    src:
        url("../fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype"),
        url("../fonts/Nunito/Nunito-Italic-VariableFont_wght.ttf") format("truetype");
}

html {
    background: url(../images/background.jpg) no-repeat center center fixed;
    background-size: cover;
    background-color: var(--background);
}

::selection {
    color: var(--fg-high);
    background-color: var(--bg-med);
}

h1 {
    font-family: Rubik, sans-serif;
    font-weight: normal;
    font-size: 3rem;
}

h2, h3, h4, h5, h6 {
    font-family: Thasadith, serif;
    font-weight: normal;
    font-size: 2rem;
    margin: 1.5rem 0;
}

a:not(.nav-link) {
    padding: 0 3px;
    color: var(--fg-med);
    text-decoration: none;
}

a:not(.nav-link):hover {
    color: var(--background);
    background-color: var(--fg-high);
}

a:not(.nav-link):active {
    color: var(--fg-med);
}

body {
    margin: 0;
    font-size: 1rem;
    color: var(--fg-high);
    font-family: Nunito, sans-serif;
}
